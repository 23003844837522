/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import PropTypes from 'prop-types';
import { Container, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { fNumber } from '../../utils/format-number';
import Chart, { useChart } from '../../components/chart';

// ----------------------------------------------------------------------

const CHART_HEIGHT = 400;

const LEGEND_HEIGHT = 72;

const StyledChart = styled(Chart)(({ theme }) => ({
  height: CHART_HEIGHT,
  '& .apexcharts-canvas, .apexcharts-inner, svg, foreignObject': {
    height: `100% !important`,
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    borderTop: `dashed 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
  },
}));

/* const Heading = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[400],
  fontSize: '18px',
  fontWeight: 400,
})) */

const CampaignHeading = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 500,
  color: theme.palette.grey[100]
}));

// ----------------------------------------------------------------------

export default function CampaignStatus({ title, subheader, runningCampaign, ActionButton, chart, ...other }) {
  // const theme = useTheme();

  const { colors, series, options } = chart;

  const chartSeries = series.map((i) => i.value);

  const chartOptions = useChart({
    chart: {
      sparkline: {
        enabled: true,
      },
    },
    colors,
    labels: series.map((i) => i.label),
    stroke: { colors },
    legend: {
      offsetY: 0,
      floating: true,
      position: 'bottom',
      horizontalAlign: 'center',
    },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (value) => fNumber(value),
        title: {
          formatter: (seriesName) => `${seriesName}`,
        },
      },
    },
    plotOptions: {
      pie: {
        donut: {
          size: '90%',
          labels: {
            value: {
              formatter: (value) => fNumber(value),
            },
            total: {
              formatter: (w) => {
                const sum = w.globals.seriesTotals.reduce((a, b) => a + b, 0);
                return fNumber(sum);
              },
            },
          },
        },
      },
    },
    ...options,
  });

  return (
    // <Card {...other} sx={{ height: "100%" }} >
    <>
      <CampaignHeading>{title}</CampaignHeading>

      {runningCampaign ? <Container
        style={{
          // backgroundImage: `url(${imagesUrl?.runningStatus})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          position: 'relative',
          height: '340px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Stack flexDirection={"column"} sx={{ justifyContent: "center", alignItems: "center", gap: '12px' }}>
          <Typography variant='body1'>No active campaigns found. Create a new one.</Typography>
          <ActionButton />
        </Stack>
      </Container> : <StyledChart
        dir="ltr"
        type="donut"
        series={chartSeries}
        options={chartOptions}
        width="100%"
        height={280}
      />}
    </>
    // </Card>
  );
}

CampaignStatus.propTypes = {
  chart: PropTypes.object,
  subheader: PropTypes.string,
  title: PropTypes.string,
};
