import React from 'react';
import { styled, alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: '8px',
    backgroundColor: alpha(theme.palette.grey[600], 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.grey[500], 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: theme.palette.grey[100],
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
}));

const BasicSearch = ({ text, ...others }) => {
    return (
        <Search>
            <SearchIconWrapper>
                <SearchIcon sx={{
                    color: 'grey.500'
                }} />
            </SearchIconWrapper>
            <StyledInputBase
                placeholder={text}
                inputProps={{ 'aria-label': 'search' }}
                {...others}
            />
        </Search>
    )
}

export default BasicSearch;