import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomButton = styled(Button)(({ theme }) => ({
    borderRadius: '20px',
    boxShadow: 'none',
}))

const UploadButton = ({ handleClick, fieldName }) => (
    <CustomButton variant="contained" color="primary" size='small'
        onClick={(e) => handleClick(fieldName)}
        key={fieldName}
        sx={{
            '&:hover': {
                backgroundColor: 'primary.main',
            }
        }
        }> Upload again</CustomButton >
)

export default UploadButton;