import { Box, Typography, TextField, InputAdornment, Button } from '@mui/material';
import React, { useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import CustomModal from '../../components/modal/customModal';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import './planStyle.css';
import Logger from '../../lib/Logger';
import { workSpaceMaxUser } from '../../config/commonConfig';
import { axiosGetCall, axiosPostCall } from '../../services/apisCall';
import { renderSuccessMessage } from '../../lib/toastMessage';
import { updateWalletBalance } from '../../redux/action/walletActions';
import { useDispatch } from "react-redux";
import { getItem } from '../../lib/cookies';
import jwtDecode from 'jwt-decode';
import { displayRazorpay } from '../../lib/razorpay';
const logger = new Logger("buy now");

const BuyNow = ({ open, setOpen, users, handleUser, price, plan }) => {
    const [loader, setLoader] = useState(false);
    const [checkout, setCheckout] = useState(false);
    const dispatch = useDispatch();

    const fetchBalance = async () => {
        try {
            const { balance } = await axiosGetCall("fetchWalletBalance");
            dispatch(updateWalletBalance(parseFloat(balance)));
        } catch (error) {
            console.log(error);
        }
    }

    const verifyPayment = async (data) => {
        try {
            await axiosPostCall("verifyPayment", data);
            const payload = {
                amount: (users * price),
                planName: plan,
                numberOfUsers: users,
                recurring: false,
            }
            const { message } = await axiosPostCall("assignPlan", payload);
            setCheckout(true);
            setTimeout(() => {
                setCheckout(false);
                setOpen(false);
            }, [10000]);
            renderSuccessMessage(message);
            fetchBalance();
        } catch (error) {
            console.error(error);
        }
    }

    const razorpayHandler = async () => {
        try {
            const token = getItem("token");
            const { email, name, mobile } = jwtDecode(token);
            const { balance = 0 } = await axiosGetCall("fetchWalletBalance");
            const totalAmountPay = ((users * price) - balance)
            setLoader(true);
            if (balance - (users * price) < 0) {
                const { order: { id } } = await axiosGetCall("createOrder", { amount: totalAmountPay });
                await displayRazorpay({ order: { id } }, verifyPayment);
            } else {
                const data = {
                    amount: (users * price),
                    planName: plan,
                    numberOfUsers: users,
                    recurring: false,
                }
                const { message } = await axiosPostCall("assignPlan", data);
                setCheckout(true);
                setTimeout(() => {
                    setCheckout(false);
                    setOpen(false);
                }, 1000);
                renderSuccessMessage(message);
            }
            setLoader(false);
            // setOpen(false);
        } catch (error) {
            console.log(error);
        }
    }

    const handleBlur = (value) => {
        if (plan === "Pro" && value > workSpaceMaxUser?.pro?.max) {
            handleUser(workSpaceMaxUser?.pro?.max)
        }
        if (plan === "Business" && value > workSpaceMaxUser?.business?.max) {
            handleUser(workSpaceMaxUser?.business?.max)
        }
        if (plan === "Business Pro" && value > workSpaceMaxUser?.businessPro?.max) {
            handleUser(workSpaceMaxUser?.businessPro?.max)
        }
    }

    const bestPlanRecommendation = (users) => {
        if (users <= workSpaceMaxUser?.pro?.max) {
            return "Pro";
        }

        if (users > workSpaceMaxUser?.business?.min && users <= workSpaceMaxUser?.business?.max) {
            return "Business";
        }

        if (users > workSpaceMaxUser?.businessPro?.min && users <= workSpaceMaxUser?.businessPro?.max) {
            return "Business Pro";
        }
    }

    const description = (
        <Box sx={{
            mt: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            padding: '20px'
        }
        } >
            {!checkout ? <> <Box sx={{
                display: 'flex',
                gap: '6px',
                flexDirection: 'column'
            }}>

                <Box sx={{
                    maxWidth: '350px',
                    padding: '10px',
                    mt: '10px',
                    backgroundColor: '#f8d7da',
                    borderRadius: '6px',
                    color: '#842029',

                }}>
                    {users <= 300 ? <Typography sx={{ fontSize: '14px' }}>
                        * Please buy <b>{bestPlanRecommendation(users)}</b> plan for  <b>{users}</b> licenses.
                    </Typography> : <Typography sx={{ fontSize: '14px' }}>
                        * Please Contact Support for  <b>{users}</b> licenses.
                    </Typography>}
                </Box>

                <Typography sx={{ fontSize: '14px', color: 'grey.400' }}>
                    Number of users
                </Typography>
                

                <TextField
                    id="addAmmount"
                    placeholder='Enter Number of users'
                    value={users}
                    onChange={(e) => handleUser(e.target.value)}
                    onBlur={(e) => handleBlur(e.target.value)}
                    type="Number"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <GroupAddIcon />
                            </InputAdornment>
                        ),
                    }}
                    size={'small'}
                    fullWidth
                // onChange={e => setAmount(e.target.value)}
                />
            </Box>

                <Box sx={{
                    maxWidth: '350px',
                    padding: '10px',
                    mt: '10px',
                    backgroundColor: '#fff3cd',
                    borderRadius: '6px',
                    color: '#664d03',

                }}>
                    <Typography sx={{ fontSize: '14px' }}>
                        * To purchase the  <b>{plan}</b> plan for  <b>{users}</b> licenses, <b>₹{users * price}</b> will be deducted from your <b>wallet</b>.
                    </Typography>
                </Box>

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    mt: '20px',
                    justifyContent: 'center'
                }}>
                    <LoadingButton
                        variant='contained'
                        sx={{
                            boxShadow: 'none',
                            minWidth: '180px'
                        }}
                        onClick={() => razorpayHandler()}
                        loader={loader}
                    // disabled={!amount}
                    >
                        {`Pay ${price * plan}`}
                    </LoadingButton>
                </Box> </> : <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    maxWidth: '350px'
                }}> :
                {/* <video src='small.mp4' alt='success' loop/> */}
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2" width="90px">
                    <circle class="path circle" fill="none" stroke="#089b2a" strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                    <polyline class="path check" fill="none" stroke="#089b2a" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
                </svg>
                <Typography sx={{ fontSize: '24px', fontWeight: 600 }}>
                    Congratulations!
                </Typography>
                <Typography sx={{ fontSize: '16px', textAlign: 'center', maxWidth: '270px' }}>
                    <b>Pro</b>  plan for <b>{users}</b> licences is activated on your account.
                </Typography>

                <Button variant='contained' sx={{ boxShadow: 'none', minWidth: '300px', mt: '10px' }}>
                    Back to Home
                </Button>
            </Box>}
        </Box >
    )
    return (
        <CustomModal
            open={open}
            title={"Checkout"}
            description={description}
            close={() => setOpen(false)}
        // backgroundColor='#fff'
        // action={deleteActions()}

        />
    )
}

export default BuyNow