import { Breadcrumbs, Link, styled } from "@mui/material"

const StyledLink = styled(Link)(({ theme, active }) => ({
    textDecoration: 'none',
    color: active ? theme.palette.primary.main : theme.palette.text.primary,
    fontWeight: active ? 'bold' : 'normal',
}));

const OrderBreadCrumbs = ({ links = [], separator }) => {
    const lastItem = links[links.length - 1].name;
    return (
        <Breadcrumbs separator={separator} aria-label="breadcrumb">
            {links.map(({ name, active, handleRedirect, to, disabled }) => (<StyledLink
                key={name}
                to={to}
                onClick={handleRedirect}
                active={active}
                sx={{
                ...(!active && (lastItem === name) && {
                    cursor: 'default',
                    pointerEvents: 'none',
                    color: 'text.disabled',
                })
                }}
                >
                 {name}
            </StyledLink>
    ))
}
        </Breadcrumbs >
    )
}

export default OrderBreadCrumbs;