const initialState = {
  name: ""
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {

    case "SIDEBAR_ACTIVE_MENU":
      const name = action.payload;
      return { ...state, name };

    default:
      return state;
  }
};

export default dashboardReducer;