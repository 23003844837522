import React, { useCallback, useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import {
    Box,
    FormControl, InputLabel, Select, OutlinedInput,
    Chip, MenuItem, InputAdornment, Button,
    FormControlLabel,
    Checkbox,
    Tooltip,
    IconButton
} from '@mui/material';
import * as Yup from 'yup';
import { Controller, useForm, } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import FormProvider from '../../components/hook-form/form-provider';
import LoadingButton from '@mui/lab/LoadingButton';
import { Icon } from '@iconify/react';
import { PRODUCTS } from '../../config/commonConfig';
import Logger from '../../lib/Logger';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { axiosGetCall, axiosPostCall } from '../../services/apisCall';
import { renderErrorMessage, renderSuccessMessage } from '../../lib/toastMessage';
import UploadBrandingImage from './uploadBox';
import RHFTextField from '../../components/hook-form/rhf-text-field';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { checkProductAccess } from '../../utils/common';

const logger = new Logger("brading");
const RowTow = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: '20px',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    },
}));

const RowItem = styled("div")(() => ({
    display: "flex",
    width: "100%",
    alignItems: "center",
    gap: "5px"
}))

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    // '&:not(:last-child)': {
    //   borderBottom: 0,
    // },
    '&::before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const BrandingForm = ({ advanceSetting, setAdvanceSetting }) => {
    const [previousSubmit, setPreviousSubmit] = useState({});

    const [privateKey, setPrivateKey] = useState(null);
    const [publicKey, setPublicKey] = useState(null);
    const [fullKey, setFullKey] = useState(null);
    ;
    const [update, setUpdate] = useState(false);
    const [checked, setChecked] = useState("selfSSL");
    const [selfSSL, setSelfSSl] = useState(false);
    const [productAccess] = useState(checkProductAccess());
    const [brandingUrls, setBrandingUrls] = useState({
        console: {},
        meet: {},
        vb: {},
        webinar: {},
        sms: {},
        shortUrl: {},
    })
    const inputRef = useRef();
    const inputRefPrivate = useRef();
    const inputRefChain = useRef();

    const NewCampaignSchema = Yup.object().shape({
        salesEmail: Yup.string().email('Please enter a valid email address').required('Email is required'),
        salesContacts: Yup.string().required("Sales Contacts Required"),
        siteTitle: Yup.string().required("Site Title Required"),
        companyName: Yup.string().required("Company Name Required"),
        companyBrandName: Yup.string().required("Company Brand Name Required"),
        // applicaitonUrl: Yup.string().required("Panel Domain Name Required"),
        address: Yup.string().required("Address required"),
        supportEmail: Yup.string().email('Please enter a valid email address').required('Email is required'),
        supportContacts: Yup.string().required("Support Conntacts Required"),
        fEmailAddress: Yup.string().email('Please enter a valid email address').required('Email is required'),
        // websitePrivacyPolicyUrl: Yup.string().required("Website Privacy Policy Url required"),
        // termsAndConditionUrl: Yup.string().required("Terms and Conditions required"),
        favicon: Yup.mixed(),
        logo: Yup.mixed(),
    });

    const methods = useForm({
        resolver: yupResolver(NewCampaignSchema),
        defaultValues: {
            companyName: "",
            companyBrandName: "",
            applicationUrl: "",
            siteTitle: "",
            address: "",
            salesEmail: "",
            salesContacts: "",
            supportEmail: "",
            supportContacts: "",
            fEmailAddress: "",
            privacyPolicyUrl: "",
            termsAndConditionUrl: "",
            products: ["Console"],
        }
    });

    const {
        setValue,
        handleSubmit,
        control,
        watch,
    } = methods;

    const values = watch();

    const handleFileChange = useCallback(
        (acceptedFiles, name) => {
            const file = acceptedFiles[0];

            const newFile = Object.assign(file, {
                preview: URL.createObjectURL(file),
            });

            if (file) {
                setValue(name, newFile, { shouldValidate: true });
            }
        },
        [setValue]
    );

    const handleRemoveLogo = (brand, imageName) => {
        setBrandingUrls((prev) => {
            const tempBranding = { ...prev };
            tempBranding[brand][imageName] = "";
            return tempBranding;
        })
    }


    const handleFileChangePublic = (event) => {
        const file = event.target.files[0];
        setPublicKey(file)
    };

    const handleFileChangePrivate = (event) => {
        const file = event.target.files[0];
        setPrivateKey(file)
    };

    const handleFileChangeFullKey = (event) => {
        const file = event.target.files[0];
        setFullKey(file)
    };

    const inputSx = {
        color: "grey.200",
        borderColor: 'grey.700',
        borderRadius: "6px",
    };

    const onSubmit = async (data) => {
        try {
            const form = new FormData();

            const mapper = {
                fEmailAddress: "fromEmail",
            }
            form.append("advanceSettings", JSON.stringify(advanceSetting))
            Object.keys(data).forEach((key) => {
                if (!key) return;
                if (key === "products") {
                    form.append(key, JSON.stringify(data[key]));
                } else {
                    form.append(mapper[key] ? mapper[key] : key, data[key]);
                }
            })
            form.append("sellfSSL", selfSSL);
            form.append("publicKey", publicKey);
            form.append("privateKey", privateKey);
            form.append("fullKey", fullKey);
            await axiosPostCall("createBranding", form);
            setUpdate(true)
            return renderSuccessMessage("Created successfully!")
        } catch (error) {
            logger.error("crateBranding apiCall [createBrading] ", error)
            return renderErrorMessage(error.message)
        }
    }

    const fetchSslCertificate = async (url, filename, fileName, ref) => {
        try {
            const response = await fetch(url);
            if (response.ok) {
                const blob = await response.blob();
                const file = new File([blob], fileName, { type: blob.type });
                const dataTransfer = new DataTransfer();
                dataTransfer.items.add(file);
                if (ref.current) {
                    ref.current.files = dataTransfer.files;
                }
                setValue(fileName, file);
            }
        } catch (error) {
            console.error('Error fetching the image:', error);
        }
    }

    const fetchBrandignDetails = async () => {
        try {
            const {
                companyName = "",
                companyBrandName = "",
                siteTitle = "",
                applicationUrl = "",
                address = "",
                salesEmail = "",
                salesContacts = "",
                supportEmail = "",
                supportContacts = "",
                privacyPolicyUrl = "",
                termsAndConditionUrl = "",
                fromEmail = "",
                products = "",
                console = {},
                meet = {},
                vb = {},
                webinar = {},
                sms = {},
                shortUrl = {},
                publicKeyUrl = "",
                privateKeyUrl = "",
                fullKeyUrlUrl = "",
                publicKeyOriginal = "",
                privateKeyOriginal = "",
                fullKeyOriginal = "",
                twoFa = {},
                signup = true,
                meetMedia = false,
                mothlyVerification = false,
                googleAuth = true,
                restrictedOrigin = "",
                blockedOrigin = "",
            } = await axiosGetCall("updateBrandingDetails");
            setPreviousSubmit({
                companyName,
                applicationUrl,
                companyBrandName,
                websitePrivacyPolicyUrl: privacyPolicyUrl,
                termsAndConditionUrl,
                siteTitle,
                salesEmail,
                salesContacts,
                supportContacts,
                supportEmail,
                fromEmail,
                address,
                products,
            })
            if (applicationUrl) {
                setUpdate(true);
                setBrandingUrls({
                    console,
                    meet,
                    vb,
                    webinar,
                    sms,
                    shortUrl,
                })
                setValue("companyName", companyName);
                setValue("address", address);
                setValue("applicationUrl", applicationUrl);
                setValue("companyBrandName", companyBrandName);
                setValue("address", address);
                setValue("salesContacts", salesContacts);
                setValue("salesEmail", salesEmail);
                setValue("supportContacts", supportContacts);
                setValue("supportEmail", supportEmail);
                setValue("siteTitle", siteTitle);
                setValue("privacyPolicyUrl", privacyPolicyUrl);
                setValue("termsAndConditionUrl", termsAndConditionUrl);
                setValue("fEmailAddress", fromEmail);
                setValue("products", products);
                setAdvanceSetting({
                    twoFa: Object.keys(twoFa) > 0 ? twoFa : advanceSetting?.twoFa, 
                    signUp: signup,
                    mothlyVerification,
                    googleAuth,
                    restrictedOrigin,
                    blockedOrigin,
                    meetMedia,
                })
                await fetchSslCertificate(publicKeyUrl, "publicKey", publicKeyOriginal, inputRef);
                await fetchSslCertificate(privateKeyUrl, "privateKey", privateKeyOriginal, inputRefPrivate);
                await fetchSslCertificate(fullKeyUrlUrl, "fullKey", fullKeyOriginal, inputRefChain);
            }

        } catch (error) {
            logger.error("fetchBrandingDetails apiCall [updateBrandingDetails]", error);
            return renderErrorMessage(error.messsage);
        }
    }

    const handleUpdate = async (data) => {
        try {
            let remainSame = true;

            const mapper = {
                fEmailAddress: "fromEmail",
            }
            for (const key of Object.keys(data)) {
                if (data[key] !== previousSubmit[key]) {
                    remainSame = false;
                    break;
                }
            }
            if (remainSame) {
                return renderErrorMessage("Nothing has changed");
            }

            const form = new FormData();
            form.append("advanceSettings", JSON.stringify(advanceSetting))
            Object.keys(data).forEach((key) => {
                if (!key || !data[key]) return;
                if (key === "products") {
                    form.append(key, JSON.stringify(data[key]));
                } else {
                    form.append(mapper[key] ? mapper[key] : key, data[key]);
                }
            })
            form.append("selfSSL", selfSSL);
            await axiosPostCall("updateBranding", form);
            renderSuccessMessage("Updated Successfully !!")
        } catch (error) {
            logger.error("createBranding [updateBraning] api[updateBranding]", error)
        }
    }

    const handleSslChecbox = (event) => {
        if (event.target.checked && event.target.id !== checked) {
            if(event.target.id === "selfSSL") {
                setSelfSSl(false);
            } else {
                setSelfSSl(true);
            }
         setChecked(event.target.id); 
    }
  };

    useEffect(() => {
        fetchBrandignDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [update])

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(update ? handleUpdate : onSubmit)} >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '20px',
                    width: '100%',
                    padding: '25px'
                }}
                noValidate
                autoComplete="off"
            >
                <RowTow>
                    <RowItem>
                        <RHFTextField
                            name="companyName"
                            label="Company Name"
                            variant="outlined"
                            fullWidth
                        />
                        <Tooltip
                            PopperProps={{ style: { zIndex: 9999 } }}
                            componentsProps={{
                                tooltip: {
                                    sx: {
                                        bgcolor: 'grey.500',
                                    },
                                },
                            }}
                            title={"Please enter the full legal name of your company as registered. Avoid using abbreviations or informal names to ensure accurate processing."}
                        >
                            <IconButton sx={{ ml: '0px !important' }}>
                                <InfoOutlinedIcon />
                            </IconButton>
                        </Tooltip>
                    </RowItem>

                    <RowItem>

                        <RHFTextField
                            fullWidth
                            name="applicationUrl"
                            id="outlined-basic"
                            label="Panel Domain"
                            variant="outlined"
                            InputProps={{
                                sx: inputSx,
                            }}
                        />
                        <Tooltip
                            PopperProps={{ style: { zIndex: 9999 } }}
                            componentsProps={{
                                tooltip: {
                                    sx: {
                                        bgcolor: 'grey.500',
                                    },
                                },
                            }}
                            title={"Please enter the domain name on which you want to run your website (e.g., example.com). Ensure it is a valid and correctly formatted domain name."}
                        >
                            <IconButton sx={{ ml: '0px !important' }}>
                                <InfoOutlinedIcon />
                            </IconButton>
                        </Tooltip>
                    </RowItem>
                </RowTow>

                <RowTow>

                    <RHFTextField
                        fullWidth
                        label="Company Brand Name"
                        name="companyBrandName"
                        variant="outlined"
                        InputProps={{
                            sx: inputSx,
                        }}
                    />

                    <RHFTextField
                        fullWidth
                        name="siteTitle"
                        label="Site Title"
                        variant="outlined"
                        InputProps={{
                            sx: inputSx,
                        }}
                    />


                </RowTow>

                <RowTow>

                    <RHFTextField fullWidth
                        id="outlined-basic"
                        name="salesEmail"
                        label="Sales Email"
                        variant="outlined"
                        // type="number"
                        InputProps={{
                            sx: inputSx,
                        }}
                    />

                    <RHFTextField
                        fullWidth
                        id="outlined-basic"
                        name="salesContacts"
                        label="Sales Mobile Number"
                        variant="outlined"
                        InputProps={{
                            sx: inputSx,
                        }}
                    />
                </RowTow>

                <RowTow>
                    <RHFTextField fullWidth
                        id="outlined-basic"
                        label="Support Email"
                        name="supportEmail"
                        variant="outlined"
                        InputProps={{
                            sx: inputSx,
                        }}
                    />

                    <RHFTextField
                        fullWidth
                        id="outlined-basic"
                        name="supportContacts"
                        label="Support Mobile Number"
                        variant="outlined"
                        InputProps={{
                            sx: inputSx,
                        }}
                    />
                </RowTow>

                <RowTow>
                    <RHFTextField fullWidth
                        id="outlined-basic"
                        name="fEmailAddress"
                        label="From Email Address (Mail to Clients)"
                        variant="outlined"
                        InputProps={{
                            sx: inputSx,
                        }}
                    />

                    <RHFTextField
                        fullWidth
                        id="outlined-basic"
                        label="Address"
                        name="address"
                        variant="outlined"
                        InputProps={{
                            sx: inputSx,
                        }}
                    />
                </RowTow>

                <RowTow>
                    <RowItem>
                        <RHFTextField fullWidth
                            id="outlined-basic"
                            label="Website Privacy Policy Url"
                            name="privacyPolicyUrl"
                            variant="outlined"
                            InputProps={{
                                sx: inputSx,
                            }}
                        />
                        <Tooltip
                            PopperProps={{ style: { zIndex: 9999 } }}
                            componentsProps={{
                                tooltip: {
                                    sx: {
                                        bgcolor: 'grey.500',
                                    },
                                },
                            }}
                            title={"Please enter the URL of your company's Privacy Policy. Ensure it is a valid and correctly formatted URL (e.g., https://www.example.com/privacy-policy)."}
                        >
                            <IconButton sx={{ ml: '0px !important' }}>
                                <InfoOutlinedIcon />
                            </IconButton>
                        </Tooltip>
                    </RowItem>
                    <RowItem>
                        <RHFTextField
                            fullWidth
                            id="outlined-basic"
                            label="Terms and Condition Website Url"
                            name="termsAndConditionUrl"
                            variant="outlined"
                            InputProps={{
                                sx: inputSx,
                            }}
                        />

                        <Tooltip
                            PopperProps={{ style: { zIndex: 9999 } }}
                            componentsProps={{
                                tooltip: {
                                    sx: {
                                        bgcolor: 'grey.500',
                                    },
                                },
                            }}
                            title={"Please enter the URL of your company's Terms and Conditions. Ensure it is a valid and correctly formatted URL (e.g., https://www.example.com/terms-and-conditions)."}
                        >
                            <IconButton sx={{ ml: '0px !important' }}>
                                <InfoOutlinedIcon />
                            </IconButton>
                        </Tooltip>
                    </RowItem>
                </RowTow>
                <RowTow >

                    <FormControl fullWidth>
                        <InputLabel id="multiple-chip-label">Select Products</InputLabel>
                        <Controller
                            name="products"
                            control={control}
                            defaultValue={["console"]}
                            render={({ field }) => (
                                <RowItem>
                                    <Select
                                        labelId="multiple-chip-label"
                                        id="multiple-chip"
                                        sx={{
                                            width: "50%"
                                        }}
                                        fullWidth
                                        // width={"50%"}
                                        multiple
                                        input={<OutlinedInput id="select-multiple-chip" label="Select products" />}
                                        renderValue={(selected) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {selected.map((value) => (
                                                    <Chip key={value} label={value} sx={{ color: 'grey.300' }} />
                                                ))}
                                            </Box>
                                        )}
                                        MenuProps={MenuProps}
                                        {...field}
                                    >
                                        {PRODUCTS.filter((({value}) => productAccess.includes(value))).map(({ value, label }) => (
                                            <MenuItem sx={{ color: 'grey.300' }} key={value} value={value}>{label}</MenuItem>
                                        ))}
                                    </Select>
                                    <Tooltip
                                        PopperProps={{ style: { zIndex: 9999 } }}
                                        componentsProps={{
                                            tooltip: {
                                                sx: {
                                                    bgcolor: 'grey.500',
                                                },
                                            },
                                        }}
                                        title={"Please select the product you want to feature on your website. Choose from the available options to ensure accurate configuration and display."}
                                    >
                                        <IconButton sx={{ ml: '0px !important' }}>
                                            <InfoOutlinedIcon />
                                        </IconButton>
                                    </Tooltip>
                                </RowItem>
                            )}
                        />
                    </FormControl>

                    <Box> </Box>
                </RowTow>

                <RowTow>
                    <FormControlLabel
                        control={<Checkbox id="ssl" checked={checked === "ssl"} onChange={handleSslChecbox} />}
                        label={"Use SSL"}
                    />
                    <FormControlLabel
                        control={<Checkbox id="selfSSL" checked={checked === "selfSSL"} onChange={handleSslChecbox} />}
                        label={"Use System SSL"}
                    />
                </RowTow>

                {selfSSL && <RowTow>
                    <FormControl fullWidth >
                        <InputLabel htmlFor="outlined-adornment-amount">Upload SSL certificates private key</InputLabel>
                        <OutlinedInput
                            fullWidth
                            // variant="outlined"
                            type='file'
                            InputProps={{
                                sx: inputSx,
                            }}
                            inputRef={inputRefPrivate}
                            id="outlined-adornment-amount"
                            startAdornment={<InputAdornment position="start"><Icon icon={"line-md:upload-loop"} width={"20px"} /></InputAdornment>}
                            label="Upload SSL certificates private key"
                            onChange={handleFileChangePrivate}
                            endAdornment={<InputAdornment position="end">
                                <Button
                                    variant="outlined"
                                    size="small"
                                    onClick={(e) =>
                                        inputRefPrivate.current?.dispatchEvent(
                                            new MouseEvent('click', {
                                                view: window,
                                                bubbles: true,
                                                cancelable: true,
                                                buttons: 1,
                                            }),
                                        )
                                    }
                                >
                                    Upload File
                                </Button>
                            </InputAdornment>}
                            sx={{
                                '& input::file-selector-button': {
                                    display: 'none',
                                },
                            }}
                        />

                    </FormControl>

                    <FormControl fullWidth >
                        <InputLabel htmlFor="outlined-adornment-amount">Upload SSL certificates full chain file</InputLabel>
                        <OutlinedInput
                            fullWidth
                            type='file'
                            InputProps={{
                                sx: inputSx,
                            }}

                            inputRef={inputRefChain}
                            id="outlined-adornment-amount"
                            startAdornment={<InputAdornment position="start"><Icon icon={"line-md:upload-loop"} width={"20px"} /></InputAdornment>}
                            label="Upload SSL certificates full chain file"
                            onChange={handleFileChangeFullKey}
                            endAdornment={<InputAdornment position="end">
                                <Button
                                    variant="outlined"
                                    size="small"
                                    onClick={(e) =>
                                        inputRefChain.current?.dispatchEvent(
                                            new MouseEvent('click', {
                                                view: window,
                                                bubbles: true,
                                                cancelable: true,
                                                buttons: 1,
                                            }),
                                        )
                                    }
                                >
                                    Upload File
                                </Button>
                            </InputAdornment>}
                            sx={{
                                '& input::file-selector-button': {
                                    display: 'none',
                                },
                            }}
                        />
                    </FormControl>
                </RowTow>}
                {selfSSL && <RowTow>
                    <FormControl sx={{ width: "50%" }} >
                        <InputLabel htmlFor="outlined-adornment-amount">Upload SSL certificates public key</InputLabel>
                        <OutlinedInput
                            fullWidth
                            type='file'
                            // value={""}
                            // value=""
                            InputProps={{
                                sx: inputSx,
                            }}
                            inputRef={inputRef}
                            id="outlined-adornment-amount"
                            startAdornment={<InputAdornment position="start"><Icon icon={"line-md:upload-loop"} width={"20px"} />
                            </InputAdornment>}
                            label="Upload SSL certificates public key"
                            onChange={handleFileChangePublic}
                            endAdornment={<InputAdornment position="end">
                                <Button
                                    variant="outlined"
                                    size="small"
                                    onClick={(e) =>
                                        inputRef.current?.dispatchEvent(
                                            new MouseEvent('click', {
                                                view: window,
                                                bubbles: true,
                                                cancelable: true,
                                                buttons: 1,
                                            }),
                                        )
                                    }
                                >
                                    Upload File
                                </Button>
                            </InputAdornment>}
                            sx={{
                                '& input::file-selector-button': {
                                    display: 'none',
                                },
                            }}
                        />
                    </FormControl>

                    <Box> </Box>
                </RowTow>}

                <Accordion sx={{
                    width: "100%",
                    '&::before': {
                        display: 'none',
                    },
                }}>
                    <AccordionSummary
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        Console Branding
                    </AccordionSummary>

                    <AccordionDetails sx={{ display: "flex", flexDirection: "column", gap: "25px" }}>
                        <RowTow>
                            <UploadBrandingImage
                                name="favicon"
                                label="Upload Favicon"
                                handleFileChange={handleFileChange}
                                imageDesc="Logo image size: <5MB, 130x32px dimensions"
                                onRemove={() => handleRemoveLogo("console", "faviconUrl")}
                                imageUrl={brandingUrls?.console?.faviconUrl}
                                brand={"console"}
                                imageName={"favicon"}
                            />
                            <UploadBrandingImage
                                name="logo"
                                label="Upload logo image/svg for light theme"
                                handleFileChange={handleFileChange}
                                imageDesc="Logo image size: <5MB, 130x32px dimensions"
                                onRemove={() => handleRemoveLogo("console", "logoUrlLight")}
                                imageUrl={brandingUrls?.console?.logoUrlLight}
                            />
                        </RowTow>

                        <RowTow>
                            <UploadBrandingImage
                                name="logoWithoutName"
                                label="Upload logo without name"
                                handleFileChange={handleFileChange}
                                imageDesc="Logo image size: <5MB, 32x32px dimensions"
                                onRemove={() => handleRemoveLogo("console", "logoUrlIcon")}
                                imageUrl={brandingUrls?.console?.logoUrlIcon}
                            />

                            <UploadBrandingImage
                                name="darkLogo"
                                label="Upload logo image/svg for dark theme"
                                handleFileChange={handleFileChange}
                                imageDesc="Logo image size: <5MB, 130x32px dimensions"
                                onRemove={() => handleRemoveLogo("console", "logoUrlDark")}
                                imageUrl={brandingUrls?.console?.logoUrlDark}
                            />
                        </RowTow>
                    </AccordionDetails>
                </Accordion>

                {values?.products.includes("Meet") && <Accordion sx={{
                    width: "100%",
                    '&::before': {
                        display: 'none',
                    },
                }}>
                    <AccordionSummary
                        // expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        Meet Branding
                    </AccordionSummary>
                    <AccordionDetails sx={{ display: "flex", flexDirection: "column", gap: "25px" }}>
                        <RowTow>
                            <UploadBrandingImage
                                name="meetLogo"
                                label="Upload logo image/svg for light theme"
                                handleFileChange={handleFileChange}
                                imageDesc="Logo image size: <5MB, 130x32px dimensions"
                                onRemove={() => handleRemoveLogo("meet", "meetLogoUrlLight")}
                                imageUrl={brandingUrls?.meet?.meetLogoUrlLight}
                            // onRemove={handleRemoveLogo}
                            />
                            <UploadBrandingImage
                                name="meetDarkLogo"
                                label="Upload logo image/svg for dark theme"
                                handleFileChange={handleFileChange}
                                imageDesc="Logo image size: <5MB, 130x32px dimensions"
                                onRemove={() => handleRemoveLogo("meet", "meetLogoUrlDark")}
                                imageUrl={brandingUrls?.meet?.meetLogoUrlDark}
                            />
                        </RowTow>
                    </AccordionDetails>
                </Accordion>}

                {values?.products.includes("Broadcast") && <Accordion sx={{
                    width: "100%",
                    '&::before': {
                        display: 'none',
                    },
                }}>
                    <AccordionSummary
                        // expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        Broadcast Branding
                    </AccordionSummary>
                    <AccordionDetails>
                        <RowTow>
                            <UploadBrandingImage
                                name="broadcastLogo"
                                label="Upload logo image/svg for light theme"
                                handleFileChange={handleFileChange}
                                imageDesc="Logo image size: <5MB, 130x32px dimensions"
                                onRemove={() => handleRemoveLogo("vb", "vbLogoUrlLight")}
                                imageUrl={brandingUrls?.vb?.vbLogoUrlLight}
                            />
                            <UploadBrandingImage
                                name="broadcastDarkLogo"
                                label="Upload logo image/svg for dark theme"
                                handleFileChange={handleFileChange}
                                imageDesc="Logo image size: <5MB, 130x32px dimensions"
                                onRemove={() => handleRemoveLogo("vb", "vbLogoUrlDark")}
                                imageUrl={brandingUrls?.vb?.vbLogoUrlDark}
                            />
                        </RowTow>
                    </AccordionDetails>
                </Accordion>}

                {values?.products.includes("SMS") && <Accordion sx={{
                    width: "100%",
                    '&::before': {
                        display: 'none',
                    },
                }}>
                    <AccordionSummary
                        // expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        SMS Branding
                    </AccordionSummary>
                    <AccordionDetails>
                        <RowTow>
                            {/* <FileInput
                                warning={"Favicon image size: <5MB, 32x32px dimensions"}
                                text={"Upload favicon"}
                                image={favicon}
                                filename={"Favicon"}
                                onChange={handleFileChangeFav}
                            /> */}
                            {/* <UploadBrandingImage
                                name="smsFavicon"
                                label="Upload Favicon"
                                handleFileChange={handleFileChange}
                                imageDesc="Logo image size: <5MB, 130x32px dimensions"
                                onRemove={() => handleRemoveLogo("sms", "smsFaviconUrl")}
                                imageUrl={brandingUrls?.sms?.smsFaviconUrl}
                            // onRemove={handleRemoveLogo}
                            /> */}
                            <UploadBrandingImage
                                name="smsLogo"
                                label="Upload logo image/svg for light theme"
                                handleFileChange={handleFileChange}
                                imageDesc="Logo image size: <5MB, 130x32px dimensions"
                                onRemove={() => handleRemoveLogo("sms", "smsLogoUrlLight")}
                                imageUrl={brandingUrls?.sms?.smsLogoUrlLight}
                            // onRemove={handleRemoveLogo}
                            />
                            <UploadBrandingImage
                                name="smsDarkLogo"
                                label="Upload logo image/svg for dark theme"
                                handleFileChange={handleFileChange}
                                imageDesc="Logo image size: <5MB, 130x32px dimensions"
                                onRemove={() => handleRemoveLogo("sms", "smsLogoUrlDark")}
                                imageUrl={brandingUrls?.sms?.smsLogoUrlDark}
                            // onRemove={handleRemoveLogo}
                            />
                        </RowTow>

                        {/* <RowTow>
                            <UploadBrandingImage
                                name="smsLogoWithoutName"
                                label="Upload logo without name"
                                handleFileChange={handleFileChange}
                                imageDesc="Logo image size: <5MB, 32x32px dimensions"
                                onRemove={() => handleRemoveLogo("sms", "smsLogoUrlIcon")}
                                imageUrl={brandingUrls?.sms?.smsLogoUrlIcon}
                            // onRemove={handleRemoveLogo}
                            />


                        </RowTow> */}
                    </AccordionDetails>
                </Accordion>}

                {values?.products.includes("Webinar") && <Accordion sx={{
                    width: "100%",
                    '&::before': {
                        display: 'none',
                    },
                }}>
                    <AccordionSummary
                        // expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        Webinar Branding
                    </AccordionSummary>
                    <AccordionDetails>
                        <RowTow>
                            {/* <UploadBrandingImage
                                name="webinarFavicon"
                                label="Upload Favicon"
                                handleFileChange={handleFileChange}
                                imageDesc="Logo image size: <5MB, 130x32px dimensions"
                                onRemove={() => handleRemoveLogo("webinar", "webinarFaviconUrl")}
                                imageUrl={brandingUrls?.webinar?.webinarFaviconUrl}
                            // onRemove={handleRemoveLogo}
                            /> */}
                            <UploadBrandingImage
                                name="webinarLogo"
                                label="Upload logo image/svg for light theme"
                                handleFileChange={handleFileChange}
                                imageDesc="Logo image size: <5MB, 130x32px dimensions"
                                onRemove={() => handleRemoveLogo("webinar", "webinarLogoUrlLight")}
                                imageUrl={brandingUrls?.webinar?.webinarLogoUrlLight}
                            // onRemove={handleRemoveLogo}
                            />
                            <UploadBrandingImage
                                name="webinarDarkLogo"
                                label="Upload logo image/svg for dark theme"
                                handleFileChange={handleFileChange}
                                imageDesc="Logo image size: <5MB, 130x32px dimensions"
                                onRemove={() => handleRemoveLogo("webinar", "webinarLogoUrlDark")}
                                imageUrl={brandingUrls?.webinar?.webinarLogoUrlDark}
                            // onRemove={handleRemoveLogo}
                            />
                        </RowTow>

                        {/* <RowTow>
                            <UploadBrandingImage
                                name="webinarLogoWithoutName"
                                label="Upload logo without name"
                                handleFileChange={handleFileChange}
                                imageDesc="Logo image size: <5MB, 32x32px dimensions"
                                onRemove={() => handleRemoveLogo("webinar", "webinarLogoUrlIcon")}
                                imageUrl={brandingUrls?.webinar?.webinarLogoUrlIcon}
                            // onRemove={handleRemoveLogo}
                            />

   
                        </RowTow> */}
                    </AccordionDetails>
                </Accordion>}

                {/*values?.products.includes("shortUrl") && <Accordion sx={{
                    width: "100%",
                    '&::before': {
                        display: 'none',
                    },
                }}>
                    <AccordionSummary
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        Short url Branding
                    </AccordionSummary>
                    <AccordionDetails>
                        <RowTow>
                            <UploadBrandingImage
                                name="shortUrlLogo"
                                label="Upload logo image/svg for light theme"
                                handleFileChange={handleFileChange}
                                imageDesc="Logo image size: <5MB, 130x32px dimensions"
                                onRemove={() => handleRemoveLogo("shortUrl", "shortUrlLogoUrlLight")}
                                imageUrl={brandingUrls?.shortUrl?.shortUrlLogoUrlLight}
                            // onRemove={handleRemoveLogo}
                            />

                            <UploadBrandingImage
                                name="shortUrlDarkLogo"
                                label="Upload logo image/svg for dark theme"
                                handleFileChange={handleFileChange}
                                imageDesc="Logo image size: <5MB, 130x32px dimensions"
                                onRemove={() => handleRemoveLogo("shortUrl", "shortUrlLogoUrlDark")}
                                imageUrl={brandingUrls?.shortUrl?.shortUrlLogoUrlDark}
                            // onRemove={handleRemoveLogo}
                            />
                        </RowTow>
                    </AccordionDetails>
                </Accordion>*/}

                <Box sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    pt: '10px',
                    pb: '20px'
                }}>
                    <LoadingButton
                        variant='contained'
                        type='submit'
                        loading={false}
                        sx={{ boxShadow: 'none' }}
                    // onClick={() => update ? handleUpdate() : createBranding()}
                    >
                        {update ? "Update Branding" : "Create Branding"}
                    </LoadingButton>
                </Box>
            </Box>
        </FormProvider>

    )
}

export default BrandingForm;
/* 
import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function CustomizedAccordions() {
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>Collapsible Group Item #1</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum dolor
            sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
            sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography>Collapsible Group Item #2</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum dolor
            sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
            sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>Collapsible Group Item #3</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum dolor
            sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
            sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
} */
