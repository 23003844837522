import React from 'react'

import { styled } from '@mui/material/styles';
import { Button, Box, Typography } from '@mui/material';
import bgUrl from './deepcallBg.png'

const CardsMainSection = styled('div')(({ theme }) => ({
    height: '100%',
    padding: '8px 15px',
    width: 'calc(100% / 2)',
    borderRadius: "6px",
    border: '1px solid',
    backgroundColor: theme.palette.card.main,
    borderColor: theme.palette.grey[1000],
    [theme.breakpoints.down('md')]: {
        width: '100%',
    },
    [theme.breakpoints.between('md', 'lg')]: {
        width: '100%',
        minHeight: '220px'
    },
}));

const CardsInnerSection = styled('div')(({ theme }) => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '19px 36px',
    backgroundImage: theme.palette.mode === "light" ? `url(${bgUrl})` : "",
    // filter: 'blur(2px)',
    gap: '10px',
}));

const CustomBuuton = styled(Button)(() => ({
    boxShadow: 'none',
    borderRadius: '23px',
}));

const DeepCallSection = () => {
    return (
        <CardsMainSection>
            <CardsInnerSection>
                <Box sx={{
                    backgroundColor: "card.main",
                    display: 'flex',
                    justifyContent: 'center'
                    // filter: 'blur(0px)',
                    // color: 'grey.1000'
                }}>
                    {/* <DeepCallLogo /> */}
                    <Typography
                        sx={{
                            fontSize: '24px',
                            fontWeight: 500,
                            textAlign: 'center'
                        }}
                    >Unified Communication</Typography>
                </Box>
                <Box sx={{
                    // backgroundColor: "rgba(255, 255, 255, 0.80)",
                    // filter: 'blur(-2px)',
                    // color: '#fff'
                }}>
                    <CustomBuuton
                        variant='contained'
                        sx={{
                            backgroundColor: 'primary.main',
                            '&:hover': {
                                backgroundColor: 'primary.main',
                            }
                        }}
                    >
                        Coming Soon
                    </CustomBuuton>
                </Box>
            </CardsInnerSection>
        </CardsMainSection>
    )
}

export default DeepCallSection;
