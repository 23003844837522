const imagesUrl = {
  walletSystem: "./assets/images/imageScroller/walletSystem.svg",
  businessRevenue: "./assets/images/imageScroller/businessRevenue.png",
  roketWallet: "./assets/images/imageScroller/roketWallet.svg",
  loginServiceLftImg: "./assets/images/imageScroller/loginServiceLftImg.png",
  logoWithName: "./assets/images/logo/logoWithName.svg",
  authBackground: "./assets/images/background/authBackground.png",
  loading: "./assets/images/loading/loader.svg",
  dark: "./assets/images/appearance/dark.png",
  light: "./assets/images/appearance/light.png",
  system: "./assets/images/appearance/system.png",
  completion: "./assets/images/submit/checkmark.png"
};

const RECAPTCHA_SITE_API_KEY = "6Lc37cIpAAAAAEoFRYYpGjy0HRDA9G51GcdOtfyd";
const messageCookiesExpTimer = 1 / 48;

const CUSTOM_ROUTE = "custom_session";

const PLATFORM_MAPPER = {
  101: "webinar",
  102: "meet",
  103: "sms",
  104: "voice",
  105: "chat", 
  106: "lists",
  107: "drive",
};

const PRODUCTS = [
  { value: "Console", label: "Console" },
  { value: "Meet", label: "Meet" },
  { value: "Webinar", label: "Webinar" },
  { value: "Broadcast", label: "Broadcast" },
  { value: "SMS", label: "SMS" },
  { value: "ShortUrl", label: "Short url" },
  { value: "Chat", label: "Chat" },
  { value: "Lists", label: "Lists"},
  { value: "Drive", label: "Drive"},
]

const razorpayConfig = {
  checkoutUrl: 'https://checkout.razorpay.com/v1/checkout.js',
  key: 'rzp_test_uD3HnYCnTX1EfZ'
}

const workspacePricing = {
  pro: 60,
  business: 50,
  businessPro: 47,
};

const workSpaceMaxUser = {
  pro: { min: 1, max: 9 },
  business: { min: 10, max: 200 },
  businessPro: { min: 201, max: 300 },
}

const PLAN_ID_MAPPER = {
  free: 100,
  pro: 101,
  business: 102,
  businessPro: 103
}

const workSpaceName = {
  pro: "Sarv Worspace Pro",
  business: "Sarv Wokrspace Business",
  businessPro: "Sarv Workspace Business Pro",
}

export {
  imagesUrl,
  RECAPTCHA_SITE_API_KEY,
  messageCookiesExpTimer,
  CUSTOM_ROUTE,
  PLATFORM_MAPPER,
  PRODUCTS,
  razorpayConfig,
  workspacePricing,
  workSpaceMaxUser,
  workSpaceName,
  PLAN_ID_MAPPER
};
