//react
import React from 'react';

//@mui
import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

//@mui icons
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import KycVerification from '../../assets/svgs/kycVerification';

import PropTypes from 'prop-types';

const Heading = styled(Typography)(({ theme }) => ({
    fontSize: '24px',
    fontWeight: 500,
    color: theme.palette.grey[100],
    lineHeight: '40px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
        fontSize: '20px',
    },
}));

const HeadingBox = styled('div')(({ theme }) => ({
    width: '450px',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
        width: '350px'
    },
}));

const StartKyc = ({ current, next }) => {
    const textSpanSx = { color: '#7ABB89' };

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '30px',
        }}>
            <KycVerification />

            <HeadingBox>
                <Heading>
                    Complete your <span style={textSpanSx}> KYC </span> today & enjoy full <span style={textSpanSx}> benefits </span> of  <span style={textSpanSx}> all services. </span>
                </Heading>
            </HeadingBox>

            <Button
                variant='contained'
                endIcon={<ArrowForwardIcon />}
                onClick={() => {
                    current(false)
                    next(true)
                }}
                sx={{
                    boxShadow: 'none'
                }}
            >
                Start Now
            </Button>
        </Box>
    )
}

StartKyc.propTypes = {
    current: PropTypes.func.isRequired,
    next: PropTypes.func.isRequired,
}

export default StartKyc;
