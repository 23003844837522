//@mui
import { Box, TextField, Typography } from "@mui/material";

import PropTypes from 'prop-types';

const KycInput = ({ type = "text", inputName, inputId, inputPlaceholder, value, setValue, error = false, setError }) => {
    const handleChange = (value) => setValue(value)

    const handleClick = () => setError(false)

    return (
        <Box sx={{
            display: 'flex',
            gap: '6px',
            flexDirection: 'column',
            width: '100%'
        }}>
            <Typography sx={{ fontSize: '14px', fontWeight: 500, color: "grey.300" }}>{inputName}</Typography>

            <TextField
                id={inputId}
                error={error}
                type={type}
                value={value}
                onChange={(e) => handleChange(e.target.value)}
                InputProps={{
                    sx: {
                        color: "grey.200",
                        border: "1px solid",
                        borderColor: 'grey.700',
                        borderRadius: "6px",
                    },
                }}
                placeholder={inputPlaceholder}
                variant="outlined"
                sx={{
                    color: 'grey.500',
                    width: "100%",
                    backgroundColor: 'grey.800',
                    borderRadius: '6px',
                    "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                            borderColor: "grey.700"
                        },
                        '&:hover fieldset': {
                            borderColor: "grey.700"
                        }
                    },
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                        display: "none",
                    },
                    "& input[type=number]": {
                        MozAppearance: "textfield",
                    },

                }}
                onClick={() => handleClick()}
            />
        </Box>

    )
}

KycInput.propTypes = {
    type: PropTypes.string,
    inputName: PropTypes.string.isRequired,
    inputId: PropTypes.string.isRequired,
    inputPlaceholder: PropTypes.string.isRequired,
    value: PropTypes.string,
    setValue: PropTypes.func.isRequired,
    setError: PropTypes.func.isRequired
}
export default KycInput;