
import { styled } from '@mui/material/styles';
import { Typography, Box } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import ProfilePicture from '../sections/users/profilePicture';
import NewUserForm from '../sections/users/form';
import { useEffect, useState } from 'react';
import { checkPermission, compareObjectValues } from '../utils/common';
import { useLocation, useNavigate } from 'react-router-dom';
import { axiosPostCall } from '../services/apisCall';
import { renderErrorMessage, renderSuccessMessage } from '../lib/toastMessage';

const MainSection = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '14px',
    // padding: '76px 12px 12px 70px',
    padding: '76px 82px 12px 140px',
    height: '100%',
    overflowY: 'scroll',
    // overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
        padding: '70px 14px 14px 14px',
        height: 'auto',
    },
}));

const Card = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    borderRadius: "6px",
    border: '1px solid',
    overflow: 'hidden',
    backgroundColor: theme.palette.card.main,
    borderColor: theme.palette.grey[1000],
    width: '40%',
    [theme.breakpoints.down('sm')]: {
        width: '100%',
    },
}));

const HeadingTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.grey[100],
    fontSize: '20px',
    fontWeight: 600,

}));

const FormSection = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    gap: '10px',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    },
}));

const NewUsers = () => {
    const navigate = useNavigate();
    const [verified, setVeried] = useState(false);
    const [image, setImage] = useState(null);
    const [edit, setEdit] = useState(false);
    const [userDetails, setUserDetails] = useState({})

    const location = useLocation();

    const createNewUser = async (data) => {
        try {
            const { name, email, contact, password, address, city, code, role, permission, products, gender, maritalStatus, flag, dDate, optionalParameters, userName, loginLimit, productPermissions } = data;

            const formData = new FormData();

            const dataObj = {
                name, email, password, mobile: contact, countryCode: "91", address,
                zipcode: code, city, role, permission, products, gender, suspendTime: dDate, flag,
                maritalStatus, forceVerify: verified, profileName: image?.name || "", optionalParameters,
                userName, loginLimit, productPermissions
            }

            formData.append("data", JSON.stringify(dataObj))
            formData.append("profilePhoto", image);

            if (!edit) {

                const { message } = await axiosPostCall("createUser", formData);
                renderSuccessMessage(message || "New user crated successfully");

                navigate('/users');
            }
            else {
                if (!compareObjectValues(userDetails, dataObj)) {
                    return renderErrorMessage("No thing has been changed for update.")

                }
                formData.append("email", userDetails?.email);
                const { message } = await axiosPostCall("updateUser", formData);
                renderSuccessMessage(message || "New user crated successfully");

                navigate('/users');

            }

        } catch (error) {
            console.log(error);
            renderErrorMessage(error?.message || 'Network error!')
        }
    }

    useEffect(() => {
        const permission = checkPermission()
        if (permission !== 'l4') return navigate('/');
        const { edit = false, userDetails = {} } = location?.state || {};

        if (edit) {
            setEdit(edit);
            const { forceVerify } = userDetails;
            setVeried(forceVerify);

            setUserDetails(userDetails)
        }

        //eslint-disable-next-line
    }, [])

    return (
        <MainSection>
            <ToastContainer />

            <Box sx={{ width: '100%' }}>
                <HeadingTypography>
                    {edit ? "Update User" : "Create new user"}
                </HeadingTypography>
            </Box>

            <FormSection >
                <Card sx={{ maxHeight: '420px', minHeight: "420px", justifyContent: 'center' }}>

                    <ProfilePicture
                        verified={verified}
                        setVeried={setVeried}
                        image={image}
                        setImage={setImage}
                    />

                </Card>

                <Card sx={{ width: '100%' }}>
                    <NewUserForm handleCreateNewUser={createNewUser} edit={edit} userDetails={userDetails} />
                </Card>
            </FormSection>

        </MainSection>
    )
}

export default NewUsers