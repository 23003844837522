import React, { useEffect, useState } from 'react';
import { IconButton, Paper, TextField, Select, MenuItem, InputLabel, FormControl, Typography } from '@mui/material';
import SpinnerLogo from "../../assets/svgs/spinnerLogo";
import useRecaptchaV3 from '../../hooks/useRecaptcha';
import "./chatStyles.css"
import { configFile } from '../../config';
import { axiosPostCall } from '../../services/apisCall';
import Logger from '../../lib/Logger';
import MessageBanner from '../../layouts/Banners/MessageBanner';
import Submition from './submit';
import LoadingButton from '@mui/lab/LoadingButton';
const { RECAPTCHA_SITE_API_KEY } = configFile;
const logger = new Logger("chat");


const ChatSection = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [formData, setFormData] = useState({ name: '', email: '', phone: '', label: "" });
    const executeRecaptcha = useRecaptchaV3(RECAPTCHA_SITE_API_KEY);
    const [errorMsg, setErrorMsg] = useState("");
    const [submited, setSubmited] = useState(false);
    const [loading, setLoading] = useState(false);

    const toggleChat = () => {
        setIsOpen(!isOpen);
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            setLoading(true);
            const recaptchaToken = await executeRecaptcha("chat");
            await axiosPostCall("leads", { formData, recaptchaToken })
            setFormData({ name: '', email: '', phone: '', label: "" });
            setSubmited(true);
            setLoading(false);
        } catch (error) {
            logger.error(error);
            setLoading(false)
            setErrorMsg(error.message)
        }
    };

    useEffect(() => {
        if (errorMsg) {
            setTimeout(() => {
                setErrorMsg("");
                console.log(errorMsg);
            }, 5000)
        }
    }, [errorMsg])



    return (
        <div className='chat' style={{ position: 'fixed', bottom: 20, right: 20 }}>
            <IconButton onClick={toggleChat}>
                <SpinnerLogo />
            </IconButton>
            {isOpen &&  (
                <Paper elevation={3} sx={{ alignItems: "center", justifyContent: "center", width: "392px", position: 'fixed', bottom: 80, right: 20, gap: "10px", padding: 5, }}>
                {!submited ? <>
                    {errorMsg && <MessageBanner message={errorMsg} close={() => setErrorMsg("")} />}
                    <form onSubmit={handleSubmit} fullWidth>
                        <TextField
                            fullWidth
                            label="Name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            margin="normal"
                            inputProps={{
                                sx: {
                                    height: '30px',
                                    fontSize: '14px',
                                    color: '#282828'
                                },
                            }}
                        />
                        <TextField
                            fullWidth
                            label="Email"
                            name="email"
                            type="email"
                            value={formData.email}
                            onChange={handleChange}
                            margin="normal"
                            inputProps={{
                                sx: {
                                    height: '30px',
                                    fontSize: '14px',
                                    color: '#282828'
                                },
                            }}
                        />
                        <TextField
                            fullWidth
                            label="Phone"
                            name="phone"
                            type="tel"
                            value={formData.phone}
                            onChange={handleChange}
                            margin="normal"
                            inputProps={{
                                sx: {
                                    height: '30px',
                                    fontSize: '14px',
                                    color: '#282828'
                                },
                            }}
                        />
                        <FormControl fullWidth margin='normal'>
                            <InputLabel id="label">Label</InputLabel>
                            <Select
                                labelId="label"
                                name="label"
                                value={formData.label}
                                label="label"
                                fullWidth
                                onChange={handleChange}
                                sx={{color: "#282828"}}
                                inputProps={{
                                    sx:{
                                        color: "#282828"
                                    },
                                    MenuProps: {
                                        MenuListProps: {
                                            sx: {
                                                color: '#282828'
                                            }
                                        }
                                    }
                                }}
                            >
                                <MenuItem value={"getDemo"}>Get Demo</MenuItem>
                                <MenuItem value={"getCall"}>Get Call</MenuItem>
                            </Select>
                        </FormControl>
                        <Typography variant='body2' sx={{color: 'grey.500'}}>This site is protected by reCAPTCHA and the Google
                            <a href="https://policies.google.com/privacy">Privacy Policy</a> and
                            <a href="https://policies.google.com/terms">Terms of Service</a> apply.
                        </Typography>
                        <LoadingButton loading={loading} type="submit" variant="contained" color="primary" style={{ marginTop: 10 }}>
                            Submit
                        </LoadingButton>
                    </form></> : <Submition/>
                    }
                </Paper> 
            )}
        </div>
    );
}

export default ChatSection;
