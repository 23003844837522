import React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';

const StyledBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(3),
  // border: `1px dashed ${theme.palette.divider}`,
  // borderRadius: theme.shape.borderRadius,
  maxWidth: "100px",
}));

const ImageBox = ({ imageSrc, onRemove }) => {
  return (
    <StyledBox>
      <img src={imageSrc}  style={{ width: '100%', height: "100%" }} alt="Brnding" />
      <IconButton
        aria-label="remove"
        onClick={onRemove}
        sx={{ position: 'absolute', top: 8, right: 8 }}
      >
        <CloseIcon />
      </IconButton>
    </StyledBox>
  );
};

export default ImageBox;