import * as React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';

export default function CircularProgressWithLabel(props) {
  return (
    <Tooltip title={`For security purpose password will automaticaly hide in 10 seconds`} componentsProps={{
      tooltip: {
        sx: {
          bgcolor: 'grey.300',
        },
      },
    }}>
      <Box sx={{ position: 'relative', display: 'inline-flex', width: '20px', }}>

        <CircularProgress variant="determinate" {...props} />
        <Box
          sx={{
            top: 0,
            left: "-20px",
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography component="div" color="grey.400" sx={{ fontSize: '8px' }}>
            {`${props.value / 10}`}
          </Typography>
        </Box>
      </Box>
    </Tooltip>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};
