import React from "react";
import { imagesUrl } from "../../../config/commonConfig";
import Grid from '@mui/material/Unstable_Grid2';
import SignUpForm from "../../../components/Auth/Signup";

function SignUp() {
    return (
        <Grid sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            background: {
                xs: '#fff',
                sm: `#0F478D 0px -64.059px / 100% 115.26% url(${imagesUrl?.authBackground})`,
            },
            backgroundBlendMode: 'multiply',
            backgroundRepeat: "repeat"
        }}>
            <SignUpForm />
        </Grid>
    )
}

export default SignUp;