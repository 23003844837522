import React, { useEffect, useState } from 'react'
import CustomModal from '../../components/modal/customModal'
import { Box, Button, styled, Typography, Skeleton } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { axiosGetCall } from '../../services/apisCall';
import moment from 'moment';
import Logger from '../../lib/Logger';

const logger = new Logger("activity")


const CustomTableCell = styled(TableCell)(({ theme }) => ({
    backgroundColor: theme.palette.grey[1000],
    color: theme.palette.grey[300],
    '&:hover': {
        color: theme.palette.grey[200],
    }
}));

const columns = [
    { id: 'id', label: 'S NO', minWidth: 100, align: "center" },
    { id: 'label', label: 'Labels', minWidth: 500, align: "center" },
    { id: 'createdAt', label: 'Time', minWidth: 200, align: "center" },
];


const ActivityModal = ({ /* data, */ openActivity, handleClose, setOpenActivity, email }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [row, setRow,] = useState([]);
    const [isListLoading, setIsLoading] = useState(false)
    const [count, setCount] = useState(0);
    const [load, setLoad] = useState(false);
    // const { email } = data;

    const fetchActivity = async () => {
        try {
            setIsLoading(true)

            const skip = rowsPerPage * page
            const { userActivity = [], userActivityCount = 0 } = await axiosGetCall("userActivity", { skip, limit: rowsPerPage, email })
            setRow(userActivity);
            setCount(userActivityCount)

        } catch (error) {
            setRow([]);
            setCount(0)
            logger.error(error)
        }
        setIsLoading(false)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setLoad(true)
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
        setLoad(true);

    };

    const forceActions = () => (
        <Box sx={{
            mt: 3,
            mb: -2,
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            gap: '10px',
            justifyContent: 'flex-end'
        }}>
            <Button
                variant='outlined'
                size='small'
                onClick={() => {
                    setOpenActivity(false);
                    handleClose(false)
                }}
            >
                Close
            </Button>
        </Box>
    )

    useEffect(() => {
        fetchActivity();
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (load) {
            fetchActivity()
            setLoad(false)
        }
        //eslint-disable-next-line
    }, [load])

    const description = (
        <Box sx={{
            mt: 1.3,
            display: 'flex',
            flexDirection: 'column',
            gap: '15px',
            width: '100%',
            // padding: '20px'
        }}>
            <Paper sx={{ width: '100%', borderRadius: '6px' }}>
                <TableContainer sx={{
                    maxHeight: window.innerHeight - 225//'calc(100% - 200px)' //684 
                }}>
                    <Table stickyHeader aria-label="sticky table" size='small'>
                        <TableHead sx={{
                            backgroundColor: 'grey.1000',
                            borderTop: '1px solid',
                            borderBottom: '1px solid',
                            borderColor: 'grey.900'
                        }}>
                            <TableRow>
                                {columns.map((column) => (
                                    <CustomTableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                    >
                                        {column.label}
                                    </CustomTableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!!row.length && row.map((row, index) => {
                                const { label, createdAt } = row;
                                const date = moment(createdAt);
                                const formattedDate = date.format('D MMM, YYYY');
                                const formattedTime = date.format('h:mm A');
                                const Sno = index + 1;

                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                        <TableCell align={"center"} sx={{ color: 'grey.300', fontWeight: 500 }}>
                                            {Sno}
                                        </TableCell>
                                        <TableCell align={"center"} sx={{ color: 'grey.300', fontWeight: 500 }}>
                                            {label}
                                        </TableCell>
                                        <TableCell align={"center"} sx={{ color: 'grey.300', fontWeight: 500 }}>
                                            <Typography><Typography>{formattedDate}</Typography><Typography sx={{ color: 'grey.600' }}>{formattedTime}</Typography></Typography>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}

                            {/* Loading case */}
                            {isListLoading && <>
                                <TableRow hover role="checkbox" tabIndex={-1}>
                                    {columns.map((column) => {
                                        const id = column.id;
                                        return (
                                            <TableCell key={id} align={column.align} sx={{ color: 'grey.300', fontWeight: 500 }}>
                                                <Typography component="div" key={"body1"} variant={"body1"} sx={{ maxWidth: '70%' }}>
                                                    <Skeleton />
                                                </Typography>
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                                <TableRow hover role="checkbox" tabIndex={-1}>
                                    {columns.map((column) => {
                                        const id = column.id;
                                        return (
                                            <TableCell key={id} align={column.align} sx={{ color: 'grey.300', fontWeight: 500 }}>
                                                <Typography component="div" key={"body1"} variant={"body1"} sx={{ maxWidth: '70%' }}>
                                                    <Skeleton />
                                                </Typography>
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            </>}
                        </TableBody>
                    </Table>
                </TableContainer>

                {(!row.length && !isListLoading) &&
                    <Box sx={{
                        width: '100%',
                        display: "flex",
                        justifyContent: 'center',
                        alignItems: 'center',
                        minHeight: '200px'
                    }}>
                        <Typography>
                            No more data found!
                        </Typography>
                    </Box>
                }

                <TablePagination
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                // slotProps={{
                //     MenuProps: {
                //         sx: {
                //             '.MuiPaper-root': {
                //                 color: 'grey.200',
                //                 zIndex: 1301
                //             },
                //         },
                //     },
                // }}
                // sx={{
                //     position: "sticky",
                //     color: 'grey.200',
                //     borderTop: '0.5px solid',
                //     borderColor: 'grey.900',
                //     zIndex: 9999999999999
                // }}
                />

            </Paper>

        </Box>
    )

    return (
        <CustomModal
            open={openActivity}
            title={"User Activity"}
            description={description}
            close={() => setOpenActivity(false)}
            action={forceActions()}
            isCloseIcon={false}
        />
    )
}

export default ActivityModal