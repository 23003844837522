import React from 'react'
import { Box, Link, Typography } from "@mui/material";
import PropTypes from 'prop-types';
import LoadingButton from '@mui/lab/LoadingButton';

function AuthFooter(props) {
    const { btnText = "", navigationMessage = "", navigateText = "", btnCheckHandler, navigationCheckHandler, loadingBtn = false, signUp = true } = props;

    return (
        <Box sx={{
            display: 'flex',
            gap: '10px',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            {btnText && < LoadingButton
                variant="contained"
                size="small"
                loading={loadingBtn}
                loadingPosition="center"
                onClick={btnCheckHandler}
                sx={{
                    boxShadow: 'none',
                    backgroundColor: '#3069B0',
                    borderRadius: '4px',
                    fontSize: 'inherit',
                    fontWeight: 500,
                    width: '100%',
                    height: '40px',
                    '&:hover': {
                        backgroundColor: '#26548d'
                    }
                }}
            >
                {btnText}
            </LoadingButton>}
            {signUp && <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '6px',
                p: '12px 0px'
                // justifyContent: 'center',
            }}>
                <Typography sx={{
                    fontWeight: '500',
                    fontSize: '13px',
                    color: '#8D8D8D'
                }}>
                    {navigationMessage}
                </Typography>
                <Link variant="body2" underline="none" onClick={navigationCheckHandler} sx={{
                    cursor: 'pointer',
                    color: '#3069B0',
                    fontSize: '13px',
                    fontWeight: '500',
                }}>
                    {navigateText}
                </Link>
            </Box>}

        </Box >
    )
}

AuthFooter.propType = {
    btnText: PropTypes.string.isRequired,
    navigateText: PropTypes.string.isRequired,
    btnCheckHandler: PropTypes.func,
    navigationCheckHandler: PropTypes.func,
    loadingBtn: PropTypes.bool
}

export default AuthFooter;
