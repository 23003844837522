import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import VerificationForm from "../../../components/Auth/Verification";
import { imagesUrl } from "../../../config/commonConfig";

export default function Verification() {
  return (
    <Grid
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        background: {
          xs: '#fff',
          sm: `#0F478D 0px -64.059px / 100% 115.26% url(${imagesUrl?.authBackground})`,
        },
        backgroundBlendMode: 'multiply',
        backgroundRepeat: "repeat"
      }}
    >
      <VerificationForm />
    </Grid>
  );
}
