import React, { useContext } from 'react';
import { BrandingContext } from '../../context/brandingContext';

const SpinnerLogo = ({ width = 36, height = 36 }) => {
  const { logoUrlIcon = "" } = useContext(BrandingContext);

  return (
    logoUrlIcon ? <img src={logoUrlIcon} alt="Logo" draggable={false} />
      : <></>
  )
}

export default SpinnerLogo;