//@mui
import { Box, Button, InputAdornment, TextField, Typography } from "@mui/material";
import AttachmentIcon from '@mui/icons-material/Attachment';
import PropTypes from 'prop-types';

const KycFileInput = ({ InputName, inputId, inputRef, setFile }) => {
    const handleChange = (file) => setFile(file)
    
    return (
        <Box sx={{
            display: 'flex',
            gap: '6px',
            flexDirection: 'column',
            width: '100%'
        }}>
            <Typography sx={{ fontSize: '14px', fontWeight: 500, color: "grey.300" }}>{InputName}</Typography>

            <TextField
                id={inputId}
                variant="outlined"
                type="file"
                onChange={(e)=>handleChange(e.target.files[0])}
                sx={{
                    color: 'grey.500',
                    backgroundColor: 'grey.800',
                    borderRadius: '6px',
                    "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                            borderColor: "grey.700"
                        },
                        '&:hover fieldset': {
                            borderColor: "grey.700"
                        }
                    },
                    '& input::file-selector-button': {
                        display: 'none',
                    },
                }}
                inputRef={inputRef}
                InputProps={{
                    sx: {
                        color: "grey.500",
                        border: "1px solid",
                        borderColor: 'grey.700',
                        borderRadius: "6px",

                    },
                    endAdornment: (
                        <InputAdornment position="end">
                            <Button
                                variant="outlined"
                                size="small"
                                onClick={(e) =>
                                    inputRef.current?.dispatchEvent(
                                        new MouseEvent('click', {
                                            view: window,
                                            bubbles: true,
                                            cancelable: true,
                                            buttons: 1,
                                        }),
                                    )
                                }
                                sx={{
                                    color: '#fff',
                                    backgroundColor: '#C09936',
                                    border: 'none',
                                    "&:hover": {
                                        color: '#fff',
                                        backgroundColor: '#C09936',
                                        border: 'none',
                                    }
                                }}
                            >
                                Choose File
                            </Button>
                        </InputAdornment>
                    ),
                    startAdornment: (
                        <InputAdornment position="start">
                            <AttachmentIcon sx={{ transform: 'rotate(-45deg)', color: "grey.500" }} />
                        </InputAdornment>
                    ),
                }}
            />
        </Box>
    )
}

KycFileInput.propTypes = {
    InputName: PropTypes.string.isRequired,
    inputId: PropTypes.string.isRequired,
    setFile: PropTypes.func.isRequired
}
export default KycFileInput;