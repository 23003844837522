//react
import React, { useState, useRef } from 'react';

//@mui
import { Box, Typography, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { styled } from '@mui/material/styles';

//@mui icons
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

//components
import KycInput from '../../components/Dashboard/KycInput/KycInput';
import KycFileInput from '../../components/Dashboard/KycInput/KycFileInput';

// kyc Common validator function
import { validateGstNumber, validatePanNumber, validateUploadFile } from '../../utils/kycCommon';

import { renderErrorMessage } from '../../lib/toastMessage';
import ErrorMessage from "../../utils/ErrorMessage.json"
import PropTypes from 'prop-types';
import ShowNote from './showNote';
import { axiosPostCall } from '../../services/apisCall';
import Logger from '../../lib/Logger';
import { LoadingButton } from '@mui/lab';

const MainSection = styled('div')(({ theme }) => ({
    display: 'flex',
    gap: '20px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '50%',
    [theme.breakpoints.down('sm')]: {
        width: '100%',
    },
}));

const FormRowSection = styled('div')(({ theme }) => ({
    display: 'flex',
    gap: '10px',
    flexDirection: 'row',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    },
}));

const ComapnyProfile = ({ current, next, formData }) => {
    const gstRef = useRef();
    const panRef = useRef();
    const logger = new Logger("companyProfile");
    // const crfFormRef = useRef()

    const [companyName, setCompanyName] = useState("")
    const [gstNumber, setGstNumber] = useState("")
    const [panNumber, setPanNumber] = useState("")
    const [gstFile, setGstFile] = useState()
    const [panFile, setPanFile] = useState()
    const [crfForm,] = useState()
    const [loading, setLoading] = useState(false);

    const [nameError, setNameError] = useState(false)
    const [gstError, setGstError] = useState(false)
    const [panError, setPanError] = useState(false)
    const [checked, setChecked] = useState(true)

    const InActiveNextBtn = {
        backgroundColor: '#727374',
        color: "#B8B9BA",
        '&:hover': {
            backgroundColor: '#727374',
            color: "#B8B9BA",
        }
    }

    const handleSubmit = async () => {
        try {
            const files = [gstFile, panFile, crfForm]
            const form = new FormData();

            let fileError = false
            files.filter((file) => file).forEach(file => {
                if (!validateUploadFile(file)) {
                    fileError = true
                }
            });
            if (fileError) return renderErrorMessage(ErrorMessage?.[4013])

            if (checked && !validateGstNumber(gstNumber)) {
                setGstError(true)
                return renderErrorMessage(ErrorMessage?.[4016])
            }

            if (!validatePanNumber(panNumber)) {
                setPanError(true)
                return renderErrorMessage(ErrorMessage?.[4014])
            }

            form.append("gstStatus", checked)
            form.append("companyName", companyName)
            form.append("panNumber", panNumber)
            form.append("pancard", panFile)
            form.append("crfForm", panFile)
            form.append("isCompanyKyc", true);

            if (checked) {
                formData.append("gst", gstFile)
                formData.append("gstNumber", gstNumber)
            }
            form.append("currentStep", "company");
            form.append("nextStep", "authorizedPersonProfile");
            // form.append("")
            setLoading(true);
            await axiosPostCall("uploadKyc", form);
            setLoading(false)
            current(false)
            next(true)
        } catch (error) {
            setLoading(false);
            logger.error(`API Error -  ${JSON.stringify(error)}`);
        }
    }

    const handleSelect = (key) => {
        switch (key) {
            case 0:
                setChecked(true)
                break
            case 1:
                setChecked(false)
                setGstFile()
                break
            default:
                break;
        }
    }

    return (
        <MainSection>
            <Typography sx={{
                color: 'grey.100',
                fontSize: '32px',
                fontWeight: 600,
                lineHeight: '40px'
            }}>
                Company Profile
            </Typography>

            <KycInput
                inputName="Company name"
                inputId="companyName"
                inputPlaceholder="Enter company name"
                value={companyName}
                setValue={setCompanyName}
                error={nameError}
                setError={setNameError}
            />

            <Box sx={{
                display: 'flex',
                gap: '6px',
                flexDirection: 'column',
                width: '100%'
            }}>
                <Typography sx={{ fontSize: '14px', fontWeight: 500, color: "grey.300" }}>You have GST number?</Typography>

                <FormGroup sx={{
                    flexDirection: 'row',
                    gap: '60px'
                }}>
                    <FormControlLabel
                        control={<Checkbox
                            checked={checked}
                            onChange={() => handleSelect(0)}
                        />}
                        label="Yes"
                        sx={{
                            color: "grey.300",
                            fontSize: '14px',
                            fontWeight: 500,
                        }}
                    />

                    <FormControlLabel
                        control={<Checkbox
                            checked={!checked}
                            onChange={() => handleSelect(1)}
                        />}
                        label="No"
                        sx={{
                            color: "grey.300",
                            fontSize: '14px',
                            fontWeight: 500,
                        }}
                    />

                </FormGroup>

            </Box>


            {checked && <FormRowSection>

                <KycInput inputName="GST number" inputId="companyGstNumber" inputPlaceholder="Enter GST number" value={gstNumber} setValue={setGstNumber} error={gstError} setError={setGstError} />

                <KycFileInput InputName="Upload GST certificate" inputId="companyGstNumber" inputRef={gstRef} setFile={setGstFile} />

            </FormRowSection>}

            <FormRowSection>

                <KycInput inputName="PAN number" inputId="CompanyPanNumber" inputPlaceholder="Enter PAN number" value={panNumber} setValue={setPanNumber} error={panError} setError={setPanError} />

                <KycFileInput InputName="Upload PAN card" inputId="companyPanNumber" inputRef={panRef} setFile={setPanFile} />

            </FormRowSection>

            <ShowNote />

            <LoadingButton
                loading={loading}
                variant='contained'
                endIcon={<ArrowForwardIcon />}
                sx={{
                    "&.Mui-disabled": InActiveNextBtn,
                    boxShadow: 'none'
                }}
                onClick={handleSubmit}
                disabled={!panFile || !companyName || !panNumber || (checked && (!gstFile || !gstNumber))}
            >
                Save & Next
            </LoadingButton>
        </MainSection>
    )
}

ComapnyProfile.propTypes = {
    current: PropTypes.func.isRequired,
    next: PropTypes.func.isRequired,
    formData: PropTypes.object.isRequired,
}

export default ComapnyProfile;
