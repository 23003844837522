import { Box, Typography } from "@mui/material";
import ErrorMessage from "../../utils/ErrorMessage.json"

const NetworkError = ({ message }) => {
    return (
        <Box sx={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <Typography
                sx={{
                    color: 'grey.600',
                    fontWeight: 500,
                    fontSize: '14px'
                }} >
                {message || ErrorMessage["networkError"]}
            </Typography>
        </Box>
    )
}

export default NetworkError;