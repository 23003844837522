import { Typography } from '@mui/material'
import { Box } from '@mui/material'
import React from 'react'

const ComingSoon = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh'
            }}
        >

            <Typography
                sx={{
                    color: 'grey.100',
                    fontSize: '18px',
                    fontWeight: 500
                }}
            >
                Stand by for the big reveal – coming soon!
            </Typography>
        </Box>
    )
}

export default ComingSoon