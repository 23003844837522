const currentEnv = () => {
    return process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : "local";
};

const currentProduct = () => {
    return process.env.REACT_APP_PRODUCT_ENV || "SARV";
}
const env = currentEnv();
const product = currentProduct();

const configFiles = await import(`./${product}/config.${env}`)

const fetchConfig = () => {
    return configFiles;
};

const configFile = fetchConfig();

export {
    configFile,
    currentEnv,
    currentProduct,
};
