import React, { useEffect, useState } from "react";

//@material
import { Box, Stepper, Step, StepLabel } from '@mui/material';
import { styled } from '@mui/material/styles';

//components
import ChooseMethod from '../kyc/chooseMethod';
import StartKyc from '../kyc/startKyc';
import ComapnyProfile from '../kyc/companyProfile';
import Certification from '../kyc/certification';
import Address from '../kyc/address';
import PersonalInfo from '../kyc/personalInfo';
import AuthorizedPersonProfile from '../kyc/AuthorizedPersonProfile';
import UploadDocuments from '../kyc/UploadDocuments';
import KycInfo from "../kyc/kycInfo";

import { axiosGetCall } from '../../services/apisCall';
import Logger from "../../lib/Logger"
import { ToastContainer } from 'react-toastify';
import { renderErrorMessage } from '../../lib/toastMessage';
import ErrorMessage from "../../utils/ErrorMessage.json";
const logger = new Logger("kyc")


const MainSection = styled('div')(({ theme }) => ({
    display: 'flex',
    height: `calc(${window.innerHeight}px - 242px)`,
    flexDirection: "column",
    gap: '8px',
    alignItems: 'center',
    overflow: 'hidden',
    overflowY: 'scroll'
}));

const steps = [
    'Select master blaster campaign settings',
    'Create an ad group',
    'Create an ad',
];

const KycTabPanel = () => {

    const [startKyc, setStartKyc] = useState(false)
    const [kycInfo, setKycInfo] = useState(false);
    const [chooseMethod, setChooseMethod] = useState(false)
    const [companyKyc, setCompanyKyc] = useState("")
    const [authorizedPersonProfile, setAuthorizedPersonProfile] = useState(false)
    const [certification, setCertification] = useState(false)
    const [address, setAddress] = useState(false)
    const [uploadDoc, setUploadDoc] = useState(false)
    const [uploadLoading, setUploadLoading] = useState(false)
    const [kycFields, setKycFields] = useState({});
    const [kycFiles, setKycFiles] = useState([]);
    const [addressDocuments, setAddressDocuments] = useState([]);
    const [kycStatus, setKycStatus] = useState("");

    const [formData] = useState(() => {
        const initialFormData = new FormData();
        return initialFormData;
    });

    const handleSubmit = async () => {
        setUploadLoading(true)
        try {
            // const { message } = await axiosPostCall("uploadKyc", formData);
            setAddress(false);
            await fetchKycStatus();
            // renderSuccessMessage(message)
        } catch (error) {
            logger.error("kyc.jsx , handleSubmit", error)
            renderErrorMessage(error.message)
        }
        setUploadLoading(false)
    }

    const currentStageMapper = {
        chooseMethod: setChooseMethod,
        uploadDoc: setUploadDoc,
        companyKyc: setCompanyKyc,
        authorizedPersonProfile: setAuthorizedPersonProfile,
        certification: setCertification,
        address: setAddress,
        startKyc: setStartKyc,
    }

    const allSteps = {
        startKyc: setStartKyc,
        // kycInfo: setKycInfo,
        chooseMethod: setChooseMethod,
        companyKyc: setCompanyKyc,
        authorizedPersonProfile: setAuthorizedPersonProfile,
        certification: setCertification,
        address: setAddress,
        uploadDoc: setUploadDoc,
        individual: setCompanyKyc,
        company: setCompanyKyc,
        finish: setKycInfo,

    }

    const fetchKycStatus = async () => {
        try {
            const { kycFields = {}, kycFiles = [], addressDocuments = [], kycStatus = "", step = "startKyc" } = await axiosGetCall("kycStatus");
            if (kycStatus && step === "finish") {
                setKycInfo(true);
                setStartKyc(false);
                setKycFields(kycFields);
                setKycFiles(kycFiles);
                setKycStatus(kycStatus);
                setAddressDocuments(addressDocuments);
                return;
            }
            else if(allSteps?.[step]) {
                if(step === "individual" || step === "company") {
                    allSteps[step](step);
                } else {
                    currentStageMapper[step](true);
                }
                return;
            }
            setStartKyc(true);
        }
        catch (error) {
            logger.error("kycTabPanel [fetchKycStatus] catchError", error)
            renderErrorMessage(ErrorMessage?.[4000])
        }
    }

    useEffect(() => {
        fetchKycStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <ToastContainer />

            <MainSection>

                <Box sx={{
                    display: 'flex',
                    // backgroundImage: 'url(./assets/images/bg/gradientBg.svg)',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    gap: '40px',
                    width: '100%',
                    m: '14px',
                    borderRadius: '6px'
                }}>
                    {false && (
                        <Box sx={{ width: '90%' }}>
                            <Stepper activeStep={1} alternativeLabel>
                                {steps.map((label) => (
                                    <Step key={label}>
                                        <StepLabel >{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </Box>)
                    }

                    {kycInfo && <KycInfo
                        kycFields={kycFields}
                        setKycFields={setKycFields}
                        kycFiles={kycFiles}
                        setKycFiles={setKycFiles}
                        addressDocuments={addressDocuments}
                        kycStatus= {kycStatus}
                        setAddressDocuments={setAddressDocuments} />
                    }

                    {startKyc && <StartKyc current={setStartKyc} next={setChooseMethod} />}

                    {chooseMethod && <ChooseMethod current={setChooseMethod} next={setCompanyKyc} formData={formData} />}

                    {companyKyc === "company" && <ComapnyProfile current={setCompanyKyc} next={setAuthorizedPersonProfile} formData={formData} />}

                    {authorizedPersonProfile && <AuthorizedPersonProfile current={setAuthorizedPersonProfile} next={setCertification} formData={formData} />}

                    {certification && <Certification current={setCertification} next={setAddress} formData={formData} />}

                    {address && <Address current={setAddress} next={handleSubmit} formData={formData} loadingBtn={uploadLoading} />}

                    {companyKyc === "individual" && <PersonalInfo current={setCompanyKyc} next={setUploadDoc} formData={formData} />}

                    {uploadDoc && <UploadDocuments current={setUploadDoc} next={setAddress} formData={formData} />}

                </Box>
            </MainSection>
        </>

    )
}

export default KycTabPanel;