import React from 'react';
import { Typography, IconButton, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
// import KycSvg from '../../assets/svgs/kycSvg';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import NotificationsIcon from '@mui/icons-material/Notifications';
import {useNavigate} from "react-router-dom"

const CardsMainSection = styled('div')(({ theme }) => ({
    height: '100%',
    padding: '18px 14px',
    width: 'calc(100% / 4 - 7px)',
    borderRadius: "6px",
    border: '1px solid',
    backgroundColor: theme.palette.card.main,
    borderColor: theme.palette.grey[1000],
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
        width: '100%',
    },
}));

const Heading = styled(Typography)(({ theme }) => ({
    color: theme.palette.grey[400],
    fontSize: '18px',
    fontWeight: 400,
}))

const CardLeftSection = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '6px',
    // width: 'calc(100% / 1.6)'
    width: '100%'
}));

/* const CardRightSection = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: 'calc(100% - 100% / 1.6)'
})); */

const CustomIcon = styled(IconButton)(({ theme }) => ({
    backgroundColor: theme.palette.grey[800],
    color: "#F0F0F0",
    height: '18px',
    width: '18px',
    '&:hover': {
        backgroundColor: theme.palette.grey[800],
        color: "#F0F0F0",
    }
}));

const Title = styled(Typography)(({ theme }) => ({
    color: theme.palette.grey[200],
    fontSize: '20px',
    fontWeight: 400
}));

const Count = styled(Typography)(({ theme }) => ({
    color: theme.palette.grey[700],
    fontSize: '14px',
    fontWeight: 500
}));

const NotificationSection = ({notificationCount, message}) => {
    const navigate = useNavigate();

    return (
        <CardsMainSection>
            < CardLeftSection>
                <Heading>
                    Notification
                </Heading>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '10px'
                    }}
                >
                    <IconButton sx={{
                        backgroundColor: 'warning.light',
                        color: 'grey.0',
                        borderRadius: '8px',
                        width: '39px',
                        height: '36px',
                        "&:hover": {
                            backgroundColor: 'warning.light',
                            color: 'grey.0',
                        }
                    }}>
                        <NotificationsIcon />
                    </IconButton>

                    <Title>
                        {`${message} `} 
                        {Boolean(notificationCount) && <CustomIcon
                        onClick={(e)=>navigate("/settings", { state: 1 })}
                        >
                            <ArrowForwardIcon sx={{ width: '16px' }} />
                        </CustomIcon>}
                    </Title>
                </Box>


                <Count>
                    {Boolean(notificationCount) && `1/${notificationCount}`}
                </Count>
            </CardLeftSection>

            {/*  <CardRightSection>
                <KycSvg />
            </CardRightSection>
 */}
        </CardsMainSection>
    )
}

export default NotificationSection;
