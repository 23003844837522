import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';

import { Box, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';

import Iconify from '../iconify';
import ImageBox from '../imageBox';
import { useEffect } from 'react';

// ----------------------------------------------------------------------

export default function UploadBox({ placeholder, fileType = "csv", handleFileChange, name, error, disabled, acceptedMimes, imageUrl, onRemove, sx, ...other }) {
  const onDrop = (acceptedFiles) => {
      handleFileChange(acceptedFiles, name);
    };
  const { acceptedFiles, getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
    disabled : imageUrl? true: false,
    onDrop,
    ...other,
    maxFiles: 1,
    accept: acceptedMimes,
  });

  const hasError = isDragReject || error;

  useEffect(() => {
    const fetchImage = async () => {
      if (imageUrl) {
        const response = await fetch(imageUrl);
        const blob = await response.blob();
        const file = new File([blob], 'image.jpg', { type: blob.type });
        onDrop([file]);
      }
    };

    fetchImage();

    // eslint-disable-next-line
  }, []);


  return (
    <Box
      {...getRootProps()}
      sx={{
        m: 0.5,
        width: 64,
        height: 64,
        flexShrink: 0,
        display: 'flex',
        borderRadius: 1,
        cursor: 'pointer',
        alignItems: 'center',
        color: 'text.disabled',
        justifyContent: 'center',
        bgcolor: (theme) => alpha(theme.palette.grey[500], 0.08),
        border: (theme) => `dashed 1px ${alpha(theme.palette.grey[500], 0.16)}`,
        ...(isDragActive && {
          opacity: 0.72,
        }),
        ...(disabled && {
          opacity: 0.48,
          pointerEvents: 'none',
        }),
        ...(hasError && {
          color: 'error.main',
          borderColor: 'error.main',
          bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
        }),
        '&:hover': {
          opacity: 0.72,
        },
        ...sx,
      }}
    >
      <input {...getInputProps()} />

      {placeholder || (
        ((!acceptedFiles[0] || fileType === "csv") && !imageUrl ) && <Iconify
          icon="eva:cloud-upload-fill"
          style={{ color: (theme) => alpha(theme.palette.success.main, 0.08) }}
          width={28}
        />
      )}
      {fileType === "csv"  && <Typography>{acceptedFiles[0] ? acceptedFiles[0].name : "No File Choosen"}</Typography>} 

      {fileType !== "csv" && (acceptedFiles[0] || imageUrl) && <ImageBox imageSrc={imageUrl || acceptedFiles[0].preview} onRemove={onRemove}/>}
    </Box>
  );
}

UploadBox.propTypes = {
  disabled: PropTypes.object,
  error: PropTypes.bool,
  placeholder: PropTypes.object,
  sx: PropTypes.object,
};
