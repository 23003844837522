import React, { useRef } from 'react'
import KycFileInput from '../../components/Dashboard/KycInput/KycFileInput';
import KycInput from '../../components/Dashboard/KycInput/KycInput';
import CustomModal from '../../components/modal/customModal';

import { styled } from '@mui/material/styles';
import { Button, Box } from '@mui/material';

const MainSection = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    paddingTop: '20px',
    paddingBottom: '28px',
}));

const UpdateKycModal = ({ titleName, inputName, open = true, isInput = true, isFileInput = true, handleClose, handleFile, field, handleField, handleFileChange }) => {
    const ref = useRef();
    const INPUT_NAME_MAPPER = {
        aadhar: "Aadhar card",
        pancard: "Pancard",
        incorporationCertificate: "Incorporation Certificate",
        boardResolution: "Board Resolution",
        signature: "Signature",
        authPancard: "Auth Pancard",
        crfForm: "CRF Form",
        electricityBill: "Electricity Bill",
        noc: "NOC", 
        gst: "GST",
        rentAgreement: "Rent Agreement"
    }

    const title = `Update ${INPUT_NAME_MAPPER[titleName] || titleName} details`;

    const description = (
        <MainSection>
            {isInput && <KycInput
                type='text'
                inputName={inputName || "Address"}
                inputId="aadharCardNumber"
                inputPlaceholder={`Enter ${inputName || "address"}`}
                value={field}
                setValue={handleField}
                error={false}
                setError={() => ""}
            />
            }

            {isFileInput && <KycFileInput
                InputName={`Upload ${INPUT_NAME_MAPPER[titleName]}`}
                inputId="authorizedPersonAadharCard"
                inputRef={ref}
                setFile={handleFile}
            />
            }
        </MainSection>
    )

    const action = (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center'
            }}>

            <Button
                variant='contained'
                size='small'
                sx={{ boxShadow: 'none' }}
                onClick={handleFileChange}
            >
                Update Details
            </Button>
        </Box>

    )

    return (
        <CustomModal
            open={open}
            title={title}
            description={description}
            close={() => handleClose()}
            action={action}
        />
    )
}

export default UpdateKycModal