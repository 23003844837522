import { Icon } from '@iconify/react'
import LoadingButton from '@mui/lab/LoadingButton'
import { Card, CardContent, Typography, Box, useTheme, styled } from '@mui/material'
import { useState } from 'react';
import BuyNow from './buyNow';
import { useNavigate } from 'react-router-dom';
import './planStyle.css';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { workspacePricing } from '../../config/commonConfig';
import { getItem } from '../../lib/cookies';
import Modal from '@mui/material/Modal';
import Contacts from '../../pages/contactUs';
import { setBulkLocalStorageItem, setLocalStorageItem } from '../../lib/localStorage';

const Heading = styled(Typography)(() => ({
    fontSize: '34px',
    fontWeight: 600,
    width: 'auto'
}));

const ListPublicPlans = () => {
    const theme = useTheme();
    const [buy, setBuy] = useState(false)
    const [noOfUser, setNoOfUser] = useState(0);
    const [pricePerHead, setPricePerHead] = useState(1);
    const [userPlan, setUserPlan] = useState("pro");
    const [modalControl, setModalControl] = useState(false);
    const navigate = useNavigate();

    const handleProceedToBuy = (e, user, price, plan) => {
        if (!getItem("sessionToken")) {
            navigate("/register", { state: { origin: "plan" } })
            return;
        }
        setLocalStorageItem("planDetails", { user, price, plan })
        navigate("/checkout", { state: { user, price, plan } })
        // setBuy(true);
        // setNoOfUser(user);
        // setPricePerHead(price);
        // setUserPlan(plan)
    }

    const FeaturesList = ({ name }) => (
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '8px',
            alignItems: 'center',
            // justifyContent: 'center',
            mt: '10px'
        }}>
            <Icon icon={"gravity-ui:check"} color={theme.palette.primary.main} />

            <Typography sx={{ fontSize: 14, fontWeight: 400 }} color="text.secondary">
                {name}
            </Typography>
        </Box>
    );

    const handleModalClose = () => {
        setModalControl(false);
    }

    return (
        <Card sx={{
            width: '90%',
            display: 'flex',
            flexDirection: 'row',
            gap: '10px',
            justifyContent: 'center',
            mt: '20px'
        }}>
            <Modal open={modalControl} onClose={handleModalClose}>
                <Contacts />
            </Modal>
            <CardContent
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                    justifyContent: 'center',
                    mb: '20px'
                }}>
                    <Heading>
                        Plans &amp; Pricing
                    </Heading>
                </Box>


                <Box sx={{
                    display: 'flex',
                    flexDirection: {
                        xs: 'column',
                        sm: 'column',
                        md: 'column',
                        lg: 'row',
                        xl: 'row',
                    },
                    gap: '10px',
                }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: {
                            xs: 'column',
                            sm: 'column',
                            md: 'row',
                            lg: 'row',
                            xl: 'row',
                        },
                        gap: '10px',
                    }}>
                        <Card sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '15px',
                            maxWidth: '300px',
                            padding: '20px 10px 20px 10px'
                        }}>
                            <CardContent>
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '6px',
                                    alignItems: 'center'
                                }}>
                                    <Typography sx={{ fontSize: 22, fontWeight: 500 }} color="text.primary" gutterBottom>
                                        Basic
                                    </Typography>
                                    <Typography sx={{ fontSize: 14, fontWeight: 400 }} color="text.secondary">
                                        1 - 5 users
                                    </Typography>
                                </Box>

                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    // gap: '6px',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    mt: '10px'
                                }}>
                                    <Typography sx={{ fontSize: 26, fontWeight: 500 }} color="primary.main">
                                        Free
                                    </Typography>
                                    <Typography sx={{ fontSize: 14, fontWeight: 400 }} color="text.secondary">
                                        <del> &#8377; 40.00* </del>
                                    </Typography>
                                </Box>
                                <Box sx={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    pt: '20px',
                                    pb: '20px'
                                }}>
                                    <LoadingButton
                                        variant='outlined'
                                        // color=''
                                        disabled
                                    >
                                        Activated
                                    </LoadingButton>
                                </Box>

                                <FeaturesList name={"Unlimited Meetings"} />
                                <FeaturesList name={"Unlimited Webinars"} />
                                <FeaturesList name={"Unlimited Call Features"} />
                                <FeaturesList name={"Unlimited Chats"} />
                                <FeaturesList name={"Unlimited Advanced Features"} />
                                <FeaturesList name={"24/7 Support"} />


                            </CardContent>
                        </Card>

                        <Card sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '15px',
                            maxWidth: '300px',
                            padding: '20px 10px 20px 10px',
                            // border: '0.5px dashed',
                            // borderColor: 'success.main'
                        }}>
                            {/* <div class="ribbon-2">
                                <Typography sx={{ fontSize: '14px', fontWeight: 400, color: 'background.default', mr: '10px' }}>
                                    Active
                                </Typography>
                            </div> */}
                            <div class="ribbon-1 right">
                                <Typography sx={{ fontSize: '12px', fontWeight: 400, color: 'background.default', mr: '10px' }}>
                                    Active (7 Users)
                                </Typography>
                            </div>
                            <CardContent>

                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '6px',
                                    alignItems: 'center'
                                }}>
                                    <Typography sx={{ fontSize: 22, fontWeight: 500 }} color="text.primary" gutterBottom>
                                        Pro
                                    </Typography>
                                    <Typography sx={{ fontSize: 14, fontWeight: 400 }} color="text.secondary">
                                        1 - 9 users
                                    </Typography>
                                </Box>
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    // gap: '6px',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    mt: '10px'
                                }}>
                                    <Typography sx={{ fontSize: 26, fontWeight: 500 }} color="primary.main">
                                        &#8377; 60.00
                                    </Typography>

                                    <Typography sx={{ fontSize: 14, fontWeight: 400 }} color="text.secondary">
                                        <del> &#8377; 80.00* </del>
                                    </Typography>

                                    <Typography sx={{ fontSize: 14, fontWeight: 400 }} color="text.secondary">
                                        per users/month
                                    </Typography>
                                </Box>

                                <Box sx={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    pt: '20px',
                                    pb: '20px'
                                }}>
                                    {/* <LoadingButton
                                        variant='contained'
                                        sx={{ boxShadow: 'none' }}
                                    >
                                        Buy Now
                                    </LoadingButton> */}
                                    <LoadingButton
                                        variant='contained'
                                        // color='success'
                                        onClick={(e) => handleProceedToBuy(e, 1, workspacePricing?.pro, "pro")}
                                        sx={{ boxShadow: 'none' }}
                                    >
                                        Add More
                                    </LoadingButton>
                                </Box>
                                <FeaturesList name={"Unlimited Meetings"} />
                                <FeaturesList name={"Unlimited Webinars"} />
                                <FeaturesList name={"Unlimited Call Features"} />
                                <FeaturesList name={"Unlimited Chats"} />
                                <FeaturesList name={"Unlimited Advanced Features"} />
                                <FeaturesList name={"24/7 Support"} />
                            </CardContent>
                        </Card>

                        <Card sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '15px',
                            maxWidth: '300px',
                            padding: '20px 10px 20px 10px',
                            border: '0.5px dashed',
                            borderColor: 'primary.main'
                        }}>
                            <CardContent>
                                <Box sx={{
                                    display: 'flex',
                                    padding: '2px',
                                    // mt: '-30px',
                                    justifyContent: 'center',
                                    // mb: '18px'
                                    backgroundColor: "primary.lighter",
                                    position: 'relative',
                                    top: '-44px',
                                    ml: '-39px',
                                    mr: '-39px',
                                    mb: '-20px'
                                }}>
                                    <Typography sx={{ fontSize: 12, fontWeight: 400 }} color="primary.main">
                                        MOST POPULAR
                                    </Typography>
                                </Box>
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '6px',
                                    alignItems: 'center'
                                }}>
                                    <Typography sx={{ fontSize: 22, fontWeight: 500 }} color="text.primary" gutterBottom>
                                        Business
                                    </Typography>
                                    <Typography sx={{ fontSize: 14, fontWeight: 400 }} color="text.secondary">
                                        10 - 200 users
                                    </Typography>
                                </Box>

                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    // gap: '6px',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    mt: '10px'
                                }}>
                                    <Typography sx={{ fontSize: 26, fontWeight: 500 }} color="primary.main">
                                        &#8377; 50.00
                                    </Typography>

                                    <Typography sx={{ fontSize: 14, fontWeight: 400 }} color="text.secondary">
                                        <del> &#8377; 70.00* </del>
                                    </Typography>

                                    <Typography sx={{ fontSize: 14, fontWeight: 400 }} color="text.secondary">
                                        per users/month

                                    </Typography>
                                </Box>

                                <Box sx={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    pt: '20px',
                                    pb: '20px'
                                }}>
                                    <LoadingButton
                                        variant='contained'
                                        sx={{ boxShadow: 'none' }}
                                        onClick={(e) => handleProceedToBuy(e, 10, workspacePricing?.business, "business")}
                                    >
                                        Buy Now
                                    </LoadingButton>
                                </Box>

                                <FeaturesList name={"Unlimited Meetings"} />
                                <FeaturesList name={"Unlimited Webinars"} />
                                <FeaturesList name={"Unlimited Call Features"} />
                                <FeaturesList name={"Unlimited Chats"} />
                                <FeaturesList name={"Unlimited Advanced Features"} />
                                <FeaturesList name={"24/7 Support"} />


                            </CardContent>
                        </Card>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: {
                            xs: 'column',
                            sm: 'column',
                            md: 'row',
                            lg: 'row',
                            xl: 'row',
                        },
                        gap: '10px',
                    }}>

                        <Card sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '15px',
                            maxWidth: '300px',
                            padding: '20px 10px 20px 10px'
                        }}>
                            <CardContent>
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '6px',
                                    alignItems: 'center'
                                }}>
                                    <Typography sx={{ fontSize: 22, fontWeight: 500 }} color="text.primary" gutterBottom>
                                        Business Pro
                                    </Typography>
                                    <Typography sx={{ fontSize: 14, fontWeight: 400 }} color="text.secondary">
                                        201 - 300 users
                                    </Typography>
                                </Box>

                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    // gap: '6px',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    mt: '10px'
                                }}>
                                    <Typography sx={{ fontSize: 26, fontWeight: 500 }} color="primary.main">
                                        &#8377; 47.00
                                    </Typography>

                                    <Typography sx={{ fontSize: 14, fontWeight: 400 }} color="text.secondary">
                                        <del> &#8377; 65.00* </del>
                                    </Typography>

                                    <Typography sx={{ fontSize: 14, fontWeight: 400 }} color="text.secondary">
                                        per users/month
                                    </Typography>
                                </Box>

                                <Box sx={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    pt: '20px',
                                    pb: '20px',
                                }}>
                                    <LoadingButton
                                        variant='contained'
                                        sx={{ boxShadow: 'none' }}
                                        onClick={(e) => handleProceedToBuy(e, 201, workspacePricing?.businessPro, "businessPro")}
                                    >
                                        Buy Now
                                    </LoadingButton>
                                </Box>

                                <FeaturesList name={"Unlimited Meetings"} />
                                <FeaturesList name={"Unlimited Webinars"} />
                                <FeaturesList name={"Unlimited Call Features"} />
                                <FeaturesList name={"Unlimited Chats"} />
                                <FeaturesList name={"Unlimited Advanced Features"} />
                                <FeaturesList name={"24/7 Support"} />


                            </CardContent>
                        </Card>

                        <Card sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '15px',
                            maxWidth: '300px',
                            padding: '20px 10px 20px 10px'
                        }}>
                            <CardContent>
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '6px',
                                    alignItems: 'center'
                                }}>
                                    <Typography sx={{ fontSize: 22, fontWeight: 500 }} color="text.primary" gutterBottom>
                                        Enterprise
                                    </Typography>
                                    <Typography sx={{ fontSize: 14, fontWeight: 400 }} color="text.secondary">
                                        &gt; 300 users
                                    </Typography>
                                </Box>

                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    // gap: '6px',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    mt: '30px'
                                }}>
                                    {/* <Typography sx={{ fontSize: 26, fontWeight: 500 }} color="primary.main">
                                    &#8377; 50.00
                                </Typography>

                                <Typography sx={{ fontSize: 14, fontWeight: 400 }} color="text.secondary">
                                    <del> &#8377; 80.00* </del>
                                </Typography> */}

                                    <Typography sx={{ fontSize: 14, fontWeight: 400 }} color="text.secondary">
                                        Contact us for pricing information and more
                                    </Typography>
                                </Box>

                                <Box sx={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    pt: '20px',
                                    pb: '20px'
                                }}>
                                    <LoadingButton
                                        variant='outlined'
                                        sx={{ boxShadow: 'none' }}
                                        onClick={() => setModalControl(true)}
                                    >
                                        Contact Sales
                                    </LoadingButton>
                                </Box>

                                <FeaturesList name={"Unlimited Meetings"} />
                                <FeaturesList name={"Unlimited Webinars"} />
                                <FeaturesList name={"Unlimited Call Features"} />
                                <FeaturesList name={"Unlimited Chats"} />
                                <FeaturesList name={"Unlimited Advanced Features"} />
                                <FeaturesList name={"24/7 Support"} />
                            </CardContent>
                        </Card>
                    </Box>
                </Box>

            </CardContent>

            <BuyNow
                open={buy}
                users={noOfUser}
                handleUser={setNoOfUser}
                setOpen={setBuy}
                price={pricePerHead}
                plan={userPlan}
            />
        </Card>
    )
}

export default ListPublicPlans