import React, { useState } from 'react';

// @mui
import { styled } from '@mui/material/styles';
import { Typography, Button, Box } from '@mui/material';

import UpcomingMeetListSvg from '../../assets/svgs/upcomingMeetListSvg';

// @mui icons
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import NetworkError from '../../components/Error';
import commonMessage from '../../utils/commonMessage.json'
import moment from 'moment';
import { CustomSkeleton } from '../skeleton/skeletonLoader';
import { handleRedirect } from '../../utils/common';
import LoadingButton from '@mui/lab/LoadingButton';
import Logger from '../../lib/Logger';
import { useTheme } from '@mui/material';
const logger = new Logger("upcommingMeetSection")

const CardsMainSection = styled('div')(({ theme }) => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    padding: '16px 19px',
    width: 'calc(100% / 2)',
    borderRadius: "6px",
    border: '1px solid',
    backgroundColor: theme.palette.card.main,
    borderColor: theme.palette.grey[1000],
    [theme.breakpoints.down('md')]: {
        width: '100%',
    },
    [theme.breakpoints.between('md', 'lg')]: {
        width: 'calc(100% / 2.5)',
    },
}));

const CardsLeftSection = styled('div')(({ theme }) => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'calc(100% / 1.3)',
    gap: '10px',
    [theme.breakpoints.down('sm')]: {
        width: '100%',
    },
}));

const CardsInnerLeftSection = styled('div')(({ theme }) => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100% / 2)',
    gap: '10px',
    [theme.breakpoints.down('sm')]: {
        width: 'calc(100% / 1.3)',
    },
}));
const CardsInnerRighttSection = styled('div')(({ theme }) => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: 'calc(100% / 2)',
    gap: '10px',
    [theme.breakpoints.down('sm')]: {
        width: 'calc(100% - 100% / 1.3)',
        flexDirection: 'column'
    },
}));

const CardsRightSection = styled('div')(({ theme }) => ({
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'calc(100% - 100% / 1.3)',
    [theme.breakpoints.down('lg')]: {
        display: 'none'
    },
}));

const Heading = styled(Typography)(({ theme }) => ({
    color: theme.palette.grey[400],
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '18px'
}))

const SubHeading = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '18px'
}))

const InviteSection = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
}))

const CustomBuuton = styled(LoadingButton)(() => ({
    boxShadow: 'none',
    borderRadius: '23px',
    height: '25px',
    // width: '79%',
    fontSize: '12px',
    fontWeight: 500
}));

const ListItemStartDate = styled(Button)(({ theme }) => ({
    color: theme.palette.grey[500],
    // height: '19px',
    // pl: '0px',
    fontSize: '12px',
    fontWeight: 500,
    ":hover": {
        backgroundColor: 'transparent'
    }
}));

const ViewAll = styled(Typography)(({ theme }) => ({
    color: theme.palette.grey[400],
    fontSize: '14px',
    fontWeight: 400,
}));

const formatDate = (originalDate) => {
    const date = moment(originalDate);
    return date.format('MMMD')
}


const UpcomingMeetSection = ({ title, startDate, endDate, loader, roomId }) => {
    const [loadingBtn, setLoadingBtn] = useState(false);
    const theme = useTheme();

    const handleJoin = async () => {
        try {
            setLoadingBtn(true);
            await handleRedirect("meet", "", {}, true, theme.palette.mode, "join", roomId);
            setLoadingBtn(false);
        }
        catch (error) {
            setLoadingBtn(false);
            logger.error("upcomingMeetSection fn: [handleJoin] api:[shareSession] post catchError", error);
        }
    }

    const viewAllMeetings = async () => {
        try {
            await handleRedirect("meet", "", {}, true, theme.palette.mode, "list", roomId);
        }
        catch (error) {
            logger.error("upcomingMeetSection fn: [viewAllMeetings] api:[shareSession] post catchError", error);
        }
    }

    return (
        <CardsMainSection >
            <CardsLeftSection>
                <CardsInnerLeftSection>

                    <Heading>
                        Upcoming Meeting
                    </Heading>

                    {title &&
                        <>
                            <SubHeading>
                                {title.length > 25 ? title.slice(0, 25) + "..." : title}
                            </SubHeading>

                            <Box sx={{ ml: -0.8, mt: -0.6 }}>
                                <ListItemStartDate
                                    disableRipple
                                    startIcon={<CalendarMonthOutlinedIcon />}
                                >
                                    {formatDate(startDate)}
                                    {/* {new Date(startDate).getDate()} {monthsStr[new Date(startDate).getMonth()]} */}
                                </ListItemStartDate>

                                <ListItemStartDate
                                    disableRipple
                                    startIcon={<AccessTimeOutlinedIcon />}
                                >
                                    {moment(startDate).format("hh:mma").toUpperCase()}
                                </ListItemStartDate>
                            </Box>

                            <ViewAll onClick={() => viewAllMeetings()}>
                                View all meeting
                            </ViewAll>
                            <InviteSection>

                            </InviteSection>
                        </>}

                    {loader ?
                        <>
                            <SubHeading>
                                <CustomSkeleton variant={"text"} width={"50%"} height={"24px"} />
                            </SubHeading>

                            <Box sx={{ ml: -0.8, mt: -0.6 }}>
                                <ListItemStartDate>
                                    <CustomSkeleton variant={"text"} width={"50%"} height={"10%"} />
                                </ListItemStartDate>

                                <ListItemStartDate>
                                    <CustomSkeleton variant={"text"} width={"50%"} height={"10%"} />
                                </ListItemStartDate>

                                <ViewAll sx={{ ml: 0.8 }}>
                                    <CustomSkeleton variant={"text"} width={"50%"} height={"24px"} />
                                </ViewAll>
                            </Box>
                        </> : !title && <NetworkError message={commonMessage?.noUpocmingMeeting} />}

                </CardsInnerLeftSection>

                <CardsInnerRighttSection>
                    {title ?
                        <>
                            {/* <CustomBuuton
                                variant='contained'
                                sx={{
                                    backgroundColor: 'transparent',
                                    color: 'grey.0',
                                    border: '1px solid',
                                    borderColor: 'grey.0',
                                    '&:hover': {
                                        backgroundColor: 'transparent',
                                        color: 'grey.0',
                                    }
                                }}
                            >
                                Edit
                            </CustomBuuton> */}

                            <CustomBuuton
                                variant='contained'
                                onClick={() => handleJoin()}
                                loading={loadingBtn}
                                sx={{
                                    backgroundColor: 'primary.main',
                                    '&:hover': {
                                        backgroundColor: 'primary.main',
                                    }
                                }}
                            >
                                Start
                            </CustomBuuton>
                        </> : ""}

                </CardsInnerRighttSection>
            </CardsLeftSection>

            <CardsRightSection>
                <UpcomingMeetListSvg />

            </CardsRightSection>
        </CardsMainSection>
    )
}

export default UpcomingMeetSection

