const validatePanNumber = (panNumber) => {
  return /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(panNumber);
};

const validateAadharNumber = (aadharNumber) => {
  return /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/.test(aadharNumber);
};

const validateGstNumber = (gstNumber) => {
  return /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/.test(
    gstNumber
  );
};

const validateUploadFile=(file)=>{
    const types=["application/pdf","image/png","image/jpeg"]
    return types.includes(file.type) && file.size<=2000000 
}

const validatePincode=(pincode)=>{
  return /^[1-9][0-9]{5}$/.test(pincode)
}

export { validateAadharNumber, validatePanNumber, validateGstNumber,validateUploadFile, validatePincode};
