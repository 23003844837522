import {useCallback, useEffect, useState} from "react";

const useRecaptchaV3 = (siteKey) => {
    const [isCaptchaReady, setIsCaptchaReady] = useState(false);

    useEffect(()=> {
        if(window.grecaptcha) {
            setIsCaptchaReady(true);
        } else {
            const script = document.createElement("script");
            script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`;
            script.async = true;
            document.head.appendChild(script);

            script.onload = ()=> {setIsCaptchaReady(true)};
        }
    }, [siteKey])

    // eslint-disable-next-line react-hooks/exhaustive-deps 
    const executeRecaptcha = useCallback( async (action) => {
        if(isCaptchaReady && window.grecaptcha) {
            return await window.grecaptcha.execute(siteKey, {action})
        }
    }) 
    return executeRecaptcha;
}

export default useRecaptchaV3;
