import { Box } from '@mui/material';
import React from 'react';
import { Helmet } from 'react-helmet';
import ChatSection from '../sections/chat';

const Chat = () => {
    return (
        <>
            <Helmet>
                <style>{'body { background-color: transparent; }'}</style>
            </Helmet>
            <Box sx={{ backgroundColor: 'transparent' }}>
                    <ChatSection />
            </Box>
        </>
    )
}

export default Chat;
