import React, { useEffect, useState } from 'react';
import { Typography, FormControlLabel, Checkbox, Switch, styled, Stack, Button, TextField, IconButton, Tooltip, Chip, InputAdornment } from '@mui/material';
import CustomModal from '../../components/modal/customModal';
import { Box } from '@mui/system';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { checkProductAccess } from '../../utils/common';
// import { enableCache } from '@iconify/react/dist/iconify.js';

const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));


const AdvancedSettingsMenu = ({ open, handleClose, handleAdvanceSetting }) => {
    const [enabel2Fa, setEnable2fa] = useState([false, false, false, false, true, true, false]);
    const [blockedOrigin, setBlockedOrigin] = useState("");
    const [restrictedOrigin, setRestrictedOrigin] = useState("");
    const [restrictedChips, setRestrictedChips] = useState([]);
    const [blockedChips, setBlockedChips] = useState([]);
    const [productsAccess] = useState(checkProductAccess())

    const handleCheckBox = (e, index) => {
        setEnable2fa((prev) => {
            const temp = [...prev];
            temp[index] = e.target.checked;
            return temp;
        });
    }


    const handleInputChange = (event, setOrigin) => {
        setOrigin(event.target.value);
    };

    const handleKeyPress = (event, chips, setChips, origin, setOrigin) => {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            if (origin.trim() !== '') {
                setChips([...chips, origin.trim()]);
                setOrigin('');
            }
        }
    };

    const handleDelete = (chipToDelete, chips, setChips) => () => {
        setChips((chips) => chips.filter((chip) => chip !== chipToDelete));
    };

    const handleSubmit = () => {
        const data = {
            twoFa: { self: enabel2Fa[0], downline: enabel2Fa[1], hierarchy: enabel2Fa[2] },
            mothlyVerification: enabel2Fa[3],
            signUp: enabel2Fa[4],
            googleAuth: enabel2Fa[5],
            meetMedia: enabel2Fa[6],
            restrictedOrigin: restrictedChips,
            blockedOrigin: blockedChips,
        }
        handleAdvanceSetting(data);
        handleClose(false)
    }

    const InputChips = ({ chips, setChips }) => (
        <Box sx={{ display: "flex", flexDirection: "row", overFlow: "auto", alignItems: "center" }}>
            {chips.map((chip, index) => (
                <Chip
                    key={index}
                    label={chip}
                    onDelete={handleDelete(chip, chips, setChips)}
                    style={{ margin: '2px' }}
                />
            ))}
        </Box>
    );

    useEffect(() => {
        handleAdvanceSetting((prev) => {
            setEnable2fa([prev?.twoFa?.self, prev?.twoFa?.downline, prev?.twoFa?.hierarchy, prev?.mothlyVerification, prev?.signUp, prev?.googleAuth, prev?.meetMedia])
            setBlockedOrigin(prev?.blockedOrigin)
            setRestrictedOrigin(prev?.restrictedOrigin)
            return prev;
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const description = (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            mt: '20px',
            gap: '8px',
            padding: '15px'
        }}>

            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                // gap: "2px",
                alignItems: 'center'
            }}>
                <Typography sx={{ color: 'grey.100', fontWeight: 500, fontSize: '14px' }}>
                    Enable 2FA for
                </Typography>

                <FormControlLabel
                    value="self"
                    control={<Checkbox size='small' checked={enabel2Fa[0]} onChange={(e) => handleCheckBox(e, 0)} />}
                    label="Self"
                    labelPlacement="end"
                    sx={{ ml: '95px' }}
                />

                <FormControlLabel
                    value="downline"
                    control={<Checkbox size='small' checked={enabel2Fa[1]} onChange={(e) => handleCheckBox(e, 1)} />}
                    label="Downline"
                    labelPlacement="end"
                />

                <FormControlLabel
                    value="hierarchy"
                    control={<Checkbox size='small' checked={enabel2Fa[2]} onChange={(e) => handleCheckBox(e, 2)} />}
                    label="Hierarchy"
                    labelPlacement="end"
                />

            </Box>

            <Stack direction="row" spacing={3} alignItems="center" >
                <Typography variant='body1' sx={{ color: 'grey.100', fontWeight: 500, fontSize: '14px' }}> Monthly Verification </Typography>
                <AntSwitch checked={enabel2Fa[3]} onChange={(e) => handleCheckBox(e, 3)} defaultChecked inputProps={{ 'aria-label': 'ant design' }} sx={{ ml: '70px !important' }} />
            </Stack>

            <Stack direction="row" spacing={3} alignItems="center" sx={{ mt: '5px' }}>
                <Typography variant='body1' sx={{ color: 'grey.100', fontWeight: 500, fontSize: '14px' }}>Sign up Option</Typography>
                <AntSwitch checked={enabel2Fa[4]} onChange={(e) => handleCheckBox(e, 4)} defaultChecked inputProps={{ 'aria-label': 'ant design' }} sx={{ ml: '105px !important' }} />
            </Stack>

            <Stack direction="row" spacing={3} alignItems="center" sx={{ mt: '5px' }}>
                <Typography variant='body1' sx={{ color: 'grey.100', fontWeight: 500, fontSize: '14px' }}>Login/Signup with Google</Typography>
                <AntSwitch checked={enabel2Fa[5]} onChange={(e) => handleCheckBox(e, 5)} defaultChecked inputProps={{ 'aria-label': 'ant design' }} sx={{ ml: '35px !important' }} />
            </Stack>

            {productsAccess.includes("Meet") && <Stack direction="row" spacing={5} alignItems="center" sx={{ mt: '5px' }}>
                <Typography variant='body1' sx={{ color: 'grey.100', fontWeight: 500, fontSize: '14px' }}>Meet Media Settings</Typography>
                <AntSwitch checked={enabel2Fa[6]} onChange={(e) => handleCheckBox(e, 6)} defaultChecked inputProps={{ 'aria-label': 'ant design' }} sx={{ ml: '68px !important' }} />
            </Stack>}

            <Stack direction="row" spacing={3} alignItems="center" sx={{ mt: '5px' }}>
                <Typography variant='body1' sx={{ color: 'grey.100', fontWeight: 500, fontSize: '14px' }}>Restricted Origins</Typography>
                {/* <AntSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} sx={{ ml: '85px !important' }} /> */}
                {/* <TextField
                    label="Enter Restricted Origins"
                    sx={{ ml: '85px !important' }}
                    value={restrictedOrigin}
                    onChange={(e) => setRestrictedOrigin(e.target.value)}
                /> */}

                <TextField
                    label="Enter Restricted Origins"
                    sx={{ ml: '85px !important' }}
                    value={restrictedOrigin}
                    onChange={(e) => handleInputChange(e, setRestrictedOrigin)}
                    onKeyDown={(e) => handleKeyPress(e, restrictedChips, setRestrictedChips, restrictedOrigin, setRestrictedOrigin)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Box display="flex" alignItems="center" overflow="hidden">
                                    <InputChips chips={restrictedChips} setChips={setRestrictedChips} />
                                </Box>
                            </InputAdornment>
                        ),
                    }}
                />

                <Tooltip
                    PopperProps={{ style: { zIndex: 9999 } }}
                    componentsProps={{
                        tooltip: {
                            sx: {
                                bgcolor: 'grey.500',
                            },
                        },
                    }}
                    title={"You can restrict login/signup with the origins like only those candidate can login that have the email with the origin like yourdomain.com."}>
                    <IconButton sx={{ ml: '0px !important' }}>
                        <InfoOutlinedIcon />
                    </IconButton>
                </Tooltip>

            </Stack>

            <Stack direction="row" spacing={3} alignItems="center" sx={{ mt: '5px' }}>
                <Typography variant='body1' sx={{ color: 'grey.100', fontWeight: 500, fontSize: '14px' }}>Blocked Origins</Typography>
                {/* <AntSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} sx={{ ml: '99px !important' }}/> */}
                {/* <TextField
                    label="Enter Blocked Origins"
                    sx={{ ml: '99px !important' }}
                    value={blockedOrigin}
                    onChange={(e) => setBlockedOrigin(e.target.value)}
                /> */}

                <TextField
                    label="Enter Blocked Origins"
                    sx={{ ml: '99px !important' }}
                    value={blockedOrigin}
                    onChange={(e) => handleInputChange(e, setBlockedOrigin)}
                    onKeyDown={(e) => handleKeyPress(e, blockedChips, setBlockedChips, blockedOrigin, setBlockedOrigin)}
                    // fullWidth
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Box display="flex" alignItems="center" overflow="hidden">
                                    <InputChips chips={blockedChips} setChips={setBlockedChips} />
                                </Box>
                            </InputAdornment>
                        ),
                    }}
                />

                <Tooltip
                    PopperProps={{ style: { zIndex: 9999 } }}
                    componentsProps={{
                        tooltip: {
                            sx: {
                                bgcolor: 'grey.500',
                            },
                        },
                    }}
                    title={"Add blocked domain if you want to disable login for some domains."}
                >
                    <IconButton sx={{ ml: '0px !important' }}>
                        <InfoOutlinedIcon />
                    </IconButton>
                </Tooltip>
            </Stack>

        </Box>
    )

    const actions = (
        <Box sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            mt: '15px'
        }}>
            <Button
                variant='contained'
                sx={{ boxShadow: 'none' }}
                onClick={(e) => handleSubmit()}
            >
                Save
            </Button>
        </Box>
    )

    return (
        <CustomModal
            open={open}
            close={handleClose}
            title={"Advanced Brand Settings"}
            description={description}
            action={actions}
        />
    )
}

export default AdvancedSettingsMenu