//react
import React, { useState } from 'react';

//@mui
import { Box, Typography, Select, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';

//@mui icons
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

// components
import KycInput from '../../components/Dashboard/KycInput/KycInput';

//toastMessage
import { renderErrorMessage } from '../../lib/toastMessage';

//kyc common validator function
import { validatePincode } from '../../utils/kycCommon';

import ErrorMessage from "../../utils/ErrorMessage.json"
import PropTypes from 'prop-types';
import { axiosPostCall } from '../../services/apisCall';
import Logger from '../../lib/Logger';
import { LoadingButton } from '@mui/lab';
const logger = new Logger("personalInfo");

const MainSection = styled('div')(({ theme }) => ({
    display: 'flex',
    gap: '20px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '50%',
    [theme.breakpoints.down('sm')]: {
        width: '100%',
    },
}));

const PersonalInfo = ({ current, next, formData }) => {

    const [indianStateNames,] = useState([
        'Andhra Pradesh',
        'Arunachal Pradesh',
        'Assam',
        'Bihar',
        'Chhattisgarh',
        'Goa',
        'Gujarat',
        'Haryana',
        'Himachal Pradesh',
        'Jharkhand',
        'Karnataka',
        'Kerala',
        'Madhya Pradesh',
        'Maharashtra',
        'Manipur',
        'Meghalaya',
        'Mizoram',
        'Nagaland',
        'Odisha',
        'Punjab',
        'Rajasthan',
        'Sikkim',
        'Tamil Nadu',
        'Telangana',
        'Tripura',
        'Uttar Pradesh',
        'Uttarakhand',
        'West Bengal',
    ]);

    const [address, setAddress] = useState("")
    const [pincode, setPincode] = useState("")
    const [state, setState] = useState("Rajasthan")
    const [loader, setLoader] = useState(false);

    const [addressError, setAddressError] = useState(false)
    const [pincodeError, setPincodeError] = useState(false)

    const InActiveNextBtn = {
        backgroundColor: '#727374',
        color: "#B8B9BA",
        '&:hover': {
            backgroundColor: '#727374',
            color: "#B8B9BA",
        }
    }

    const handleSubmit = async (e) => {
        try{
        const form = new FormData();
        
        if (!validatePincode(pincode)) {
            setPincodeError(true)
            return renderErrorMessage(ErrorMessage?.[4017])

        }
        form.append("address", address)
        form.append("pincode", pincode)
        form.append("state", state)
        form.append("isCompanyKyc", false);
        form.append("currentStep","individual");
        form.append("nextStep","uploadDoc");
        setLoader(true);
        await axiosPostCall("uploadKyc", form);
        setLoader(false);
        current("")
        next(true)
        } catch(error) {
            setLoader(false);
            logger.error(`API Error -  ${JSON.stringify(error)}`);
        }
    }

    const handleChange = (value) => setState(value);

    return (
        <MainSection >

            <Typography sx={{
                color: 'grey.100',
                fontSize: '32px',
                fontWeight: 600,
                lineHeight: '40px'
            }}>
                Personal Info
            </Typography>

            <KycInput inputName="Address" inputId="address" inputPlaceholder="Enter your address" value={address} setValue={setAddress} error={addressError} setError={setAddressError} />

            <Box sx={{
                display: 'flex',
                gap: '6px',
                flexDirection: 'column',
                width: '100%'
            }}>
                <Typography sx={{ fontSize: '14px', fontWeight: 500, color: "grey.300" }}>State</Typography>

                <Select
                    id="demo-simple-select"
                    placeholder={"Select your state"}
                    value={state}
                    sx={{
                        color: 'grey.300',
                        backgroundColor: 'grey.800',
                        borderRadius: '6px'
                    }}
                    onChange={(e) => handleChange(e.target.value)}
                    MenuProps={{
                        sx: {
                            '& .MuiPaper-root': {
                                color: 'grey.300',
                                backgroundColor: 'card.main',
                            },
                            // color: 'grey.300',
                        }
                    }}
                >
                    {indianStateNames.map((state, key) => {
                        return <MenuItem value={state} key={key}>{state}</MenuItem>
                    })}
                </Select>
            </Box>

            <KycInput inputName="Pincode" inputId="pincode" inputPlaceholder="Enter pin code" value={pincode} setValue={setPincode} error={pincodeError} setError={setPincodeError} />

            <LoadingButton
                variant='contained'
                endIcon={<ArrowForwardIcon />}
                loading={loader}
                disabled={!address || !pincode || !state}
                sx={{
                    "&.Mui-disabled": InActiveNextBtn,
                    boxShadow: 'none'
                }}
                onClick={handleSubmit}
            >
                Save & Next
            </LoadingButton>
        </MainSection>
    )
}

PersonalInfo.propTypes = {
    current: PropTypes.func.isRequired,
    next: PropTypes.func.isRequired,
    formData: PropTypes.object.isRequired,
}

export default PersonalInfo;
