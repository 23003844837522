import React from 'react';
import { Helmet } from 'react-helmet';
import { currentProduct } from '../config';
const product = currentProduct();
const  { privacyPolicy } = await import(`../config/${product}/privacy`)

const Privacy = () => {

    return (
        <>
            <Helmet>
                <title>Privacy Policy</title>
                {/* Include any other meta tags or stylesheets if needed */}
            </Helmet>
            <iframe
                title="Privacy Policy"
                srcDoc={privacyPolicy}
                style={{ width: '100%', height: '100vh', border: 'none' }}
            />
        </>
    );
}

export default Privacy;
