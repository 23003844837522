import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types'
import { Box, CircularProgress, TableCell, TextField, Tooltip, Typography } from '@mui/material';

import { renderSuccessMessage, renderErrorMessage } from '../../../lib/toastMessage';
import Logger from '../../../lib/Logger';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import DeleteIcon from '@mui/icons-material/Delete';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { PaginationTable } from './paginationTable';
import { LoadingButton } from '@mui/lab';
import { axiosVBGetCall } from '../../../services/vbApiCall';

const BasicDatePicker = ({ value, onChange }) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} >
            <DatePicker
                slotProps={{
                    textField: { size: 'small' }, actionBar: {
                        actions: ['today', 'clear'],
                    },
                }}
                sx={{ width: "270px", margin: "8px" }}
                value={value} onChange={(newValue) => onChange(newValue)}

            />
        </LocalizationProvider>
    )
}

BasicDatePicker.propTypes = {
    value: propTypes.number,
    onChange: propTypes.func.isRequired,
}

export const UserPlanTable = ({ planWiseCredits = [], user = {}, forceLoad, loading: tableLoading }) => {

    const [rows, setRows] = useState([])
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [load, setLoad] = useState(false)

    const logger = new Logger("senderId");

    const handleUpdateCredits = async (plan, credit, setLoading, type) => {
        setLoading(true);

        try {
            if (credit === 0) {
                setLoading(false);
                return renderErrorMessage("Credit should be more than 0.")
            }
            const { plan_id: planId } = plan;
            const { userId } = user;

            if (planId && userId && type) {
                const params = { assignTo: userId, planId, credit, type }
                const { message } = await axiosVBGetCall("assignCreditToUser", params);

                forceLoad();
                renderSuccessMessage(message);
            }


        } catch (error) {
            logger.error("userPlanTable.jsx ,handleUpdateCredits ,assignCreditToUser api", error);
            renderErrorMessage(error?.message);
        }

        setLoading(false);

    }

    const handleRemovePlan = async (plan, setLoading) => {

        setLoading(true);
        try {

            const { plan_id: planId } = plan;
            const { userId } = user;

            if (planId && userId) {
                const params = { userId, planId }
                const { message } = await axiosVBGetCall("removeUserPlan", params);
                forceLoad();
                renderSuccessMessage(message);
            }


        } catch (error) {
            logger.error("userPlanTable.jsx ,handleRemovePlan ,removeUserPlan api", error);
            renderErrorMessage(error?.message);
        }

        setLoading(false);
    }

    useEffect(() => {
        if (load) {
            setLoad(false)

        }
    }, [load])

    useEffect(() => {
        // const filteredData = planWiseCredits.filter((item) => item['plan_id'] !== undefined);
        setRows(planWiseCredits);
    }, [planWiseCredits])

    const columns = [
        { id: 'plan_id', label: 'Plan ID', minWidth: 70, align: "center" },
        {
            id: 'allowCountry',
            label: 'Country Code',
            minWidth: 120,
            align: 'center',
        },
        { id: 'TotBal', label: 'Total Balance', minWidth: 125, align: "center" },
        { id: 'CurrBal', label: 'Current Balance', minWidth: 125, align: "center" },
        { id: 'Pulse', label: 'Pulse', minWidth: 125, align: "center" },

        {
            id: 'Validity',
            label: 'Validity',
            minWidth: 30,
            align: 'center',
        },

        {
            id: 'actions',
            label: 'Action',
            minWidth: 200,
            align: 'center',
        }

    ];

    const ActionJsx = ({ id, align, value, row }) => {

        const [creditValue, setCreditValue] = useState(0);
        const [assignLoading, setAssignLoading] = useState(false);
        const [deductLoading, setDeductLoading] = useState(false);
        const [removePlanLoading, setRemovePlanLoading] = useState(false)

        return (
            <TableCell
                key={id}
                align={align}
                sx={{
                    color: 'grey.400',
                    borderBottom: '0.5px dashed',
                    borderColor: 'grey.700',
                }}>
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

                    <TextField id="outlined-basic" type='number' label="Credits" value={creditValue} onChange={(e) => setCreditValue(e.target.value)} size='small' variant="outlined" sx={{ width: "150px" }} />

                    <Box >

                        <Tooltip title="Add Credit">

                            <LoadingButton
                                style={{
                                    width: '30px',
                                    minWidth: '30px'
                                }}
                                size="small"
                                color='success'
                                onClick={() => handleUpdateCredits(row, creditValue, setAssignLoading, "A")}
                                loading={assignLoading}
                                loadingIndicator={<CircularProgress style={{ color: '#22C55E' }} size={16} />}
                            >

                                <AddCircleIcon />
                            </LoadingButton>
                        </Tooltip >
                        <Tooltip title="Deduct Credit">

                            <LoadingButton
                                style={{
                                    width: '30px',
                                    minWidth: '30px'
                                }}
                                size="small" color='error' loading={deductLoading}
                                onClick={() => handleUpdateCredits(row, creditValue, setDeductLoading, "D")}
                                loadingIndicator={<CircularProgress style={{ color: '#FF5630' }} size={16} />}
                            >
                                <RemoveCircleIcon />
                            </LoadingButton>
                        </Tooltip>
                    </Box>
                    <Tooltip title="Remove Plan">
                        <LoadingButton
                            style={{
                                width: '30px',
                                minWidth: '30px'
                            }}
                            size="small" sx={{ color: "red", }} loading={removePlanLoading}
                            loadingIndicator={<CircularProgress style={{ color: 'red' }} size={16} />}
                            onClick={() => handleRemovePlan(row, setRemovePlanLoading)}

                        >
                            <DeleteIcon />
                        </LoadingButton>

                    </Tooltip>

                </Box>
            </TableCell>
        )
    }

    return (
        <Box>
            <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                sx={{ mb: "-20px" }}
            >
                Currrent Assign Plan
            </Typography>
            <PaginationTable
                page={page}
                ActionJsx={ActionJsx}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                loading={tableLoading}
                rows={rows}
                columns={columns}
                maxHeight={680}
                mapKey="plan_id"
            />
        </Box>

    )
}

UserPlanTable.propTypes = {
    campaignId: propTypes.number,
}
