import CloseIcon from '@mui/icons-material/Close';
import {
    Box,
    Typography,
    IconButton,
} from "@mui/material";
import { Stack } from '@mui/system';
const MessageBanner = ({ message, close, type, successColor, closeButton, icon }) => {
    let back_color='#f8d7da'
    let color ='#721c24'
    if(type==="success"){
         back_color= successColor || '#4cbb54'
         color= "#515079"
   }

   if(type === "error") {
    back_color= "#7A4F01";
    color= "white"
   }
   
    return (
        <Box 
        sx={{
            height: '35px',
            backgroundColor: back_color,
            borderColor: '#f5c6cb',
            color: color,
            borderRadius: '5px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            p: '5px',
            width: '100%',
        }}>
        <Stack direction={"row"} gap={1}>
            {icon}
            <Typography className='messageBanner' sx={{
                display: 'flex',
                justifyContent: 'center',
                fontSize: '14px',
                fontWeight: 500
            }}>
                {message}
            </Typography>
        </Stack>
            {closeButton && <Box sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                // alignContent: 'flex-end',
                // position: 'relative',
                // left: '100px',
            }}>
                <IconButton onClick={() => close()
                } sx={{
                    display: 'flex',
                    '&:hover': {
                        backgroundColor: 'transparent'
                    }
                }}>
                    <CloseIcon sx={{
                        fontSize: '26px',
                        fontWeight: 900
                    }} />
                </IconButton>
            </Box>}
        </Box>
    )
}
export default MessageBanner;