import React from 'react';
import { MenuItem, Menu, useTheme } from '@mui/material';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { ListItemIcon } from '@mui/material';
import TagIcon from '@mui/icons-material/Tag';
import { handleRedirect } from '../utils/common';
import Iconify from '../components/iconify/iconify';

const MoreMenu = ({ anchorEl, handleClose, self, products = [] }) => {
    const { palette: { mode = "light" } } = useTheme();
    return (
      <Menu
        anchorEl={anchorEl}
        id="more-menu"
        open={Boolean(anchorEl)}
        onClose={() => handleClose()}
        onClick={() => handleClose()}
        PaperProps={{
          elevation: 0,
          sx: {
            ml: "12px",
            mt: "-10px",
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 32,
              right: 0,
              left: -5,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        {products?.includes("Sorturl") && (
          <MenuItem
            onClick={(e) =>
              handleRedirect("sms", "", {}, self, mode, "shorturl")
            }
            sx={{
              color: "grey.200",
              display: "flex",
              alignItems: "center",
            }}
          >
            <ListItemIcon sx={{ color: "grey.400" }}>
              <TagIcon />
            </ListItemIcon>
            Short Url
          </MenuItem>
        )}
        {products?.includes("Chat") && (
          <MenuItem
            onClick={() => handleRedirect("chat", "", {}, self, mode)}
            sx={{
              color: "grey.200",
              display: "flex",
              alignItems: "center",
            }}
          >
            <ListItemIcon sx={{ color: "grey.400" }}>
              <ChatBubbleOutlineIcon />
            </ListItemIcon>
            Chat
          </MenuItem>
        )}
        {products?.includes("Lists") && (
          <MenuItem
            onClick={() => handleRedirect("lists", "", {}, self, mode)}
            sx={{
              color: "grey.200",
              display: "flex",
              alignItems: "center",
            }}
          >
            <ListItemIcon sx={{ color: "grey.400" }}>
              <Iconify icon="lucide:list-tree" />
              {/* <ChatBubbleOutlineIcon /> */}
            </ListItemIcon>
            Lists
          </MenuItem>
        )}
        {products?.includes("Drive") && (
          <MenuItem
            onClick={() => handleRedirect("drive", "", {}, self, mode)}
            sx={{
              color: "grey.200",
              display: "flex",
              alignItems: "center",
            }}
          >
            <ListItemIcon sx={{ color: "grey.400" }}>
              <Iconify icon="ri:drive-fill" />
              {/* <ChatBubbleOutlineIcon /> */}
            </ListItemIcon>
            Drive
          </MenuItem>
        )}
      </Menu>
    );
};

export default MoreMenu;
