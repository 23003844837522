import jsCookie from "js-cookie";
import PropTypes from "prop-types";
import { checkIframe } from "../utils/common";

// Get Single cookie data.
const getItem = (key) => {

  if (checkIframe()) {
    const refData = jsCookie.get("consoleRef");
    const data = JSON.parse(refData);
    return data[key];
  }
  return jsCookie.get(key);
};

getItem.propTypes = {
  key: PropTypes.string.isRequired, // String type key requried
};

// Get multiple cookees data.
const getBulkItems = (keyNamesArray) => {
  // console.log("Inside bulk Item", keyNamesArray);
  return keyNamesArray.map((key) => {
    const value = getItem(key);
    // console.log({ [key]: value });
    return { [key]: value };
  });
};

getBulkItems.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  // required array formate which is array of strings i.e ["key1", "key2", ...]
};

// Set single cookie.
const setItem = (key, value, exp = 1, path = "/") => {
  return jsCookie.set(key, value, {
    expires: exp,
    path,
    sameSite: "none",
    secure: true,
  });
};

setItem.propTypes = {
  key: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  exp: PropTypes.number,
  path: PropTypes.string,
  // required array formate which is array of strings i.e ["key1", "key2", ...]
};

// Set multiple cookies.
const setBulkItems = (keyValArrayOfObjects = []) => {
  return keyValArrayOfObjects.map(({ key, value, exp = 1, path = "/" }) =>
    setItem(key, value, exp, path)
  );
};

setBulkItems.propTypes = {
  keyValArrayOfObjects: PropTypes.arrayOf(PropTypes.object).isRequired,
};

// Remove particular single cookie.
const removeItem = (key, path = "/") => {
  if (checkIframe()) {
    return jsCookie.remove("consoleRef", { path })
  }
  return jsCookie.remove(key, { path });
};
removeItem.propTypes = {
  key: PropTypes.string.isRequired,
  path: PropTypes.string,
};

// Remove miltiple cookies.
const removeBulkItems = (keyValArrayOfObjects = []) => {
  return keyValArrayOfObjects.map(({ key, path = "/" }) =>
    removeItem(key, path)
  );
};
removeBulkItems.propTypes = {
  keyValArrayOfObjects: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export {
  getItem,
  getBulkItems,
  setItem,
  setBulkItems,
  removeItem,
  removeBulkItems,
};
