import React, { useState } from 'react'
import { styled } from '@mui/material/styles';
import { Typography, Box, useTheme } from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';

import { subDays } from 'date-fns';

import CampaignStatus from './campaignStatus';
import TotalCampaignsLineChart from './totalCampaigns';

const CardsMainSection = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.card.main,
    height: '100%',
    padding: '8px 15px',
    width: 'calc(100% / 2 - 7px)',
    borderRadius: "6px",
    border: '1px solid',
    borderColor: theme.palette.grey[1000],
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    [theme.breakpoints.down('lg')]: {
        width: '100%',
    },
}));

const HeadingSection = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: '7px',
    alignItems: 'center'
}));

const Heading = styled(Typography)(({ theme }) => ({
    fontSize: '20px',
    fontWeight: 600,
    color: theme.palette.grey[0]
}));

const VoiceSection = ({ callAnalyticsLabel, callDetailsSeries, runningCampaign, runningShow, notVoiceUser }) => {

    const [analyticsRange, setAnalyticsRange] = useState([
        {
            startDate: subDays(new Date(), 15),
            endDate: new Date(),
            key: 'selection',
        },
    ]);

    const theme = useTheme();
 
    return (
        <CardsMainSection>

            <HeadingSection>
                <MicIcon sx={{ color: theme.palette.mode === "light" ? 'black' : 'white' }} />
                <Heading>
                    Voice
                </Heading>
            </HeadingSection>

            {notVoiceUser ? <Box sx={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Typography>No data available for this product yet. Start using it to unlock insights! </Typography>
            </Box> :
                <>
                    {runningShow && <CampaignStatus
                        title="Running Campaign"
                        // ActionButton={CreateCampaignButton}
                        // runningCampaign={noRunningCampaign}
                        chart={{
                            series: runningCampaign,
                        }}
                    />}

                    {!runningShow && (

                        <TotalCampaignsLineChart
                            range={analyticsRange}
                            setRange={setAnalyticsRange}
                            title="Call Analytics"
                            // ActionButton={CreateCampaignButton}
                            chart={{
                                labels: callAnalyticsLabel,
                                series: callDetailsSeries,
                            }}
                        />
                    )}
                </>

            }

        </CardsMainSection>
    )
}

export default VoiceSection;
