import React from 'react';
import { Typography, Button, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import FinanceIconSvg from '../../assets/svgs/financeIcon';

const CardsMainSection = styled('div')(({ theme }) => ({
    height: '100%',
    padding: '18px 14px',
    width: 'calc(100% / 2)',
    borderRadius: "6px",
    border: '1px solid',
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    backgroundColor: theme.palette.card.main,
    borderColor: theme.palette.grey[1000],
    [theme.breakpoints.down('sm')]: {
        width: '50%',
    },
    [theme.breakpoints.between('sm', 'lg')]: {
        width: 'calc(100% / 2)',
    },
}));

const Heading = styled(Typography)(({ theme }) => ({
    color: theme.palette.grey[400],
    fontSize: '18px',
    fontWeight: 400,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: '100%'
}))

const CountSection = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    justifyContent: 'flex-start',
    alignItems: 'center'
}));

const CustomIcon = styled(IconButton)(({ theme }) => ({
    borderRadius: '10px',
    backgroundColor: theme.palette.error.lighter,
    color: theme.palette.grey[100],
    height: '36px',
    '&:hover': {
        backgroundColor: theme.palette.error?.lighter,
        color: theme.palette.grey[0],
    }
}));

const Count = styled(Typography)(({ theme }) => ({
    color: theme.palette.grey[0],
    fontSize: '36px',
    fontWeight: 700
}));

const CustomBuuton = styled(Button)(() => ({
    boxShadow: 'none',
    borderRadius: '23px',
    height: '25px',
    width: '85%',
    fontSize: '12px',
    fontWeight: 500
}));

const SupportTicket = () => {
    return (
        <CardsMainSection>
            <Heading>
                Support Ticket
            </Heading>

            <CountSection>
                <CustomIcon disableRipple disableFocusRipple disableTouchRipple>
                    <FinanceIconSvg />
                </CustomIcon>

                <Count>
                    0
                </Count>
            </CountSection>

            <CustomBuuton
                variant='contained'
                sx={{
                    backgroundColor: 'button.dark',
                    '&:hover': {
                        backgroundColor: 'button.dark',
                    },
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: '100%'
                }}
            >
                Coming Soon
            </CustomBuuton>
        </CardsMainSection>
    )
}

export default SupportTicket;
