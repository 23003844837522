//react
import React, { useState } from 'react';

//@mui
import { Box, Typography, Button } from '@mui/material';
import { styled } from '@mui/material/styles';

//@mui icons
import ApartmentIcon from '@mui/icons-material/Apartment';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import PropTypes from 'prop-types';

const ButtonGroupBox = styled('div')(({ theme }) => ({
    display: 'flex',
    gap: '14px',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column'
    },
}));

const ChooseMethod = ({ current, next, formData }) => {
    const [active, setActive] = useState("");

    const InActiveNextBtn = {
        backgroundColor: '#727374',
        color: "#B8B9BA",
        '&:hover': {
            backgroundColor: '#727374',
            color: "#B8B9BA",
        }
    }

    const optionCommonSx = {
        width: '200px',
        height: '60px',
        fontSize: '18px',
        fontWeight: 500,
    };

    const defaultOption = {
        backgroundColor: '#B8B9BA',
        color: "#2C2D2F",
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: '#B8B9BA',
            color: "#2C2D2F",
        },
    };

    const activeOption = {
        backgroundColor: '#50AB64',
        color: "#F0F0F0",
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: '#50AB64',
            color: "#F0F0F0",
        }
    };

    const handleSubmit = () => {
        formData.set("isCompanyKyc", active === 'company')
        next(active)
        current(false)
    }

    return (
        <Box sx={{
            display: 'flex',
            gap: '20px',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <Typography sx={{
                color: 'grey.100',
                fontSize: '32px',
                fontWeight: 600,
                lineHeight: '40px'
            }}>
                Are you working as?
            </Typography>

            <ButtonGroupBox>
                <Button
                    variant='contained'
                    startIcon={<ApartmentIcon />}
                    sx={active === "company" ? { ...activeOption, ...optionCommonSx } : { ...defaultOption, ...optionCommonSx }}
                    onClick={() => setActive("company")}
                >
                    Company
                </Button>
                <Button
                    variant='contained'
                    startIcon={<PersonOutlineOutlinedIcon />}
                    sx={active === "individual" ? { ...activeOption, ...optionCommonSx } : { ...defaultOption, ...optionCommonSx }}
                    onClick={() => setActive("individual")}
                >
                    Individual
                </Button>
            </ButtonGroupBox>

            <Button
                variant='contained'
                endIcon={<ArrowForwardIcon />}
                disabled={active === ""}
                sx={{
                    "&.Mui-disabled": InActiveNextBtn,
                    boxShadow: 'none'
                }}
                onClick={handleSubmit}
            >
                Save & Next
            </Button>
        </Box>
    )
}

ChooseMethod.propTypes = {
    current: PropTypes.func.isRequired,
    next: PropTypes.func.isRequired,
    formData: PropTypes.object.isRequired,
}

export default ChooseMethod;
