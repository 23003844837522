import React from 'react';
import { Outlet, Route } from "react-router-dom";

//pages
import Dashboard from '../pages/Dashboard';
import Sidebar from '../layouts/Sidebar';
import Login from '../pages/Auth/Login';
import SignUp from '../pages/Auth/Signup';
import ForgotPwd from '../pages/Auth/ForgotPwd';
import Verification from '../pages/Auth/Verification';
import { ProtectedRoute, PublicRoute } from './hoc';
import Spinner from '../pages/spinner';
import Navbar from '../layouts/Navbar';
import Settings from '../pages/settings';
import ComingSoon from '../pages/comingSoon';
import CustomSession from '../pages/customSession';
import { allowedRoute } from '../utils/common';
import Chat from '../pages/chat';
import Privacy from '../pages/privacy';
import UsersList from '../pages/usersList';
import NewUsers from '../pages/newUsers';
import Branding from '../pages/branding';
import ReferenceLogin from '../pages/referenceLogin';
import Plans from '../pages/plans';
import Contacts from '../pages/contactUs';
import Checkout from '../pages/Orders/order';

export default function AllRoutes() {
    return (
        <>
            <Route element={<><ProtectedRoute><Outlet /></ProtectedRoute></>}>
                <Route path="/" element={<><Sidebar /> <Navbar />  <Outlet /> </>}>

                    <Route index element={<Dashboard />} />
                    <Route exact path="/deepCall" element={<ComingSoon />} />
                    <Route exact path="/SMS" element={<Dashboard />} />
                    {/* <Route exact path="/chat" element={<ComingSoon />} /> */}
                    <Route exact path="/voice" element={<ComingSoon />} />
                    <Route exact path="/settings" element={<Settings />} />
                    <Route exact path="/users" element={<UsersList />} />
                    <Route exact path="/createUser" element={<NewUsers />} />
                    <Route exact path="/updateUser" element={<NewUsers />} />
                    <Route exact path="/branding" element={<Branding />} />
                    <Route exact path="/checkout" element={<Checkout />} />                    {/* <Route exact path="/invoices" element={<PaymentInvoiceList />} /> */}

                    {/* <Route exact path="/more" element={<Dashboard />} /> */}

                </Route>

            </Route>
            <Route element={<><PublicRoute > <Outlet /> </PublicRoute> </>}>

                {allowedRoute("signUp") && <Route path="/register" element={<SignUp />} />}
                {allowedRoute("forgetPassword") && <Route path="/forget-password" element={<ForgotPwd />} />}
                <Route path="/verification" element={<Verification />} />

            </Route>
            <Route exact path="/spinner" element={<Spinner />} />
            <Route path="/login" element={<Login />} />
            <Route path="/leads" element={<Chat />} />
            <Route exact path="/customSession" element={<CustomSession />} />
            <Route exact path="/privacy" element={<Privacy />} />
            <Route exact path="/refLogin" element={<ReferenceLogin />} />
            <Route exact path="/noProducts" element={<>Action not permitted </>} />
            <Route exact path="/plans" element={<Plans />} />
            <Route exact path="/contact" element={<Contacts/>} />

        </>

    )
}