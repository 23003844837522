import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material';

import Logger from '../../../lib/Logger';
import { axiosVBGetCall } from '../../../services/vbApiCall';
import { renderErrorMessage } from '../../../lib/toastMessage';

import { TtsPlanTable } from './ttsPlanTable';
import { UserPlanTable } from './userPlanTable';
import { ResellerPlanTable } from './resellerPlanTable';

const logger = new Logger("userPlansModal")

const BroadcastPlans = ({ user }) => {

    const [ttsData, setTtsData] = useState([]);
    const [credits, setCredits] = useState([]);

    const [assignedPlan, setAssignedPlan] = useState([]);
    const [planWiseCredits, setPlanWiseCredits] = useState([]);

    const [loading, setLoading] = useState(false);

    const getUserUpdatedCredit = async () => {

        try {

            const params = { searchUserId: user?.userId }

            const { userList = [] } = await axiosVBGetCall("userList", params);
            const User = userList[0] || {};

            setAssignedPlan(User?.assignedPlan);

            if (User?.planWiseCredits) {
                const assignedFilterData = User?.planWiseCredits.filter((item) => item['plan_id'] !== undefined);
                setPlanWiseCredits(assignedFilterData);
            }
            else {
                setPlanWiseCredits([]);
            }

            const ttsCredits = User?.tts_credits || {};

            if (Object.keys(ttsCredits).length > 0) {
                const data = Object.keys(ttsCredits).map((ttsType) => {
                    return { ttsType, ...ttsCredits[ttsType] }
                })
                setTtsData(data);
            }


        } catch (error) {
            logger.error("DeliveryLogsTable.jsx ,getDeliveryLogs ,deliveryLogs api", error);
            renderErrorMessage(error?.message)
        }
    }

    const getCredits = async () => {
        try {
            const { data } = await axiosVBGetCall("getPlanNCredits");
            const { userPlans: loginUserCredits } = data;
            const creditsFilteredData = loginUserCredits.filter((item) => item['plan_id'] !== undefined);
            setCredits(creditsFilteredData);

        } catch (error) {
            logger.error("DeliveryLogsTable.jsx ,getDeliveryLogs ,deliveryLogs api", error);
            renderErrorMessage(error?.message)
        }
    }

    const forceLoad = async () => {
        setLoading(true)
        await getUserUpdatedCredit();
        await getCredits();
        setLoading(false)
    }

    useEffect(() => {
        forceLoad();
        //eslint-disable-next-line
    }, [])


    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '18px',
            width: '100%',
            overflow: 'hidden',
            height: 'calc(70vh)',
            overflowY: 'scroll',
            scrollbarWidth: 'none'
        }}>
            {/* <Heading>
                TTS Plan
            </Heading> */}
            <TtsPlanTable credits={credits} assignedPlan={assignedPlan} user={user} forceLoad={forceLoad} ttsData={ttsData} loading={loading} />

            {/* <Heading>
                Currrent Assign Plan
            </Heading> */}
            <UserPlanTable planWiseCredits={planWiseCredits} user={user} forceLoad={forceLoad} loading={loading} />


            {/* <Heading>
                Reseller login Plans
            </Heading> */}
            <ResellerPlanTable credits={credits} assignedPlan={assignedPlan} user={user} forceLoad={forceLoad} loading={loading} />

        </Box>
    )
}

export default BroadcastPlans