import { useTheme } from '@mui/material';
import React from 'react';

const MailVolume = () => {
    const { palette: { mode = "light" } } = useTheme();

    const fillColor = mode === "light" ? '#282828' : "#FFF";

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
            <path d="M23.3333 16.9167V27.4167L19.4448 24.5H16.3333V19.8333H19.4448L23.3333 16.9167ZM24.5 3.5C24.8094 3.5 25.1062 3.62292 25.325 3.84171C25.5438 4.0605 25.6667 4.35725 25.6667 4.66667V15.1667H23.3333V8.44317L14.084 16.7277L4.66667 8.4175V22.1667H14V24.5H3.5C3.19058 24.5 2.89384 24.3771 2.67504 24.1583C2.45625 23.9395 2.33334 23.6428 2.33334 23.3333V4.66667C2.33334 4.35725 2.45625 4.0605 2.67504 3.84171C2.89384 3.62292 3.19058 3.5 3.5 3.5H24.5ZM24.5 19.8333C25.1055 19.8306 25.6883 20.0634 26.1253 20.4824C26.5624 20.9015 26.8194 21.474 26.8421 22.0791C26.8649 22.6842 26.6515 23.2744 26.2471 23.725C25.8427 24.1757 25.279 24.4515 24.675 24.4942L24.5 24.5V19.8333ZM22.75 5.83333H5.26284L14.0712 13.6057L22.75 5.83333Z" fill={fillColor} />
        </svg>
    )
}

export default MailVolume