const initialState = {
    balance: 0
};

const walletReducer = (state = initialState, action) => {
    switch (action.type) {

        case "UPDATE_BALANCE":
            const balance = action.payload;
            return { balance };

        default:
            return state;
    }
};

export default walletReducer;