import React from 'react'

const MicSvg = ({ color }) => {
    return (
        <svg
            id="Capa_1"
            enableBackground="new 0 0 512 512"
            height="20"
            viewBox="0 0 512 512"
            width="20"
            xmlns="http://www.w3.org/2000/svg">
            <g>
                <path fill={color ? color : "currentColor"} d="m226 270h-73.92c7.29 50.82 51.11 90 103.92 90s96.63-39.18 103.92-90h-73.92v-30h75v-45h-75v-30h75v-45h-75v-30h73.92c-7.29-50.82-51.11-90-103.92-90s-96.63 39.18-103.92 90h73.92v30h-75v45h75v30h-75v45h75z" />
                <path fill={color ? color : "currentColor"} d="m271 419.33c83.98-7.61 150-78.41 150-164.33-3.801 0-21.451 0-30 0 0 74.514-60.629 135-135 135-74.44 0-135-60.56-135-135h-30c0 85.92 66.02 156.72 150 164.33v62.67h-105v30h240v-30h-105z" />
            </g>
        </svg>
    )
}

export default MicSvg