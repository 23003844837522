import { Stack, Box, alpha, Typography } from "@mui/material"
// import UploadBox from "src/components/upload/upload-box";
import UploadBox from "../../components/upload/upload-box"

const UploadBrandingImage = ({ name, label, handleFileChange, imageUrl, imageDesc, onRemove }) => {
    const acceptedMimes = {
        "image/*": [".png", ".gif", ".jpeg", ".jpg"],
    }
    return (
        <Stack dirction={"column"} gap={1} width={"100%"}>
            <Typography varaint="body1" sx={{ fontSize: '14px', fontWeight: 400 }}>{label}</Typography>
            <Box
                fullWidth
                sx={{
                    display: "flex",
                    p: 2,
                    // mb: 3,
                    borderRadius: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    height: "140px",
                    width: "100%",
                    border: (theme) => `solid 1px ${alpha(theme.palette.grey[500], 0.2)}`
                }}>

                <UploadBox
                    name={name}
                    fileType="image"
                    handleFileChange={handleFileChange}
                    acceptedMimes={acceptedMimes}
                    imageUrl={imageUrl}
                    onRemove={onRemove}
                    sx={{ width: "100%", height: "100%" }}
                />

            </Box>
            <Box sx={{
                display: 'flex',
                justifyContent: 'flex-end'
            }}>
                <Typography
                    variant="subttitle2"
                    sx={{
                        color: (theme) => theme.palette.grey[600],
                        fontSize: '12px',
                        fontWeight: 400,
                    }}
                >
                    {imageDesc}
                </Typography>
            </Box>


        </Stack>
    )
}
export default UploadBrandingImage;