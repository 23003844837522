import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { styled } from '@mui/material/styles';
import ListPublicPlans from '../sections/plans/listPublicPlans';
import PublicNavbar from '../layouts/publicNavbar';


const Heading = styled(Typography)(() => ({
    fontSize: '34px',
    fontWeight: 600
}));

const Plans = () => {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '40px',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'center'
        }}>
            <PublicNavbar />

            <ListPublicPlans />

            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
                mt: '40px',
                mb: '40px',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <Heading>
                    Try Workspace for free
                </Heading>

                <Typography color="text.secondary">
                    See why millions of businesses love and rely on Workspace for their business needs.
                </Typography>

                <Button variant='outlined'>
                    Start a trial
                </Button>
            </Box>
        </Box>
    )
}

export default Plans