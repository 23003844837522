import React from 'react'

const KycVerification = () => (
    <svg width="100" height="101" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_458_19466)">
            <path d="M8.54166 8.521H64.5833C67.1875 8.521 69.2708 10.6043 69.2708 13.2085V94.771C69.2708 97.3752 67.1875 99.4585 64.5833 99.4585H8.54166C5.93749 99.4585 3.85416 97.3752 3.85416 94.771V13.2085C3.74999 10.6043 5.93749 8.521 8.54166 8.521Z" fill="#B3C4F3" />
            <path d="M64.4792 100.5H8.54167C5.3125 100.5 2.8125 97.8957 2.8125 94.7707V13.2082C2.8125 9.979 5.41667 7.479 8.54167 7.479H64.5833C67.8125 7.479 70.3125 10.0832 70.3125 13.2082V94.7707C70.3125 97.8957 67.7083 100.5 64.4792 100.5ZM8.54167 9.56234C6.5625 9.56234 4.89583 11.229 4.89583 13.2082V94.7707C4.89583 96.7498 6.5625 98.4165 8.54167 98.4165H64.5833C66.5625 98.4165 68.2292 96.7498 68.2292 94.7707V13.2082C68.2292 11.229 66.5625 9.56234 64.5833 9.56234H8.54167Z" fill="#302D3D" />
            <path d="M76.2499 20.3958V88.2083C76.2499 90.8125 74.0624 93 71.4583 93H15.6249C13.0208 93 10.8333 90.8125 10.8333 88.2083V6.3333C10.8333 3.72913 13.0208 1.54163 15.6249 1.54163C61.2499 1.54163 58.1249 1.3333 59.4791 1.9583C60.8333 2.5833 59.9999 2.06247 74.8958 16.9583C75.7291 17.8958 76.2499 19.0416 76.2499 20.3958Z" fill="#D2DDF2" />
            <path d="M16.0417 87.6875V6.75H57.2917L71.0417 20.5V87.6875H16.0417Z" fill="#E6ECF2" />
            <path d="M76.2501 20.396C76.2501 20.5002 77.7084 20.5002 57.2917 20.5002C57.3959 0.0834969 57.2917 1.54183 57.5001 1.54183C58.7501 1.54183 60.0001 2.06266 60.8334 2.896C75.9376 18.0002 75.3126 17.1668 75.8334 18.3127C76.1459 19.0418 76.2501 19.6668 76.2501 20.396Z" fill="#D2DDF2" />
            <path d="M71.4584 93.9375H15.6251C12.3959 93.9375 9.79175 91.3333 9.79175 88.1042V6.33333C9.79175 3.10417 12.3959 0.5 15.6251 0.5H57.5001C58.3334 0.5 59.1668 0.708333 59.8959 1.02083C61.2501 1.64583 60.5209 1.125 75.6251 16.2292C76.7709 17.375 77.3959 18.8333 77.2917 20.3958V88.1042C77.2917 91.3333 74.6876 93.9375 71.4584 93.9375ZM15.6251 2.58333C13.5417 2.58333 11.8751 4.25 11.8751 6.33333C11.9792 6.33333 11.8751 4.35416 11.8751 88.1042C11.8751 90.1875 13.5417 91.8542 15.6251 91.8542H71.4584C73.5417 91.8542 75.2084 90.1875 75.2084 88.1042V20.3958C75.2084 19.4583 74.8959 18.4167 74.1667 17.7917C58.9584 2.58333 59.8959 3.3125 59.0626 2.89583C58.0209 2.47917 61.4584 2.58333 15.6251 2.58333Z" fill="#302D3D" />
            <path d="M24.7916 22.1665H59.7916C61.7708 22.1665 63.4374 23.8332 63.4374 25.8123V56.4373C63.4374 58.4165 61.7708 60.0832 59.7916 60.0832H24.7916C22.8124 60.0832 21.1458 58.4165 21.1458 56.4373V25.8123C21.1458 23.729 22.8124 22.1665 24.7916 22.1665Z" fill="white" />
            <path d="M24.7916 67.6875H59.7916C61.7708 67.6875 63.4374 69.3542 63.4374 71.3333V74.25C63.4374 76.2292 61.7708 77.8958 59.7916 77.8958H24.7916C22.8124 77.8958 21.1458 76.2292 21.1458 74.25V71.3333C21.1458 69.3542 22.8124 67.6875 24.7916 67.6875Z" fill="white" />
            <path d="M64.1667 34.2498H30.4167C29.7917 34.2498 29.375 33.8332 29.375 33.2082C29.375 32.5832 29.7917 32.1665 30.4167 32.1665H64.1667C64.7917 32.1665 65.2083 32.5832 65.2083 33.2082C65.2083 33.8332 64.7917 34.2498 64.1667 34.2498Z" fill="#302D3D" />
            <path d="M64.1667 41.7498H30.4167C29.7917 41.7498 29.375 41.3332 29.375 40.7082C29.375 40.0832 29.7917 39.6665 30.4167 39.6665H64.1667C64.7917 39.6665 65.2083 40.0832 65.2083 40.7082C65.2083 41.3332 64.7917 41.7498 64.1667 41.7498Z" fill="#302D3D" />
            <path d="M49.6875 49.2498H30.4167C29.7917 49.2498 29.375 48.8332 29.375 48.2082C29.375 47.5832 29.7917 47.1665 30.4167 47.1665H49.6875C50.3125 47.1665 50.7292 47.5832 50.7292 48.2082C50.7292 48.8332 50.2083 49.2498 49.6875 49.2498Z" fill="#302D3D" />
            <path d="M64.1667 56.7498H30.4167C29.7917 56.7498 29.375 56.3332 29.375 55.7082C29.375 55.0832 29.7917 54.6665 30.4167 54.6665H64.1667C64.7917 54.6665 65.2083 55.0832 65.2083 55.7082C65.2083 56.3332 64.7917 56.7498 64.1667 56.7498Z" fill="#302D3D" />
            <path d="M64.1667 71.7498H30.4167C29.7917 71.7498 29.375 71.3332 29.375 70.7082C29.375 70.0832 29.7917 69.6665 30.4167 69.6665H64.1667C64.7917 69.6665 65.2083 70.0832 65.2083 70.7082C65.2083 71.3332 64.7917 71.7498 64.1667 71.7498Z" fill="#302D3D" />
            <path d="M42.6042 79.2498H30.4167C29.7917 79.2498 29.375 78.8332 29.375 78.2082C29.375 77.5832 29.7917 77.1665 30.4167 77.1665H42.6042C43.2292 77.1665 43.6458 77.5832 43.6458 78.2082C43.6458 78.8332 43.2292 79.2498 42.6042 79.2498Z" fill="#302D3D" />
            <path d="M40.4167 64.2498H30.4167C29.7917 64.2498 29.375 63.8332 29.375 63.2082C29.375 62.5832 29.7917 62.1665 30.4167 62.1665H40.4167C41.0417 62.1665 41.4583 62.5832 41.4583 63.2082C41.4583 63.8332 40.9375 64.2498 40.4167 64.2498Z" fill="#302D3D" />
            <path d="M59.4792 1.9585V18.3127H75.8334C75.3126 17.1668 75.9376 18.0002 60.8334 2.896C60.4168 2.5835 60.0001 2.16683 59.4792 1.9585Z" fill="#B3C4F3" />
            <path d="M95.7292 91.9582L91.3542 96.3332C90.625 97.0623 89.4792 97.0623 88.75 96.3332C87.6042 95.1873 93.0208 100.708 76.875 84.4582C76.1458 83.729 76.1458 82.5832 76.875 81.854C77.2917 81.4373 75.3125 83.4165 81.25 77.479C81.9792 76.7498 83.125 76.7498 83.8542 77.479L95.7292 89.354C96.3542 90.0832 96.3542 91.229 95.7292 91.9582Z" fill="#FE4A55" />
            <path d="M81.4583 77.3748L76.7708 82.0623L71.25 76.5415C73.0208 75.2915 74.6875 73.6248 75.9375 71.854L81.4583 77.3748Z" fill="#F0824B" />
            <path d="M59.5834 73.4168C66.8897 73.4168 72.8126 67.4939 72.8126 60.1877C72.8126 52.8814 66.8897 46.9585 59.5834 46.9585C52.2771 46.9585 46.3542 52.8814 46.3542 60.1877C46.3542 67.4939 52.2771 73.4168 59.5834 73.4168Z" fill="#E9ECEF" />
            <path d="M59.5834 70.2918C53.9584 70.2918 49.4792 65.7085 49.4792 60.1877C49.4792 54.5627 54.0626 50.0835 59.5834 50.0835C65.2084 50.0835 69.6876 54.6668 69.6876 60.1877C69.6876 65.7085 65.2084 70.2918 59.5834 70.2918Z" fill="white" />
            <path d="M73.7501 46.021C65.9376 38.2085 53.2292 38.2085 45.4167 46.021C37.6042 53.8335 37.6042 66.5418 45.4167 74.3543C53.2292 82.1668 65.9376 82.1668 73.7501 74.3543C81.5626 66.5418 81.5626 53.8335 73.7501 46.021ZM68.9584 69.5627C63.7501 74.771 55.4168 74.771 50.2084 69.5627C45.0001 64.3543 45.0001 56.021 50.2084 50.8127C55.4168 45.6043 63.7501 45.6043 68.9584 50.8127C74.1667 56.021 74.0626 64.3543 68.9584 69.5627Z" fill="#FFCA6E" />
            <path d="M55.3125 45.3956C55.3125 42.8956 52.6042 41.229 50.4167 42.3748C46.0417 44.5623 42.7084 48.4165 40.9375 52.8956C40.3125 54.6665 41.1459 56.6456 42.9167 57.2706C44.6875 57.8956 46.5625 57.0623 47.1875 55.3956C48.3334 52.3748 50.5209 49.8748 53.4375 48.4165C54.5834 47.8956 55.3125 46.7498 55.3125 45.3956Z" fill="#FFE391" />
            <path d="M63.8542 74.8751C63.8542 77.3751 66.5626 79.0418 68.7501 77.896C73.1251 75.6043 76.4584 71.8543 78.2292 67.271C78.7501 65.5001 77.7084 63.6251 75.9376 63.1043C74.3751 62.6876 72.7084 63.4168 71.9792 64.771C70.8334 67.7918 68.6459 70.2918 65.7292 71.7501C64.5834 72.4793 63.8542 73.6251 63.8542 74.8751Z" fill="#FFA64D" />
            <path d="M85.6741 93.2422L92.5968 86.3195L94.0697 87.7924L87.147 94.7151L85.6741 93.2422Z" fill="#E61F34" />
            <path d="M90.5208 84.1458L83.5416 91.125L82.0833 89.5625L89.0624 82.6875L90.5208 84.1458Z" fill="#E61F34" />
            <path d="M78.5295 85.915L85.462 78.9923L86.935 80.4673L80.0025 87.39L78.5295 85.915Z" fill="#E61F34" />
            <path d="M75.8333 19.354H59.4792C58.8542 19.354 58.4375 18.9373 58.4375 18.3123V1.95817C58.4375 1.33317 58.8542 0.916504 59.4792 0.916504C60.1042 0.916504 61.1458 1.74984 61.5625 2.1665C76.875 17.479 76.1458 16.6457 76.7708 17.8957C77.0833 18.6248 76.5625 19.354 75.8333 19.354ZM60.5208 17.2707H73.75L60.5208 4.0415V17.2707Z" fill="#302D3D" />
            <path d="M24.6874 36.7498H21.0416C19.6874 36.7498 18.6458 35.7082 18.6458 34.354C18.6458 34.2498 18.6458 34.8748 18.6458 32.0623C18.6458 30.7082 19.6874 29.6665 21.0416 29.6665H24.6874C26.0416 29.6665 27.0833 30.7082 27.0833 32.0623V34.354C27.0833 35.604 26.0416 36.7498 24.6874 36.7498ZM21.0416 31.6457C20.8333 31.6457 20.7291 31.854 20.7291 31.9582V34.2498C20.7291 34.4582 20.8333 34.5623 21.0416 34.5623H24.6874C24.8958 34.5623 24.9999 34.354 24.9999 34.2498V31.9582C24.9999 31.7498 24.7916 31.6457 24.6874 31.6457C24.7916 31.6457 21.7708 31.6457 21.0416 31.6457Z" fill="#302D3D" />
            <path d="M21.0416 51.7498C19.6874 51.7498 18.6458 50.7082 18.6458 49.354C18.6458 49.2498 18.6458 49.8748 18.6458 47.0623C18.6458 45.7082 19.6874 44.6665 21.0416 44.6665H24.6874C26.0416 44.6665 27.0833 45.7082 27.0833 47.0623V49.354C27.0833 50.7082 26.0416 51.7498 24.6874 51.7498C22.3958 51.7498 23.3333 51.7498 21.0416 51.7498ZM21.0416 46.7498C20.8333 46.7498 20.7291 46.9582 20.7291 47.0623V49.354C20.7291 49.5623 20.8333 49.6665 21.0416 49.6665H24.6874C24.8958 49.6665 24.9999 49.4582 24.9999 49.354V47.0623C24.9999 46.854 24.7916 46.7498 24.6874 46.7498C24.7916 46.7498 21.7708 46.7498 21.0416 46.7498Z" fill="#302D3D" />
            <path d="M21.0416 66.7498C19.6874 66.7498 18.6458 65.7082 18.6458 64.354C18.6458 64.2498 18.6458 64.8748 18.6458 62.0623C18.6458 60.7082 19.6874 59.6665 21.0416 59.6665H24.6874C26.0416 59.6665 27.0833 60.7082 27.0833 62.0623V64.354C27.0833 65.7082 26.0416 66.7498 24.6874 66.7498C22.3958 66.7498 23.3333 66.7498 21.0416 66.7498ZM21.0416 61.7498C20.8333 61.7498 20.7291 61.9582 20.7291 62.0623V64.354C20.7291 64.5623 20.8333 64.6665 21.0416 64.6665H24.6874C24.8958 64.6665 24.9999 64.4582 24.9999 64.354V62.0623C24.9999 61.854 24.7916 61.7498 24.6874 61.7498C24.7916 61.7498 21.7708 61.7498 21.0416 61.7498Z" fill="#302D3D" />
            <path d="M24.6874 81.8538H21.0416C19.6874 81.8538 18.6458 80.8122 18.6458 79.458C18.6458 79.3538 18.6458 79.9788 18.6458 77.1663C18.6458 75.8122 19.6874 74.7705 21.0416 74.7705H24.6874C26.0416 74.7705 27.0833 75.8122 27.0833 77.1663V79.458C27.0833 80.708 26.0416 81.8538 24.6874 81.8538ZM21.0416 76.7497C20.8333 76.7497 20.7291 76.958 20.7291 77.0622V79.3538C20.7291 79.5622 20.8333 79.6663 21.0416 79.6663H24.6874C24.8958 79.6663 24.9999 79.458 24.9999 79.3538V77.0622C24.9999 76.8538 24.7916 76.7497 24.6874 76.7497H21.0416Z" fill="#302D3D" />
            <path d="M88.0208 97.0625L76.1458 85.1875C74.9999 84.0417 74.9999 82.2708 76.1458 81.125L80.5208 76.75C81.6666 75.6042 83.4374 75.6042 84.5833 76.75L96.4583 88.625C97.6041 89.7708 97.6041 91.5417 96.4583 92.6875L92.0833 97.0625C90.9374 98.1042 89.1666 98.1042 88.0208 97.0625ZM82.4999 78C81.9791 78 82.2916 77.8958 77.6041 82.5833C77.2916 82.8958 77.2916 83.4167 77.6041 83.7292C78.7499 84.9792 73.2291 79.3542 89.4791 95.6042C89.7916 95.9167 90.3124 95.9167 90.6249 95.6042L94.9999 91.2292C95.3124 90.9167 95.3124 90.3958 94.9999 90.0833C81.8749 76.9583 82.9166 78 82.4999 78Z" fill="#302D3D" />
            <path d="M76.6666 83.4164L71.0416 77.7914C61.2499 84.1456 48.2291 81.3331 41.8749 71.6456C35.5207 61.9581 38.3332 48.8331 48.0207 42.4789C57.7082 36.1247 70.8332 38.9372 77.1874 48.6247C81.7707 55.6039 81.7707 64.6664 77.1874 71.6456L82.8124 77.2706C83.2291 77.6872 83.2291 78.3122 82.8124 78.7289L78.1249 83.4164C77.7082 83.8331 77.0832 83.8331 76.6666 83.4164ZM71.8749 75.7081L77.3957 81.1247L80.6249 77.8956L75.2082 72.3747C74.8957 72.0622 74.7916 71.4372 75.1041 71.0206C81.1457 62.4789 79.1666 50.6039 70.6249 44.5622C62.0832 38.5206 50.2082 40.4997 44.1666 49.0414C38.1249 57.5831 40.1041 69.4581 48.6457 75.4997C55.2082 80.1872 64.0624 80.1872 70.7291 75.4997C70.9374 75.3956 71.5624 75.3956 71.8749 75.7081Z" fill="#302D3D" />
            <path d="M49.479 70.2913C43.854 64.6663 43.854 55.708 49.479 50.083C55.104 44.458 64.0623 44.458 69.6873 50.083C75.3123 55.708 75.3123 64.6663 69.6873 70.2913C64.1665 75.8122 55.104 75.9163 49.479 70.2913ZM59.5832 47.9997C52.8123 47.9997 47.3957 53.4163 47.3957 60.1872C47.3957 66.958 52.8123 72.3747 59.5832 72.3747C62.8123 72.3747 65.9373 71.1247 68.229 68.833C75.8332 61.1247 70.3123 47.9997 59.5832 47.9997Z" fill="#302D3D" />
            <path d="M86.4582 94.979C85.5207 94.979 85.1041 93.8332 85.7291 93.2082L92.7082 86.229C93.1249 85.8123 93.7499 85.8123 94.1666 86.229C94.3749 86.4373 94.7916 87.1665 94.1666 87.6873L87.1874 94.6665C86.9791 94.8748 86.7707 94.979 86.4582 94.979Z" fill="#302D3D" />
            <path d="M82.8125 91.4371C81.875 91.4371 81.4583 90.2913 82.0833 89.6663L89.0625 82.6871C89.4791 82.2705 90.2083 82.3746 90.5208 82.7913C90.8333 83.208 90.8333 83.7288 90.5208 84.1455L83.5416 91.1246C83.3333 91.333 83.125 91.4371 82.8125 91.4371Z" fill="#302D3D" />
            <path d="M79.1665 87.7915C78.229 87.7915 77.8123 86.6457 78.4373 86.0207L85.4165 79.0415C85.8332 78.6248 86.4582 78.6248 86.8748 79.0415C87.2915 79.4582 87.2915 80.0832 86.8748 80.4998C86.1457 81.229 89.3748 77.9998 79.8956 87.479C79.7915 87.6873 79.479 87.7915 79.1665 87.7915Z" fill="#302D3D" />
            <path d="M57.0834 64.8747L53.6459 60.9163C53.2292 60.4997 53.3334 59.8747 53.75 59.458C54.1667 59.0413 54.8959 59.1455 55.3125 59.5622L57.9167 62.583L64.6875 55.3955C65.1042 54.9788 65.7292 54.9788 66.1459 55.3955C66.5625 55.8122 66.5625 56.4372 66.1459 56.8538L58.6459 64.8747C58.2292 65.2913 57.5 65.2913 57.0834 64.8747Z" fill="#302D3D" />
        </g>
        <defs>
            <clipPath id="clip0_458_19466">
                <rect width="100" height="100" fill="white" transform="translate(0 0.5)" />
            </clipPath>
        </defs>
    </svg>
)

export default KycVerification;



