import React from 'react'
import CustomModal from '../../components/modal/customModal'
import { Box, Button, styled, Typography, Stack, Chip, Avatar } from '@mui/material';
import { nameFormater } from '../../utils/common';


const RowBox = styled('div')(({ theme }) => ({
    display: 'flex',
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
    },
    justifyContent: 'space-between',
    gap: '40px',
    width: '100%'
}))

const ProfileImage = styled(Avatar)(() => ({
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: '50%',
}));

const RowInnerBox = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    // justifyContent: 'space-between',
    width: 'calc(100%/2 - 20px)',
    gap: '20px'
}))

const Key = styled(Typography)(({ theme }) => ({
    fontWeight: 500,
    color: theme.palette.grey[400],
    width: '100px',
    minWidth: '100px'
}))

const Value = styled(Typography)(({ theme }) => ({
    fontWeight: 500,
    color: theme.palette.grey[100],
}))

const UserDetails = ({ data, openDetails, handleClose, setOpenDetails }) => {

    const { email, mobile, name, maritalStatus, gender, address,
        city, zipcode, role, permission, products = [], suspendTime, flag, profileUrl
    } = data;

    const forceActions = () => (
        <Box sx={{
            mt: 3,
            mb: -2,
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            gap: '10px',
            justifyContent: 'flex-end'
        }}>
            <Button
                variant='outlined'
                size='small'
                onClick={() => {
                    setOpenDetails(false);
                    handleClose(false)
                }}
            >
                Close
            </Button>
        </Box>
    )

    const profileSection = (
        <Stack spacing={0.5} direction="column" justifyContent={'center'} alignItems={'center'} sx={{ mt: "15px", mb: '10px' }}>
            <Stack spacing={3} direction="row" justifyContent={'center'} sx={{ pb: "15px" }}>
                {/* <Badge color={status === "active" ? "success" : "error"} overlap="circular" badgeContent=" " variant="dot"> */}
                <Box sx={{ width: "100px", height: "100px" }}>
                    <ProfileImage src={profileUrl} />
                </Box>
                {/* </Badge> */}
            </Stack>

            <Typography sx={{ fontWeight: 500 }}>
                {name}
            </Typography>
            <Typography sx={{ fontWeight: 500 }}>
                {email}
            </Typography>
        </Stack>
    )

    const description = (
        <Box sx={{
            mt: 1.3,
            display: 'flex',
            flexDirection: 'column',
            gap: '15px',
            width: '50vw',
            padding: '20px'
        }}>
            {profileSection}

            <RowBox>
                <RowInnerBox>
                    <Key>
                        NAME
                    </Key>
                    <Value>
                        {nameFormater(name)}
                    </Value>
                </RowInnerBox>

                <RowInnerBox>
                    <Key>
                        MOBILE
                    </Key>
                    <Value>
                        {mobile}
                    </Value>
                </RowInnerBox>
            </RowBox>

            <RowBox>
                <RowInnerBox>
                    <Key>
                        GENDER
                    </Key>
                    <Value>
                        {nameFormater(gender)}
                    </Value>
                </RowInnerBox>

                <RowInnerBox>
                    <Key>
                        MARITAL STATUS
                    </Key>
                    <Value>
                        {nameFormater(maritalStatus)}
                    </Value>
                </RowInnerBox>
            </RowBox>

            <RowBox>
                <RowInnerBox>
                    <Key>
                        ADDRESS
                    </Key>
                    <Value>
                        {address}
                    </Value>
                </RowInnerBox>

                <RowInnerBox>
                    <Key>
                        CITY
                    </Key>
                    <Value>
                        {nameFormater(city)}
                    </Value>
                </RowInnerBox>
            </RowBox>

            <RowBox>
                <RowInnerBox>
                    <Key>
                        ZIP/CODE
                    </Key>
                    <Value>
                        {zipcode}
                    </Value>
                </RowInnerBox>

                <RowInnerBox>
                    <Key>
                        ROLE
                    </Key>
                    <Value>
                        {nameFormater(role)}
                    </Value>
                </RowInnerBox>
            </RowBox>

            <RowBox>
                <RowInnerBox>
                    <Key>
                        PERMISSIONS
                    </Key>
                    <Value>
                        {nameFormater(permission)}
                    </Value>
                </RowInnerBox>

                <RowInnerBox>
                    <Key>
                        PRODUCTS
                    </Key>
                    <Value>
                        <Box sx={{ display: "flex", gap: "5px", flexWrap: 'wrap' }}>
                            {products.map((item) => {
                                return (
                                    <Chip label={nameFormater(item)} />
                                )
                            })}
                        </Box>
                    </Value>
                </RowInnerBox>
            </RowBox>

            <RowBox>
                <RowInnerBox>
                    <Key>
                        DE-ACTIVATION DATE
                    </Key>
                    <Value>
                        {suspendTime}
                    </Value>
                </RowInnerBox>

                <RowInnerBox>
                    <Key>
                        FLAG
                    </Key>
                    <Value>
                        {flag && <Chip label={nameFormater(flag)} />}
                    </Value>
                </RowInnerBox>
            </RowBox>

        </Box>
    )

    return (
        <CustomModal
            open={openDetails}
            title={"User Details"}
            description={description}
            close={() => setOpenDetails(false)}
            action={forceActions()}
            isCloseIcon={false}
        />
    )
}

export default UserDetails