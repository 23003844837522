import React, { useEffect, useState } from 'react'
import CustomModal from '../../components/modal/customModal';

import { styled } from '@mui/material/styles';
import { MuiOtpInput } from 'mui-one-time-password-input';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import { Box, Typography, Link } from '@mui/material';

const MainSection = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    paddingTop: '20px',
    paddingBottom: '28px',
}));

const VerifyOtpModal = ({ open = true, otp, handleOtpChange, handleClose, handleSendOtp, handleVerifyOtp }) => {
    const [isResendDisabled, setIsResendDisabled] = useState(true);
    const [timer, setTimer] = useState(60);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        let interval;
        if (timer > 0 && isResendDisabled) {
            interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        } else {
            clearInterval(interval);
            setIsResendDisabled(false);
        }

        return () => clearInterval(interval);
    }, [timer, isResendDisabled]);

    const description = (
        <MainSection>
            <MuiOtpInput
                value={otp}
                onChange={(e) => handleOtpChange(e)}
                length={6}
                validateChar={(val) => !isNaN(val)}
                TextFieldsProps={{ size: 'small', placeholder: '-' }}
                sx={{
                    width: '100%',
                    '& .MuiInputBase-input': {
                        color: 'grey.0',
                    }
                }}
            />
            <Box sx={{
                display: 'flex',
                gap: '6px',
                flexDirection: 'column',
                alignItems: 'flex-start',
            }}>
                <Typography variant="body2" sx={{
                    fontSize: '13px',
                    fontWeight: 500,
                    color: '#282828',
                }}><Link underline="none" onClick={(e) => handleSendOtp(true, setTimer, setIsResendDisabled)} style={{ pointerEvents: isResendDisabled ? 'none' : '', underline: 'none' }} color={isResendDisabled ? 'textSecondary' : 'primary'} href="#">Resend OTP</Link> {isResendDisabled ? `(${timer}s)` : ''}</Typography>
            </Box>
        </MainSection>
    )

    const action = (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center'
            }}>

            <LoadingButton
                variant='contained'
                loading={loader}
                size='small'
                sx={{ boxShadow: 'none' }}
                onClick={(e) => handleVerifyOtp(setLoader)}
            >
                Verify OTP
            </LoadingButton>
        </Box>

    )

    return (
        <CustomModal
            open={open}
            title={"Enter OTP"}
            description={description}
            close={() => handleClose(false)}
            action={action}
        />
    )
}

export default VerifyOtpModal;