import React from 'react';
import { MenuItem, Menu } from '@mui/material';
// import { useTheme } from '@emotion/react';

export const ProfileMenu = ({ anchorEl, handleClose, logout, /*handleTheme*/ }) => {
    // const { palette: { mode } } = useTheme();
    // const modeMapper = {
    //     light: "Dark",
    //     dark: "Light"
    // }
    return (
        <Menu
            anchorEl={anchorEl}
            id="profile-menu"
            open={Boolean(anchorEl)}
            onClose={() => handleClose()}
            onClick={() => handleClose()}
            PaperProps={{
                elevation: 0,
                sx: {
                    ml: '12px',
                    mt: '-10px',
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                    },
                    '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 32,
                        right: 0,
                        left: -5,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                    },
                },
            }}
            transformOrigin={{ horizontal: 'left', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        >
            {/*  <MenuItem onClick={() => navigate('/kyc')}>
                Kyc
            </MenuItem>

            <Divider />
 */}
            <MenuItem
                onClick={(e) => logout()}
                sx={{ color: 'grey.200' }}
            >
                Logout
            </MenuItem>

            {/* <MenuItem
                onClick={(e) => handleTheme(modeMapper[mode].toLowerCase())}
                sx={{ color: 'grey.200' }}
            >
                {(modeMapper[mode])} Theme
            </MenuItem> */}
        </Menu>
    );
};
