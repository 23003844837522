import { Icon } from '@iconify/react'
import { Button, styled } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import BasicSearch from '../../../components/search/basicSearch';
import AddAmountModal from './addAmount';
import FilterMenu from './filterMenu';

const MainSection = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'flex-start',
        gap: "10px"
    },
}));

const PlanFilters = ({ findForSearchQuery, showFilterLabels, apply, reset, search: newSearch, isFilterApplied, filterData, setFilterData, fetchTransActionsList }) => {
    const [search, setSearch] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);
    const [openAddAmount, setOpenAddAmount] = useState(false);
    const open = Boolean(anchorEl);

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            findForSearchQuery(search)
        }
    }


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleAddAmount = () => {
        navigate(`/settings?tab=${parseInt(searchParams.get("tab"))}&payment=${true}`, { replace: true });
        // setOpenAddAmount(true);
    }

    useEffect(() => {
        setSearch(newSearch)
    }, [newSearch])

    useEffect(() => {
        if (searchParams.get("payment")) {
            setOpenAddAmount(true);
        }
    }, [searchParams]);


    return (
        <MainSection>

            <BasicSearch
                text="Search Transactions..."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onKeyDown={handleKeyPress}
            />

            <Button
                variant='contained'
                size='small'
                sx={{
                    boxShadow: 'none'
                }}
                onClick={() => findForSearchQuery(search)}
            >
                Search
            </Button>

            <Button
                variant='outlined'
                size='small'
                sx={{
                    boxShadow: 'none'
                }}
                id="filter-button"
                aria-controls={open ? 'filter-button' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                filters
            </Button>

            <Button
                variant='outlined'
                size='small'
                startIcon={<Icon icon="fluent:panel-left-add-28-filled" />}
                sx={{
                    boxShadow: 'none'
                }}
                onClick={() => handleAddAmount()}
            >
                Add Amount
            </Button>

            <FilterMenu
                anchorEl={anchorEl}
                open={open}
                handleClose={handleClose}
                reset={reset}
                apply={apply}
                isFilterApplied={isFilterApplied}
                filterData={filterData}
                setFilterData={setFilterData}
            />

            <AddAmountModal
                open={openAddAmount}
                setOpen={setOpenAddAmount}
                fetchTransActionsList={fetchTransActionsList}
            />

        </MainSection>
    )
}

export default PlanFilters