import * as React from 'react';

import { Box, Skeleton } from '@mui/material';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

import { styled } from '@mui/material/styles';
// import ActionMenu from './actionMenu';
import { copyToClipBoard, dateFormate, nameFormater } from '../../../utils/common';
import { renderSuccessMessage } from '../../../lib/toastMessage';

const CustomTableCell = styled(TableCell)(({ theme }) => ({
    backgroundColor: theme.palette.grey[1000],
    color: theme.palette.grey[300],
    '&:hover': {
        color: theme.palette.grey[200],
    }
}));

const columns = [
    // { id: 'email', label: 'Email', minWidth: 100 },
    { id: 'orderId', label: 'Order Id', minWidth: 100 },
    { id: 'paymentId', label: 'Payment Id', minWidth: 100 },
    { id: 'amount', label: 'Amount', minWidth: 100 },
    { id: 'currency', label: 'Currency', minWidth: 100, align: 'center' },
    { id: 'status', label: 'Status', minWidth: 100, align: 'center' },
    { id: 'method', label: 'Method', minWidth: 100, align: 'center' },
    { id: 'createdAt', label: 'Created At', minWidth: 100, align: 'center' },
];

export default function TransActionsTable({ usersList = [], count, isListLoading, page, setPage, rowsPerPage, setRowsPerPage, setLoad }) {

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setLoad(true);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
        setLoad(true);
    };

    const statusChip = (id, align, status) => {
        return (
            <Box sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                gap: '7px',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                {/* {status && <Box sx={{
                    width: '7px',
                    height: '7px',
                    backgroundColor: status === 'captured' ? "success.main" : status === 'pending' ? 'error.main' : 'error.main',
                    borderRadius: '50%'
                }}></Box>} */}

                <Typography>
                    {nameFormater(status)}
                </Typography>
            </Box>
        )
    }

    const methodChip = (id, align, value) => {
        return (
            <Box sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                gap: '7px',
                alignItems: 'center',
                justifyContent: 'center'
            }}>

                <Typography>
                    {nameFormater(value)}
                </Typography>
            </Box>
        )
    }

    const emailChip = (id, align, value) => {
        return (
            value ? <Tooltip title={"Check to copy"} componentsProps={{
                tooltip: {
                    sx: {
                        bgcolor: 'grey.300',
                    },
                },
            }}>
                <Typography variant='inherit' onClick={() => {
                    copyToClipBoard(value);
                    renderSuccessMessage('Email copied!')
                }}>
                    {value}
                </Typography>
            </Tooltip>
                : "--"
        )
    }

    const createdAtChip = (id, align, value) => {
        const { date, time } = dateFormate(value);

        return (
            date + ", " + time
        )
        // nameFormater(value)
    }

    const defaultRowJsx = (id, align, value) => (
        value !== undefined ? value : "--"
    )

    const tableRowData = {
        email: emailChip,
        createdAt: createdAtChip,
        status: statusChip,
        method: methodChip
    }

    return (

        <Paper sx={{
            width: '100%',
            overflow: 'hidden',
            borderRadius: '0px',
            gap: '20px',
            display: 'flex',
            flexDirection: 'column'
        }}>

            <TableContainer sx={{
                maxHeight: window.innerHeight - 225//'calc(100% - 200px)' //684 
            }}>
                <Table stickyHeader aria-label="sticky table" size='small'>
                    <TableHead sx={{
                        backgroundColor: 'grey.1000',
                        borderTop: '1px solid',
                        borderBottom: '1px solid',
                        borderColor: 'grey.900'
                    }}>
                        <TableRow>
                            {columns.map((column) => (
                                <CustomTableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </CustomTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!isListLoading && !!usersList.length && usersList
                            .map((row, index) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                        {columns.map((column) => {
                                            const id = column.id;
                                            const value = row[id];
                                            return (
                                                <TableCell key={id} align={column.align} sx={{ color: 'grey.300', fontWeight: 500 }}>
                                                    {column.format && typeof value === 'number'
                                                        ? column.format(value)
                                                        : (tableRowData?.[id] || defaultRowJsx)(id, column?.align, row[id], row, index)}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}

                        {/* Loading case */}
                        {isListLoading && <>
                            <TableRow hover role="checkbox" tabIndex={-1}>
                                {columns.map((column) => {
                                    const id = column.id;
                                    return (
                                        <TableCell key={id} align={column.align} sx={{ color: 'grey.300', fontWeight: 500 }}>
                                            <Typography component="div" key={"body1"} variant={"body1"} sx={{ maxWidth: '70%' }}>
                                                <Skeleton />
                                            </Typography>
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                            <TableRow hover role="checkbox" tabIndex={-1}>
                                {columns.map((column) => {
                                    const id = column.id;
                                    return (
                                        <TableCell key={id} align={column.align} sx={{ color: 'grey.300', fontWeight: 500 }}>
                                            <Typography component="div" key={"body1"} variant={"body1"} sx={{ maxWidth: '70%' }}>
                                                <Skeleton />
                                            </Typography>
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </>}
                    </TableBody>
                </Table>
            </TableContainer>

            {(!usersList.length && !isListLoading) &&
                <Box sx={{
                    width: '100%',
                    display: "flex",
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: '200px'
                }}>
                    <Typography>
                        No more data found!
                    </Typography>
                </Box>
            }

            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                SelectProps={{
                    MenuProps: {
                        sx: {
                            '.MuiPaper-root': {
                                color: 'grey.200',
                            },
                        },
                    },
                }}
                sx={{
                    color: 'grey.200',
                    borderTop: '0.5px solid',
                    borderColor: 'grey.900'
                }}
            />

            {/*  <ActionMenu
                anchorEl={anchorEl}
                open={open}
                handleClose={handleCloseActions}
                data={actionData}
                fetchUsersList={fetchUsersList}
            /> */}

        </Paper>
    );
}
