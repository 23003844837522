// @mui
import { styled } from '@mui/material/styles';
import { Typography, IconButton } from '@mui/material';

import SettingsIcon from '@mui/icons-material/Settings';
import HeadingTabPanel from '../sections/settings/headingTabPanel';
import { ToastContainer } from 'react-toastify';

const MainSection = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  flexDirection: 'column',
  gap: '14px',
  padding: '76px 12px 12px 70px',
  height: '100%',
  overflowY: 'scroll',
  [theme.breakpoints.down('sm')]: {
    padding: '70px 14px 14px 14px',
    height: 'auto',
  },
}));

const Card = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  // padding: '16px 19px',
  borderRadius: "6px",
  border: '1px solid',
  backgroundColor: theme.palette.card.main,
  borderColor: theme.palette.grey[1000],
  width: '100%',
  overflow: 'hidden',
  overflowY: 'scroll',
  scrollbarWidth: 'none',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    // padding: '16px 19px',
  },
}));

const HeadingSection = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '15px 10px 0px 10px'
}));

const HeadingTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[100],
  fontSize: '20px',
  fontWeight: 600
}));

const HeadingIcon = styled(IconButton)(({ theme }) => ({
  color: theme.palette.grey[100],
}));

const TabPanelSection = styled('div')(({ theme }) => ({
  height: '100%'
}));


const Settings = () => {
  return (
    <MainSection>
      <ToastContainer />

      <Card>
        <HeadingSection>

          <HeadingIcon disableFocusRipple disableRipple disableTouchRipple>
            <SettingsIcon />
          </HeadingIcon>

          <HeadingTypography>
            Settings
          </HeadingTypography>

        </HeadingSection>

        <TabPanelSection>
          <HeadingTabPanel />
          {/* 
          <TabPanelListSection>
            <SettingsTabPanel />
          </TabPanelListSection> */}

        </TabPanelSection>

      </Card>
    </MainSection>
  )
}

export default Settings