// react
import React, { useState, useRef } from 'react';

//@mui
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

//@mui icons
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

// components
import KycInput from '../../components/Dashboard/KycInput/KycInput';
import KycFileInput from '../../components/Dashboard/KycInput/KycFileInput';

// kyc common validator function
import { validateAadharNumber, validatePanNumber, validateUploadFile } from '../../utils/kycCommon';

import ErrorMessage from "../../utils/ErrorMessage.json"
import { renderErrorMessage, renderSuccessMessage } from '../../lib/toastMessage';
import PropTypes from 'prop-types';
import ShowNote from './showNote';
import Logger from '../../lib/Logger';
import { axiosPostCall } from '../../services/apisCall';
import VerifyOtpModal from './verifyOtpModal';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
const logger = new Logger("upload Documents");

const MainSection = styled('div')(({ theme }) => ({
    display: 'flex',
    gap: '20px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '50%',
    [theme.breakpoints.down('sm')]: {
        width: '100%',
    },
}));

const FormRowSection = styled('div')(({ theme }) => ({
    display: 'flex',
    gap: '10px',
    flexDirection: 'row',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    },
}));

const UploadDocuments = ({ current, next, formData }) => {

    const panRef = useRef();
    const aadharRef = useRef()
    const [aadharNumber, setAadharNumber] = useState("")
    const [panNumber, setPanNumber] = useState("")
    const [aadharFile, setAadharFile] = useState(null)
    const [panFile, setPanFile] = useState(null)
    const [clientId, setClientId] = useState("");
    const [otp, setOtp] = useState("");
    const [verifyOtp, setVerifyOtp] = useState(false);
    const [loader, setLoader] = useState(false);

    const [aadharError, setAadharError] = useState(false)
    const [panError, setPanError] = useState(false);

    // const InActiveNextBtn = {
    //     backgroundColor: '#727374',
    //     color: "#B8B9BA",
    //     '&:hover': {
    //         backgroundColor: '#727374',
    //         color: "#B8B9BA",
    //     }
    // }

    const handleSubmit = async () => {
        try {
            setLoader(true);
            await handleSendOtp();
            setLoader(false);
        } catch (error) {
            setLoader(false);
            logger.error(`API Error -  ${JSON.stringify(error)}`);
        }
    }

    const handleSendOtp = async (resend = false, handleTimer, handleResend, form) => {
        try {
            if (!aadharNumber) {
                return renderErrorMessage("Please enter the aadhaar Number");
            }

            setLoader(true)

            const { clientId } = await axiosPostCall("generateOtp", { aadhaarNumber: aadharNumber });
            setClientId(clientId);
            setLoader(false);
            
            if (resend) {
                handleTimer(60);
                handleResend(true);
                return;
                }
                renderSuccessMessage("OTP has been sent to the mobile number registered with your Aadhaar.")
                setVerifyOtp(true);
                return;
        } catch (error) {
            logger.error(error.message)
            setLoader(false);
            renderErrorMessage(error.message)
        }
    }

    const handleVerifyOtp = async (handleLoader) => {
        try {
            if (!clientId) {
                return renderErrorMessage(ErrorMessage?.[4001]);
            }

            const files = [aadharFile, panFile]
            const form = new FormData();

            let fileError = false
            files.filter((file) => file !== undefined).forEach(file => {
                if (!validateUploadFile(file)) {
                    fileError = true
                }
            });
            if (fileError) return renderErrorMessage(ErrorMessage?.[4013])

            if (!validatePanNumber(panNumber)) {
                setPanError(true)
                return renderErrorMessage(ErrorMessage?.[4014])
            }

            if (!validateAadharNumber(aadharNumber)) {
                setAadharError(true)
                return renderErrorMessage(ErrorMessage?.[4015])
            }

            form.append("aadharNumber", aadharNumber)
            form.append("panNumber", panNumber)
            form.append("aadhar", aadharFile)
            form.append("pancard", panFile)
            form.append("crfForm", panFile)
            form.append("currentStep", "uploadDoc");
            form.append("nextStep", "address");

            const payload = {
                clientId,
                otp,
            }
            handleLoader(true);
            const { message } = await axiosPostCall("verifyOtp", payload);
            await axiosPostCall("uploadKyc", form);
            handleLoader(false);
            setVerifyOtp(false);
            current(false);
            next(true);
            return renderSuccessMessage(message);
        } catch (error) {
            logger.error(error.message)
            handleLoader(false);
        }
    }
    return (
        <>
            {verifyOtp && <VerifyOtpModal
                open={verifyOtp}
                handleClose={setVerifyOtp}
                otp={otp}
                handleOtpChange={setOtp}
                handleSendOtp={handleSendOtp}
                handleVerifyOtp={handleVerifyOtp}
            />}
            <MainSection >
                <Typography sx={{
                    color: 'grey.100',
                    fontSize: '32px',
                    fontWeight: 600,
                    lineHeight: '40px'
                }}>
                    Upload Documents
                </Typography>

                <FormRowSection>
                    <KycInput
                        inputName="PAN number"
                        inputId="CompanyPanNumber"
                        inputPlaceholder="Enter PAN number"
                        value={panNumber}
                        setValue={setPanNumber}
                        error={panError}
                        setError={setPanError}
                    />

                    <KycFileInput
                        InputName="Upload PAN card"
                        inputId="companyPanNumber"
                        inputRef={panRef}
                        setFile={setPanFile}
                    />
                </FormRowSection>

                <FormRowSection>
                    <KycInput
                        inputName="Aadhar Number"
                        inputId="AadharNumber"
                        inputPlaceholder="Enter Aadhar number"
                        value={aadharNumber}
                        setValue={setAadharNumber}
                        error={aadharError}
                        setError={setAadharError}
                    />

                    <KycFileInput
                        InputName="Upload Aadhar Card card"
                        inputId="Aadharcard"
                        inputRef={aadharRef}
                        setFile={setAadharFile}
                    />

                </FormRowSection>

                <ShowNote />


                <LoadingButton
                    variant='contained'
                    endIcon={<ArrowForwardIcon />}
                    loading={loader}
                    onClick={(e) => handleSubmit()}
                    disabled={!panFile || !aadharFile || !panNumber || !aadharNumber}
                    sx={{
                        // "&.Mui-disabled": InActiveNextBtn['&:hover'],
                        boxShadow: 'none'
                    }}
                >
                    Save & Next
                </LoadingButton>
            </MainSection>
        </>
    )
}

UploadDocuments.propTypes = {
    current: PropTypes.func.isRequired,
    next: PropTypes.func.isRequired,
    formData: PropTypes.object.isRequired,
}

export default UploadDocuments;
