import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { imagesUrl } from '../../config/commonConfig';
function LoadingModal({ open }) {
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          outline: 'none',
          zIndex: '9999',
        }}
      >
        <img src={imagesUrl?.loading} alt="Loading..." />
      </Box>
    </Modal>
  );
}
export default LoadingModal;
