import { Box } from '@mui/material';
import React from 'react';
import { SpinnerSection } from '../sections/spinner';
import { Helmet } from 'react-helmet';

const Spinner = () => {
    return (
        <>
            <Helmet>
                <style>{'body { background-color: transparent; }'}</style>
            </Helmet>
            
            <Box sx={{ backgroundColor: 'transparent' }}>

                <SpinnerSection />
            </Box>
        </>
    )
}

export default Spinner;
