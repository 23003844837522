import { TextField, Box, Typography, Stack, Select, MenuItem, InputLabel, FormControl, Button, Tooltip } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { axiosVBGetCall, axiosVBPatchCall, axiosVBPostCall } from '../../../services/vbApiCall';
import { renderErrorMessage, renderSuccessMessage } from '../../../lib/toastMessage';
import dayjs from 'dayjs';
import { LoadingButton } from '@mui/lab';
import Logger from '../../../lib/Logger';
import { copyToClipBoard } from '../../../utils/common';
const logger = new Logger("asetting")

const BasicDatePicker = ({ value, onChange, dateError, resetError, label }) => {
    const [/* open */, setOpen] = useState(false);
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} >
            <TimePicker views={['hours']}
                label={label}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                slotProps={{
                    textField: {
                        size: 'small',
                        error: Boolean(dateError),
                        helperText: Boolean(dateError) && dateError,
                        onClick: () => resetError()
                    },
                    actionBar: {
                        actions: ['today', 'clear'],
                    },
                }}
                ampm={false}
                // sx={{ width: "100%", margin: "8px" }}
                value={value} onChange={(newValue) => onChange(newValue)}

            />
        </LocalizationProvider>
    )
}


const requestList = [
    { label: "Self", value: "U" },
    { label: "Downline", value: "D" },
    { label: "Hierarchy", value: "H" }
]

const dndStatusList = [
    { label: "On", value: "1" },
    { label: "Off", value: "0" }
]



const BroadcastSettings = ({ user }) => {

    const inputSx = {
        color: "grey.200",
        borderColor: 'grey.700',
        borderRadius: "6px",
    };

    const [anouncementUploadLimit, setAnouncementUploadLimit] = useState(0);
    const [callLimit, setCallLimit] = useState(0);
    const [callWindowRequestFor, setCallWindowRequestFor] = useState("U");
    const [callLimitRequestFor, setCallLimitRequestFor] = useState("U");
    const [uploadLimitRequestFor, setUpdateLimitRequestFor] = useState("U");
    const [dndStatusRequestFor, setDndStatusRequestFor] = useState("U");
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [startDateError, setStartDateError] = useState("");
    const [endDateError, setEndDateError] = useState("");
    const [dndStatus, setDndStatus] = useState("0");

    const [userToken, setUserToken] = useState("");
    const [callWindowloader, setCallWindowLoader] = useState(false);
    const [callLimitLoader, setCallLimitLoader] = useState(false);
    const [announcementLimitLoader, setAnnouncementLoader] = useState(false);
    const [dndLoader, setDndLoader] = useState(false);


    const updateCallLimit = async () => {
        try {
            setCallWindowLoader(true);
            const payload = {
                userId: user?.userId,
                action: "updateCallLimits",
                callLimit,
                requestFor: "U",
            }
            if (user?.userType === "reseller") {
                payload.requestFor = callLimitRequestFor;
            }
            await axiosVBPatchCall("updateProfileSetting", payload);
            setCallWindowLoader(false);

            renderSuccessMessage("Success");
        } catch (error) {
            logger.error(error)
            setCallWindowLoader(false);
            renderErrorMessage(error.message);
        }
    }

    const updateAnnouncementUploadLimit = async () => {
        try {
            setAnnouncementLoader(true);
            const payload = {
                userId: user?.userId,
                action: "updateAnnouncementUploadLimit",
                announcementLimit: parseInt(anouncementUploadLimit),
                requestFor: "U",
            }
            if (user?.userType === "reseller") {
                payload.requestFor = uploadLimitRequestFor;
            }
            await axiosVBPatchCall("updateProfileSetting", payload);
            setAnnouncementLoader(false);
            renderSuccessMessage("Success");
        } catch (error) {
            logger.error(error)
            setAnnouncementLoader(false);
            renderErrorMessage(error.message)
        }
    }

    const updateDndStatus = async () => {
        try {
            setDndLoader(true);
            const payload = {
                userId: user?.userId,
                DNDFilterStatus: dndStatus,
                action: "changeUserDNDFilterStatus",
                requestFor: "U",
            };

            if (user?.userType === "reseller") {
                payload.requestFor = dndStatusRequestFor;
            }
            await axiosVBPatchCall("updateProfileSetting", payload);
            setDndLoader(false);
            renderSuccessMessage("Success");
        } catch (error) {
            logger.error(error);
            setDndLoader(false);
            renderErrorMessage(error.message);
        }
    }

    const updateACallWindow = async () => {
        try {
            
            if (!startTime) {
                return setStartDateError("Start date is required");
            }
            if (!endTime) {
                return setEndDateError("End date is required")
            }
            const startHour = new Date(startTime).getHours();
            const endHour = new Date(endTime).getHours();

            if (startHour > endHour) {
                return setEndDateError("End date must be greater than the Start date")
            }
            const payload = {
                userId: user?.userId,
                action: "updateCallWindow",
                callWindowStart: startHour,
                callWindowEnd: endHour,
                requestFor: "U",
            }
            if (user?.userType === "reseller") {
                payload.requestFor = callWindowRequestFor;
            }
            setCallWindowLoader(true);
            await axiosVBPatchCall("updateProfileSetting", payload);
            setCallWindowLoader(false);
            renderSuccessMessage("Success");
        }
        catch (error) {
            logger.error(error);
            setCallWindowLoader(false);
            renderErrorMessage(error.message)
        }
    }

    const handleSubmit = async (type) => {
        if(type === "callLimit") {
            await updateCallLimit();
        }

        if (type === "announcementLimit") {
          await updateAnnouncementUploadLimit();
        }

        if (type === "callWindow") {
          await updateACallWindow();
        }

        if (type === "dndStatus") {
          await updateDndStatus();
        }
    }

    const resetStartTimeError = useMemo(() => {
        return () => {
            setStartDateError("");
        };
        // eslint-disable-next-line
    }, [startTime]);

    const resetEndTimeError = useMemo(() => {
        return () => {
            setEndDateError("")
        }
        // eslint-disable-next-line
    }, [endTime])

    const fetchSettingDetails = async () => {
        try {
            const params = { requestUserId: user?.userId, requestName: "all" }
            const { data = {} } = await axiosVBGetCall("getUserSettingsInfo", { ...params });
            const { token = "" } = await axiosVBPostCall("tokenGenerate", { userId: user?.userId });
            const {
                data: { DNDFilterStatus = "0" },
            } = await axiosVBGetCall("getUserSettingsInfo", {
                requestName: "DNDFilterStatus",
                requestUserId: user?.userId,
            });
            setDndStatus(DNDFilterStatus)
            setUserToken(token);
            const { dailyCallLimit = 0, callWindowStartTime = 0, callWindowEndTime = 0, announcementUploadLimit = 0 } = data;

            const startTime = dayjs().hour(callWindowStartTime).minute(0).second(0).millisecond(0);
            const endTime = dayjs().hour(callWindowEndTime).minute(0).second(0).millisecond(0);

            setCallLimit(parseInt(dailyCallLimit));
            setAnouncementUploadLimit(parseInt(announcementUploadLimit));

            setStartTime(startTime);
            setEndTime(endTime);
        } catch (error) {
            logger.error(error);
        }
    }

    useEffect(() => {
        fetchSettingDetails();
        // eslint-disable-next-line
    }, [])

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          mt: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "6px",
            mb: "15px",
          }}
        >
          <Typography>Call Window</Typography>

          {/* <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} gap={1}> */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "16px",
            }}
          >
            <BasicDatePicker
              label={"Start Time"}
              value={startTime}
              onChange={setStartTime}
              dateError={startDateError}
              resetError={resetStartTimeError}
            />
            <BasicDatePicker
              label={"End Time"}
              value={endTime}
              onChange={setEndTime}
              dateError={endDateError}
              resetError={resetEndTimeError}
            />
            {user?.userType !== "reseller" && (<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <LoadingButton
                loading={callWindowloader}
                variant="contained"
                sx={{ width: "100px" }}
                onClick={() => handleSubmit("callWindow")}
              >
                Submit
              </LoadingButton>
            </Box>)}
          </Box>
          {/* 
          </Stack> */}
          <Stack width={"100%"} direction={"row"} alignItems={"center"}>
            {user?.userType === "reseller" && (
              <Stack
                gap={2}
                direction={"row"}
                width={"100%"}
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "10px",
                }}
              >
                {/* <Typography sx={{ width: "25%" }}>Request For</Typography> */}
                <FormControl fullWidth>
                  <InputLabel id="request-select">Request For</InputLabel>
                  <Select
                    labelId="request-select"
                    id="request-select"
                    fullWidth
                    value={callWindowRequestFor || ""}
                    onChange={(e) => setCallWindowRequestFor(e?.target?.value)}
                    label="Request For"
                    size="small"
                    displayEmpty
                    sx={{
                      width: "50%",
                    }}
                  >
                    {requestList.map(({ label, value }) => (
                      <MenuItem key={label} value={value}>
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
            )}
            {user?.userType === "reseller" && (
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <LoadingButton
                  loading={callWindowloader}
                  variant="contained"
                  sx={{ width: "100px" }}
                  onClick={() => handleSubmit("callWindow")}
                >
                  Submit
                </LoadingButton>
              </Box>
            )}
          </Stack>
        </Box>

        <Box
          sx={{
            display: "flex",
            gap: "6px",
            mb: "15px",
          }}
        >
          <TextField
            fullWidth
            id="outlined-basic"
            label="Enter Call Limit"
            variant="outlined"
            InputProps={{
              sx: inputSx,
            }}
            value={callLimit}
            onChange={(e) => setCallLimit(e.target.value)}
            size="small"
          />
          {user?.userType === "reseller" && (
            <Stack
              gap={2}
              direction={"row"}
              sx={{
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              {/* <Typography sx={{ width: "25%" }}>Request For</Typography> */}
              <FormControl fullWidth>
                <InputLabel id="request-select">Request For</InputLabel>
                <Select
                  labelId="request-select"
                  id="request-select"
                  value={callLimitRequestFor || ""}
                  onChange={(e) => setCallLimitRequestFor(e?.target?.value)}
                  label="Request For"
                  size="small"
                  displayEmpty
                >
                  {requestList.map(({ label, value }) => (
                    <MenuItem key={label} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          )}
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <LoadingButton
              loading={callLimitLoader}
              variant="contained"
              sx={{ width: "100px" }}
              onClick={() => handleSubmit("callLimit")}
            >
              Submit
            </LoadingButton>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            // flexDirection: 'column',
            gap: "6px",
            mb: "15px",
          }}
        >
          <TextField
            fullWidth
            id="outlined-basic"
            label="Announcement Upload Limit"
            variant="outlined"
            InputProps={{
              sx: inputSx,
            }}
            value={anouncementUploadLimit}
            onChange={(e) => setAnouncementUploadLimit(e.target.value)}
            size="small"
          />
          {user?.userType === "reseller" && (
            <Stack
              gap={2}
              direction={"row"}
              sx={{
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              {/* <Typography sx={{ width: "25%" }}>Request For</Typography> */}
              <FormControl fullWidth>
                <InputLabel id="request-select">Request For</InputLabel>
                <Select
                  labelId="request-select"
                  id="request-select"
                  value={uploadLimitRequestFor || ""}
                  onChange={(e) => setUpdateLimitRequestFor(e?.target?.value)}
                  label="Request For"
                  size="small"
                  displayEmpty
                >
                  {requestList.map(({ label, value }) => (
                    <MenuItem key={label} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          )}
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <LoadingButton
              loading={announcementLimitLoader}
              variant="contained"
              sx={{ width: "100px" }}
              onClick={() => handleSubmit("announcementLimit")}
            >
              Submit
            </LoadingButton>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            gap: "6px",
            mb: "15px",
          }}
        >
          <FormControl fullWidth>
            <InputLabel id="request-select">Dnd Status</InputLabel>
            <Select
              labelId="request-select"
              id="request-select"
              value={dndStatus || ""}
              onChange={(e) => setDndStatus(e?.target?.value)}
              label="Dnd Status"
              size="small"
              displayEmpty
            >
              {dndStatusList.map(({ label, value }) => (
                <MenuItem key={label} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {user?.userType === "reseller" && (
            <Stack
              gap={2}
              direction={"row"}
              sx={{
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              {/* <Typography sx={{ width: "25%" }}>Request For</Typography> */}
              <FormControl fullWidth>
                <InputLabel id="request-select">Request For</InputLabel>
                <Select
                  labelId="request-select"
                  id="request-select"
                  value={dndStatusRequestFor || ""}
                  onChange={(e) => setDndStatusRequestFor(e?.target?.value)}
                  label="Request For"
                  size="small"
                  displayEmpty
                >
                  {requestList.map(({ label, value }) => (
                    <MenuItem key={label} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          )}
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <LoadingButton
              loading={dndLoader}
              variant="contained"
              sx={{ width: "100px" }}
              onClick={() => handleSubmit("dndStatus")}
            >
              Submit
            </LoadingButton>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            // flexDirection: 'column',
            gap: "6px",
            mb: "15px",
            alignItems: "center",
          }}
        >
          <Typography variant="subtitle1" fontWeight="bold">
            User Token :
          </Typography>
          <Tooltip title="Click here to copy">
            <Typography
              variant="body1"
              color="text.secondary"
              onClick={() => {
                copyToClipBoard(userToken);
                renderSuccessMessage("Token Copied");
              }}
            >
              {userToken}
            </Typography>
          </Tooltip>
        </Box>

        {/* <Box sx={{ display: "flex", justifyContent: "flex-end", mt: "10px" }}>
                <LoadingButton
                    loading={loader}
                    variant="contained"
                    sx={{ width: "100px" }}
                    onClick={() => handleSubmit()}
                >
                    Submit
                </LoadingButton>
            </Box> */}
      </Box>
    );
}

export default BroadcastSettings