import React from "react";
import { Stack, Typography } from "@mui/material";
import { imagesUrl } from "../../config/commonConfig";

const Submition = ()=> {
    return(
        <Stack gap={2} direction={"column"} sx={{justifyContent: "center", alignItems: "center"}}>
            <img src={imagesUrl?.completion} width={"250px"} alt="check"/>
            <Stack sx={{justifyContent: "center", alignItems: "center"}}>
            <Typography variant="h3" sx={{color: "#282828"}}>Thank you!</Typography>
            <Typography variant="body2" sx={{color: "#282828"}}>Your submission has been sent</Typography>
            </Stack>
        </Stack>
    )
}

export default Submition;