import React, { useContext, useEffect, useState } from 'react';

// @mui
import { styled } from '@mui/material/styles';
import { Typography, AvatarGroup, Avatar, IconButton, Tooltip, useTheme } from '@mui/material';
import HiSvg from '../../assets/svgs/hiSvg';
import DocumnetFileCopyIcon from '../../assets/svgs/documnetFileCopyIcon';
import Logger from '../../lib/Logger';

import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { axiosPostCall } from '../../services/apisCall';
import { getItem } from '../../lib/cookies';
import { CustomSkeleton } from '../skeleton/skeletonLoader';
import jwtDecode from 'jwt-decode';
import { handleRedirect, nameFormater } from '../../utils/common';
import LoadingButton from '@mui/lab/LoadingButton';
import ErrorMessage from "../../utils/ErrorMessage.json"
import { BrandingContext } from '../../context/brandingContext';
const logger = new Logger("meetOpenRoomSection")

const CardsMainSection = styled('div')(({ theme }) => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    padding: '16px 19px',
    borderRadius: "6px",
    border: '1px solid',
    backgroundColor: theme.palette.card.main,
    borderColor: theme.palette.grey[1000],
    width: 'calc(100% / 1.6)',
    [theme.breakpoints.down('md')]: {
        width: '100%',
        padding: '16px 19px',
    },
}));

const CardsLeftSection = styled('div')(({ theme }) => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '19px',
    width: 'calc(100% / 1.3)',
    [theme.breakpoints.down('sm')]: {
        width: '100%',
    },
}));

const CardsRightSection = styled('div')(({ theme }) => ({
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'calc(100% - 100% / 1.3)',
    flexDirection: 'column',
    gap: '6px',
    [theme.breakpoints.down('sm')]: {
        display: 'none'
    },
}));

const CardsRightBottomSection = styled('div')(({ theme }) => ({
    width: '40%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexDirection: 'row',
    gap: '6px',
    [theme.breakpoints.up('sm')]: {
        display: 'none'
    },
}));

const HeadingSection = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    gap: '4px',
    // marginLeft: '-24px'
}))

const Heading = styled(Typography)(({ theme }) => ({
    color: theme.palette.grey[100],
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '22px'
}))

const SubHeading = styled(Typography)(({ theme }) => ({
    color: theme.palette.grey[400],
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '18px'
}))

const InviteSection = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    },
}))

const InviteUrl = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: '50px',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '20px',
    border: '1px solid',
    borderColor: theme.palette.grey[800],
    padding: '2px 14px',
    [theme.breakpoints.down('sm')]: {
        gap: '10px',
    },
}))

const InviteUrlTitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.grey[600],
    fontSize: '15px',
    fontWeight: 400,
    lineHeight: '13px'
}));

const CustomBuuton = styled(LoadingButton)(() => ({
    boxShadow: 'none',
    borderRadius: '23px'
}));

const LockIconButton = styled(IconButton)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "light" ? theme.palette.button.dark : theme.palette.grey[700],
    color: theme.palette.mode === "light" ? theme.palette.grey[900] : theme.palette.grey[100],
    '&:hover': {
        backgroundColor: theme.palette.mode === "light" ? theme.palette.button.dark : theme.palette.grey[700],
    }
}));

const JoinTitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.grey[400],
    fontSize: '16px',
    fontWeight: 500,
}));

const InviteActionsBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: '5px',
}));

const MeetOpenRoomSection = ({ attendeeCount, attendees, joinUrl, roomId, visibility, setVisibility, loader }) => {
    const [userName, setUserName] = useState("");
    const [isCopied, setIsCopied] = useState(false);
    const [timer, setTimer] = useState(null);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [loadingVisibility, setLoadingVisibility] = useState(false);
    const theme = useTheme();
    const { companyBrandName = "" } = useContext(BrandingContext)

    const handleVisibility = async () => {
        try {
            const payload = {
                methods: ['meet'],
                meet: {
                    roomId,
                    public: !visibility
                }
            }
            setLoadingVisibility(true);
            await axiosPostCall("consoleDetails", payload);
            setLoadingVisibility(false);
            setVisibility((prev) => !prev);
        }
        catch (error) {
            setLoadingVisibility(false);
            logger.error("meetOpenRoomSection fn: [handleVisibility] api:[consoleDetails] post catchError", error)
        }
    }

    const handleJoin = async () => {
        try {
            setLoadingBtn(true);
            await handleRedirect("meet", "custom_session", {}, true, theme.palette.mode, "join", roomId);
            setLoadingBtn(false);
        }
        catch (error) {
            setLoadingBtn(false);
            logger.error("meetOpenRoomSection fn: [handleJoin] api:[shareSession] post catchError", error);
        }
    }

    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(joinUrl);
            setIsCopied(true);

            if (timer) clearTimeout(timer);

            const timerId = setTimeout(() => {
                setIsCopied(false);
            }, 10000);

            setTimer(timerId);
        } catch (error) {
            setIsCopied(false);
            logger.error('meetOpenRoomSection fn: [handleVisibility] Unable to copy to clipboard', error)
        }
    };

    useEffect(() => {
        try {
            const { name = "User" } = jwtDecode(getItem("token"));
            setUserName(name);
        } catch (error) {
            logger.error("meetOpenRoomSection.jsx useEffect jwtDecode", error)
        }
    }, []);

    return (
        <CardsMainSection >
            <CardsLeftSection>

                <HeadingSection>
                    <HiSvg />

                    <Heading>
                        Hi {nameFormater(userName)}, Welcome to the {companyBrandName} console
                    </Heading>
                </HeadingSection>

                <SubHeading>
                    Your always on meeting room
                </SubHeading>

                <InviteSection>
                    {!loader ?
                        <InviteUrl>
                            <InviteUrlTitle>
                                {joinUrl ? (joinUrl.length > 25 ? joinUrl.slice(0, 25) + " ..." : joinUrl) : ErrorMessage?.[4000]}
                            </InviteUrlTitle>

                            <Tooltip
                                open={isCopied}
                                onOpen={() => {
                                    setIsCopied(true);
                                }}
                                onClose={() => {
                                    setIsCopied(false);
                                }}

                                title="Copied to clipboard!"
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                arrow
                            >
                                <IconButton
                                    onClick={() => copyToClipboard()}
                                    size="small"
                                    style={{
                                        cursor: "pointer",
                                    }}
                                >
                                    <DocumnetFileCopyIcon colour={isCopied && theme.palette.success.main} />
                                </IconButton>
                            </Tooltip>
                        </InviteUrl>
                        :
                        <CustomSkeleton variant={"rounded"} height={"40px"} width={"300px"} />
                    }
                    <InviteActionsBox>

                        <CustomBuuton
                            variant='contained'
                            onClick={() => handleJoin()}
                            loading={loadingBtn}
                            sx={{
                                backgroundColor: 'primary.main',
                                '&:hover': {
                                    backgroundColor: 'primary.main',
                                }
                            }}
                        >
                            Join Room
                        </CustomBuuton>

                        <LockIconButton disableTouchRipple disabled={loadingVisibility} onClick={() => handleVisibility()}>
                            {visibility ? <LockOpenIcon /> : < LockIcon />}
                        </LockIconButton>

                        <CardsRightBottomSection>
                            {!loader ? attendees.length > 0 ? <><JoinTitle sx={{ fontSize: '14px' }}>
                                Already joined
                            </JoinTitle>

                                <AvatarGroup
                                    max={1}

                                    total={attendeeCount || 0}
                                    sx={{
                                        '&.MuiAvatarGroup-root .MuiAvatar-root': {
                                            border: '1px solid',
                                            borderColor: 'grey.700',
                                            color: "grey.400",
                                            backgroundColor: 'grey.800',
                                            width: '24px',
                                            height: '24px',
                                            fontSize: '12px',
                                        }
                                    }}
                                >
                                    {
                                        attendees.length > 0 && attendees.slice(0, 3).map(({ displayName }, index) => (
                                            <Avatar key={index} alt={displayName} src="/static/images/avatar/1.jpg" />
                                        ))
                                    }
                                </AvatarGroup> </> : <JoinTitle sx={{ fontSize: '12px' }}>
                                No one joined yet!
                            </JoinTitle> :
                                <CustomSkeleton variant={"circular"} width={40} height={40} />}

                        </CardsRightBottomSection>
                    </InviteActionsBox>

                </InviteSection>
            </CardsLeftSection>

            <CardsRightSection>
                {!loader ? attendees.length > 0 ? <><JoinTitle>
                    Already joined
                </JoinTitle>

                    <AvatarGroup
                        max={3}
                        total={attendeeCount || 0}
                        sx={{
                            '&.MuiAvatarGroup-root .MuiAvatar-root': {
                                border: '1px solid',
                                borderColor: 'grey.700',
                                color: "grey.400",
                                backgroundColor: 'grey.800'
                            }
                        }}
                    >
                        {attendees.slice(0, 3).map(({ displayName }) => (
                            <Avatar alt={displayName} src="/static/images/avatar/1.jpg" />
                        ))}
                    </AvatarGroup> </> : <JoinTitle sx={{ fontSize: '12px' }}>
                    No one joined yet!
                </JoinTitle> :
                    <CustomSkeleton variant={"circular"} width={60} height={60} />
                }
            </CardsRightSection>
        </CardsMainSection>
    )
}

export default MeetOpenRoomSection;
