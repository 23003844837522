import React, { useEffect, useState, useContext } from "react";
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import parse from "url-parse";
import { configFile } from '../../../config';
import { ToastContainer } from "react-toastify";


// Mui
import {
  Box,
  Typography,
  Link,
  TextField,
  InputAdornment,
  IconButton,
  Divider,
  styled,
  useTheme,
  FormControl,
  Stack,
  Alert
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";

// Icons
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

import { PLATFORM_MAPPER } from "../../../config/commonConfig";
import AuthFooter from "../../../layouts/AuthFooter";
import { axiosPostCall } from "../../../services/apisCall";
import ErrorMessage from '../../../utils/ErrorMessage.json';
import { validateEmail, setLoginSession, validateDomain } from "../../../utils/common";
import Logger from "../../../lib/Logger";
import { getBulkItems, getItem, removeItem, setItem } from "../../../lib/cookies";
import { handleRedirect } from "../../../utils/common";
import { renderErrorMessage, renderSuccessMessage } from "../../../lib/toastMessage";
import LogoBlue from "../../../assets/svgs/logo";
import { BrandingContext } from "../../../context/brandingContext";
// const product = currentProduct();
// const { allowedFeatures: { googleSignIn, forgetPassword, signUp } } = await import(`../../../config/${product}/branding`)

const { GOOGLE_AUTH_CLIENT_ID } = configFile;

const HeadingSection = styled('div')(({ theme }) => ({
  width: '450px',
  display: 'flex',
  background: '#FFFFFF',
  padding: '30px 40px',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '10px',
  [theme.breakpoints.down('sm')]: {
    borderRadius: '0px',
    width: 'auto',
    padding: '20px 30px',
  }
}));

const LoginForm = ({ handleLoader }) => {
  const { palette: { mode }, breakpoints } = useTheme();
  const { googleAuth = false, signUp = false } = useContext(BrandingContext) || {};

  const greaterThanMid = useMediaQuery(breakpoints.up("md"));

  const navigate = useNavigate();
  const location = useLocation();
  const logger = new Logger("Login");

  const [warningMessage, setWarningMessage] = useState("")

  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const [successMessage, /*setSuccessMessage */] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false)
  const [sessionToken] = useState(getItem("sessionToken"));
  const [exp] = useState(getItem("exp"))
  const [currentDate] = useState(new Date());
  const url = parse(window.location.href, true);
  let { k = "" } = url.query;

  // Toggel show password.
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  // Manage login section.
  const handleLogin = async (e) => {
    try {
      setWarningMessage("")
      e.preventDefault();
      successMessage && renderSuccessMessage(successMessage)
      // if (!userId) return renderErrorMessage(ErrorMessage?.[4001]);
      if (!password) return renderErrorMessage(ErrorMessage?.[4002]);
      if (userId.includes("@")) {
        if (!validateEmail(userId)) return renderErrorMessage(ErrorMessage?.[4006]);
        if (!validateDomain(userId)) return renderErrorMessage(ErrorMessage?.[4019])
      }
      // else {
      //     if (!validateUserId(userId)) return renderErrorMessage(ErrorMessage?.[4006]);
      //   }

      setLoginLoading(true);

      const payload = { userId, password, origin: window.location.hostname };
      const res = await axiosPostCall("login", payload, true);
      const { exp, sessionToken, token, isMobileVerified = true, isEmailVerified = true, contact, email, warning, message } = res.data;

      if (warning) {
        setLoginLoading(false);
        return setWarningMessage(message)
      }

      if (!isMobileVerified || !isEmailVerified) {
        setLoginLoading(false);
        const state = { authentication: true, userId, contact, email }
        if (!isMobileVerified) state.isMobileVerified = false;
        if (!isEmailVerified) state.isEmailVerified = false;
        if (res.status === 202) state.updateContact = true;
        return navigate("/verification", { state }); 
      }

      if (!exp || !sessionToken || !token) {
        setLoginLoading(false);
        return renderErrorMessage(ErrorMessage?.[4001]);
      }

      setLoginSession(exp, sessionToken, token);
      setLoginLoading(false);
      handleLoader(true);
      return k && PLATFORM_MAPPER[k] ? handleRedirect(PLATFORM_MAPPER[k], "", { sessionToken, exp, token }, true, mode) : window.location.href = "/";

    } catch (error) {
      logger.error(error);
      renderErrorMessage(error.message);
      handleLoader(false)
      return setLoginLoading(false);
    }
  }

  const handleGoogleLogin = async (googleToken) => {
    try {
      const payload = { method: "googleLogin", token: googleToken, origin: window.location.hostname };
      if (k && PLATFORM_MAPPER[k]) payload.key = PLATFORM_MAPPER[k];
      const res = await axiosPostCall("login", payload, true);
      const { exp, sessionToken, token, warning, message, isMobileVerified = true, isEmailVerified = true, contact, email } = res?.data;

      if (warning) {
        return setWarningMessage(message)
      }

      if (!isMobileVerified || !isEmailVerified) {
        setLoginLoading(false);
        const state = { authentication: true, userId: email, contact, email }
        if (!isMobileVerified) state.isMobileVerified = false;
        if (!isEmailVerified) state.isEmailVerified = false;
        if (res.status === 202) state.updateContact = true;
        return navigate("/verification", { state });
      }

      if (!exp || !sessionToken || !token) {
        return renderErrorMessage(ErrorMessage?.[4001]);
      }
      handleLoader(true)
      setLoginSession(exp, sessionToken, token);
      return k && PLATFORM_MAPPER[k] ? handleRedirect(PLATFORM_MAPPER[k], "", { sessionToken, exp, token }, true, mode) : window.location.href = "/";
    } catch (error) {
      logger.error(error);
      handleLoader(false)
      return renderErrorMessage(error.message);
    }
  }

  // Handle forgot passowrd navigation.
  const handleNavigate = (e) => {
    e.preventDefault();
    return navigate("/forget-password");
  }

  useEffect(() => {
    console.log("Before Bulk item");
    const { message = "", time, register = false } = location?.state || {};
    if (register) {
      // eslint-disable-next-line
      k = getItem("origin");
      removeItem("origin");
    }
    if (time && time > Date.now()) {
      renderSuccessMessage(message);
    }

    // const path = whiteListPlatFormChecker(PLATFORM_MAPPER[k])
    // Object.keys(PLATFORM_MAPPER).forEach((key) => {
    //   if (PLATFORM_MAPPER[key] === path) {
    //     // eslint-disable-next-line
    //     k = key
    //   }
    // })

    const [{ sessionToken }, { token }, { exp }] = getBulkItems(["sessionToken", "token", "exp"]);

    if (sessionToken && token && exp && exp > + currentDate) {
      console.log("Inside if condition");
      console.log("k", k);
      k && PLATFORM_MAPPER[k] ? handleRedirect(PLATFORM_MAPPER[k], "", { sessionToken, exp, token }, true, mode) : window.location.href = "/";
    }
    // eslint-disable-next-line
  }, [])

  const navigateRegister = () => {
    if (k) {
      setItem("origin", k);
      return navigate('/register', { state: { k } });
    }
    return navigate('/register');
  }

  const handleFormSubmit = (e) => e.preventDefault()

  useState(() => {
    removeItem("g_state")
  }, [])

  return (
    sessionToken && exp > + currentDate ? <></> :
      <HeadingSection>

        <ToastContainer />

        <Box
          className='FormContent'
          sx={{
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            width: '100%'
          }}
          onSubmit={handleFormSubmit}
        >

          <Box sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
          >
            {/* <img src={imagesUrl?.logoWithName} alt="logoWithName" style={{ height: '50px' }} /> */}
            <Box sx={{
              width: '210px',
              height: '60px'
            }}>
              <LogoBlue isAuth={true} />
            </Box>
          </Box>
          <Box className="FormGroup" sx={{
            display: 'flex',
            gap: '24px',
            flexDirection: 'column',
            width: '100%',
          }}>
            <Box className='FormHeading' sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '6px'
            }}>
              <Typography variant="custom24600" sx={{
                display: 'flex',
                color: '#282828',
                justifyContent: 'center',
                fontSize: '1.5rem'
              }}>
                Welcome back
              </Typography>
            </Box>

            {/* {errorMsg && <MessageBanner message={errorMsg} close={() => setErrorMsg("")} />}
          {successMessage && <MessageBanner
            message={successMessage}
            close={() => setSuccessMessage("")}
            type={"success"}
          />} */}

            {warningMessage && <Stack sx={{ width: '100%' }} spacing={2}>

              <Alert severity="warning">{warningMessage}.</Alert>

            </Stack>}

            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              gap: '16px'
            }}>
              {googleAuth && <>
                <Box sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}>
                  <GoogleOAuthProvider
                    clientId={GOOGLE_AUTH_CLIENT_ID}>
                    <GoogleLogin
                      onSuccess={credentialResponse => {
                        const token = credentialResponse.credential
                        handleGoogleLogin(token)
                      }}
                      onError={() => {
                        return renderErrorMessage(ErrorMessage?.[4006])
                      }}
                      size={"large"}
                      width={greaterThanMid ? '372px' : '290px'}
                      useOneTap
                      cancel_on_tap_outside
                    />
                  </GoogleOAuthProvider>
                </Box>

                <Divider>

                  <Typography sx={{
                    display: 'flex',
                    fontWeight: '500',
                    alignItems: 'center',
                    fontSize: '13px'
                  }}>
                    Or
                  </Typography>

                </Divider>

              </>}
              <FormControl>


                <Box sx={{
                  display: 'flex',
                  gap: '6px',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
                >
                  <Typography variant="body2" sx={{
                    fontSize: '13px',
                    fontWeight: 500,
                    color: '#282828',
                  }}>
                    Email address
                  </Typography>

                  <TextField
                    id="userID"
                    placeholder="Email ID or Mobile"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={userId}
                    onChange={(e) => setUserId(e.target.value)}
                    inputProps={{
                      sx: {
                        fontSize: '13px',
                        color: "#282828"
                      },
                    }} />
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    gap: '6px',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                  }}>
                  <Typography variant="body2" sx={{
                    fontSize: '13px',
                    fontWeight: 500,
                    color: '#282828',
                  }}>Password</Typography>

                  {/* <form style={{ width: '100%' }}> */}
                  <TextField
                    variant="outlined"
                    id="outlined-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    placeholder={"*************"}
                    fullWidth
                    size="small"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    inputProps={{
                      sx: {
                        fontSize: '13px',
                        color: '#282828'
                      },
                    }}
                    autoComplete="on"
                    InputProps={{
                      endAdornment: <InputAdornment position="end" >
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                        </IconButton>
                      </InputAdornment>,
                    }}
                  />
                  {/* </form> */}
                </Box>

              </FormControl>

              <Box sx={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}>
                <Link href="#" underline="none" sx={{
                  color: '#3069B0',
                  fontWeight: '600',
                  display: 'flex',
                  justifyContent: 'end',
                  fontSize: '13px!important',
                  "&:hover": {
                    color: '#26548d'
                  },
                }} onClick={(e) => handleNavigate(e)}>Forgot password?</Link>
              </Box>

              <AuthFooter
                btnText={"Login"}
                navigationMessage={"Don't have an account? "}
                navigateText={"Create account"}
                btnCheckHandler={handleLogin}
                navigationCheckHandler={() => navigateRegister()}
                loadingBtn={loginLoading}
                signUp={signUp}
              />

            </Box>
          </Box>

        </Box>


      </HeadingSection>

  )
}

export default LoginForm;
