import React, { useEffect } from 'react';
import { AppBar, Toolbar, Button, Box, Typography, useTheme } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { styled } from "@mui/material/styles";
import CloseIcon from '@mui/icons-material/Close';
import { useContext } from 'react';
import { SidebarContext } from '../../context/SidebarContext';
import LogoBlue from '../../assets/svgs/logo';
import { Icon } from '@iconify/react';
import { checkPermission } from '../../utils/common';
import { useDispatch, useSelector } from 'react-redux';
import { axiosGetCall } from '../../services/apisCall';
import { updateWalletBalance } from '../../redux/action/walletActions';

const LogoSection = styled("div")(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    gap: '10px',
    width: '180px',
    height: '60px',
    marginLeft: '-13px',
    [theme.breakpoints.down('sm')]: {
        marginLeft: '20px',
    },
}));

const MenuIconSection = styled(IconButton)(({ theme }) => ({
    width: '32px',
    height: '32px',
    color: theme.palette.grey[0],
    [theme.breakpoints.up('sm')]: {
        display: 'none'
    },
}));

export default function Navbar() {
    const { openSidebar = false, setOpenSidebar = () => "" } = useContext(SidebarContext)
    const navigate = useNavigate();
    const location = useLocation();
    const permission = checkPermission();
    const theme = useTheme();
    const dispatch = useDispatch();

    const balance = useSelector((state) => state.wallet.balance)

    const fetchBalance = async () => {
        try {
            const { balance } = await axiosGetCall("fetchWalletBalance");
            dispatch(updateWalletBalance(parseFloat(balance)));
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchBalance();
    }, [])

    const add = [
        // {
        //     title: "Plans & Pricing",
        //     active: location?.pathname === "/plans",
        //     path: "/plans",
        //     startIcon: <Icon icon="iconoir:wallet" width={22} />
        // },
        {
            title: "Branding",
            active: location?.pathname === "/branding",
            path: "/branding",
            startIcon: <Icon icon="hugeicons:book-edit" width={22} />
        },
        {
            title: "Users",
            active: location?.pathname === "/users" || location?.pathname === "/createUser",
            path: "/users",
            startIcon: <Icon icon="ph:users-light" width={22} />
        },]

    const defaultMenu = [
        {
            title: "Settings",
            active: location?.pathname === "/settings",
            path: "/settings",
            startIcon: <SettingsOutlinedIcon />
        },
    ]

    const navbar = permission === 'l4' ? [...add, ...defaultMenu] : defaultMenu


    return (
        <Box sx={{
            // pl: '67px',
            flexDirection: 'flex-end',
            position: 'fixed',
            width: '100%',
            color: '#fff',
            zIndex: 3,
            boxShadow: '59px 0.5px 3px 0px rgba(117, 117, 117, 0.10)',
        }}>
            <AppBar position="static" component="nav" sx={{
                backgroundColor: "card.main",
                boxShadow: 'none'
            }}>
                <Toolbar>
                    <MenuIconSection onClick={() => openSidebar ? setOpenSidebar(false) : setOpenSidebar(true)}>
                        {openSidebar ?
                            <CloseIcon
                                // onClick={() => setOpenSidebar(false)}
                                sx={{
                                    width: '32px',
                                    height: '32px',
                                }} />
                            :
                            <MenuIcon
                                // onClick={() => setOpenSidebar(true)}
                                sx={{
                                    width: '32px',
                                    height: '32px',
                                }} />
                        }

                    </MenuIconSection>

                    <LogoSection >
                        <LogoBlue />
                    </LogoSection>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        width: '100%',
                        gap: '20px'
                    }}>
                        {navbar.map(({ title, active, path, icon, startIcon }) => (
                            <Button
                                onClick={() => navigate(path)}
                                key={title}
                                sx={{
                                    color: active ? "grey.70" : "grey.500",
                                    height: '34px',
                                    "&:hover": {
                                        color: active ? "grey.70" : "grey.300",
                                        bgcolor: "transparent",
                                    }
                                }}
                                endIcon={icon}
                                startIcon={startIcon}
                            >
                                {title}
                            </Button>
                        )
                        )}
                        {/* <Button variant='outlined' sx={{
                            bgcolor: 'primary.main',
                            height: '34px',
                            color: "#fff",
                            "&:hover": {
                                bgcolor: 'primary.main',
                            }
                        }} startIcon={<TextsmsOutlinedIcon />}>
                            Create Campaign
                        </Button> */}

                    </Box>

                    {/* <Box sx={{
                        ml: '15px',
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '8px',
                        alignItems: 'center',
                        border: '0.5px solid',
                        borderColor: 'primary.main',
                        borderRadius: '6px',
                        padding: '4px 10px 4px 10px'
                    }}>
                        <Icon icon="ion:wallet" color={theme.palette.primary.main} />
                        <Typography sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            fontSize: '18px',
                            fontWaight: 600,
                            color: "grey.200"
                        }}>
                            <Icon icon="healthicons:rupee" width={"20px"} height={"20px"} /> {balance}
                        </Typography>

                        <Icon icon={"zondicons:add-outline"} color={theme.palette.primary.main} onClick={() => navigate('/settings')} />

                    </Box> */}
                </Toolbar>
            </AppBar>
        </Box>

    )
}
