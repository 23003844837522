import React from 'react';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

const MainSection = styled('div')(() => ({
    display: 'flex',
    gap: '8px',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%'
}));

const NoteTypography = styled(Typography)(({ theme }) => ({
    fontSize: '14px',
    fontWeight: 500,
    color: theme.palette.grey[300]
}));

const ShowNote = () => {
    return (
        <MainSection>
            <InfoOutlinedIcon sx={{ color: 'grey.300' }} />

            <NoteTypography>Note: <Typography variant='inherit' component={"span"} sx={{ color: 'grey.500' }}>All docs should be self attested with company stamp. </Typography></NoteTypography>
        </MainSection>
    )
}

export default ShowNote