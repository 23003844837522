import React, { useState } from "react";

//mui
import Grid from '@mui/material/Unstable_Grid2';

//components
import LoginForm from "../../../components/Auth/Login";
import { imagesUrl } from "../../../config/commonConfig";
import LoadingModal from "../../../components/Loader/loading";

export default function Login() {
    const [loader, setLoader] = useState(false);
    return (
        <>
            {loader && <LoadingModal open={true} />}
            <Grid sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                background: {
                    xs: '#fff',
                    sm: `#0F478D 0px -64.059px / 100% 115.26% url(${imagesUrl?.authBackground})`,
                },
                backgroundBlendMode: 'multiply',
                backgroundRepeat: "repeat"
            }}>
                <LoginForm handleLoader={setLoader} />
            </Grid>
        </>
    )
}
